import { CSSProperties, FC } from 'react';
import { useTranslation } from 'i18nano';
import { NavLink, To } from 'react-router-dom';
import { MaterialIconText } from '../material-icon-text';

import css from './index.module.scss';

export const PageBackButton: FC<{ styles?: CSSProperties }> = ({ styles }) => {
  const translate = useTranslation();

  return (
    <NavLink to={-1 as To} preventScrollReset={true} className={css.back} style={styles}>
      <MaterialIconText size={'16'} iconName={'chevron_left'} classes={css.backIcon} />
      <span>{translate('back')}</span>
    </NavLink>
  );
};
