import { FC, memo, useEffect, useMemo, useState } from 'react';

import { HBox } from '../../layout';
import { Button } from '../../ui-kit/button';
import { IPagesProps } from './props';

const dotsThreshold = 5;
const dotsSymbol = '...';
const dotsSymbolIndex = 0;

interface IPage {
  id: number;
  label: string;
}

interface IPagesConfig {
  pages: IPage[];
  pagesCount: number;
}

export const Pages: FC<IPagesProps> = memo((props) => {
  if (!props.itemsTotalCount || !props.itemsPerPage) { return null; }

  const [selectedPageId, setSelectedPageId] = useState<number>(props.currentPage || 1);

  const pagesConfig = useMemo((): IPagesConfig => {
    const pages: IPage[] = [];

    const pagesCount = Math.ceil(props.itemsTotalCount! / props.itemsPerPage!);
    
    if (pagesCount <= dotsThreshold) {
      for (let i = 1; i <= pagesCount; ++i) {
        pages.push({ id: i, label: `${i}`});
      }

      return { pages, pagesCount };
    }


    if (selectedPageId > dotsThreshold) {
      const currentStep = 1 + (selectedPageId - dotsThreshold);

      for (let i = currentStep; i < dotsThreshold + currentStep; ++i) {
        pages.push({ id: i, label: `${i}`});
      }
  
      if (selectedPageId < pagesCount) {
        pages.push({ id: 0, label: dotsSymbol });
      }
      
      return { pages, pagesCount };
    }

    for (let i = 1; i <= dotsThreshold; ++i) {
      pages.push({ id: i, label: `${i}`});
    }

    pages.push({ id: 0, label: dotsSymbol });

    return { pages, pagesCount };
  }, [props.itemsPerPage, props.itemsTotalCount, selectedPageId]);

  const handleClick = (pageId: number) => {
    if (selectedPageId === pageId) { return; }

    if (pageId === dotsSymbolIndex && pagesConfig.pagesCount > selectedPageId) {
      if (props.onSetPage) {
        props.onSetPage(pagesConfig.pages[pagesConfig.pages.length - 2].id + 1);
      }

      setSelectedPageId(pagesConfig.pages[pagesConfig.pages.length - 2].id + 1);

      return;
    }

    if (props.onSetPage) { props.onSetPage(pageId); }

    setSelectedPageId(pageId);
  };

  useEffect(() => {
    if (props.currentPage) {
      setSelectedPageId(props.currentPage);
    }
  }, [props.currentPage]);

  const btnStyles = useMemo(() => ({ width: '32px', height: '32px' }), []);
  
  return (
    <HBox gap={4} testId={'pages'}>
      <Button
        type={'secondary'}
        style={btnStyles}
        testId={'previousBtn'}
        icon={'chevron_left'}
        isDisabled={selectedPageId === 1}
        onClick={() => handleClick(selectedPageId - 1)}
      />
      {
        pagesConfig.pages.map(({ id, label }) =>
          <Button
            key={id}
            type={id === selectedPageId ? 'primary' : 'secondary'}
            testId={'pageBtn'}
            label={label}
            onClick={() => handleClick(id)}
            style={btnStyles}
          />
        )
      }
      <Button
        type={'secondary'}
        testId={'nextBtn'}
        icon={'chevron_right'}
        style={btnStyles}
        isDisabled={selectedPageId === pagesConfig.pagesCount}
        onClick={() => handleClick(selectedPageId + 1)}
      />
    </HBox>
  );
});
