import * as React from 'react';
import { IconProps } from '../types';

export default function RequestIcon30(props: IconProps): React.ReactElement {
  const { type, width = 30, heigth = 30 } = props;
  const mainColor = type === 'primary' ? '#EDB1C4' : '#D7D7D7';
  const secondaryColor = type === 'primary' ? '#DA6289' : '#AEAEAE';
  return (
    <svg
      width={width}
      height={heigth}
    >
      <path
        d="M13.8652 25.0214C20.3104 24.0994 25.3005 18.9836 25.3005 12.7216C25.3015 11.8254 25.1983 10.932 24.993 10.0586C27.7465 11.6631 29.5717 14.4626 29.5717 17.6479C29.5697 19.3964 29.0163 21.1017 27.9871 22.5311C28.0725 23.8529 28.5509 25.0117 29.425 26.0089C29.5046 26.0988 29.555 26.2099 29.5698 26.3279C29.5846 26.446 29.5631 26.5656 29.5082 26.6717C29.4532 26.7778 29.3672 26.8654 29.2611 26.9235C29.155 26.9816 29.0336 27.0074 28.9125 26.9978C26.9663 26.8432 25.3745 26.4045 24.1373 25.6816C22.7199 26.3339 21.1721 26.6702 19.6057 26.6663C17.5695 26.6753 15.5747 26.1037 13.8652 25.0214Z"
        fill={secondaryColor}
      />
      <path
        d="M9.04478 22.339C9.90164 22.54 10.7985 22.6477 11.7225 22.6477C17.6377 22.6477 22.4332 18.2494 22.4332 12.8231C22.4332 7.39966 17.6377 3 11.7225 3C5.80728 3 1.01172 7.39824 1.01172 12.8245C1.01172 15.5802 2.24845 18.071 4.24208 19.8552C4.07323 21.057 3.55461 22.1839 2.74972 23.098C2.66401 23.1958 2.60969 23.3168 2.5937 23.4454C2.57772 23.5741 2.6008 23.7045 2.65999 23.8201C2.71918 23.9356 2.81179 24.031 2.92597 24.0941C3.04015 24.1572 3.17072 24.1851 3.30096 24.1742C5.72873 23.9787 7.64382 23.367 9.04478 22.339Z"
        fill={mainColor}
      />
      <circle cx="26" cy="4" r="4" fill="#C30341"/>

    </svg>
  );
}