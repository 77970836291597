import { mapToProfileUser } from '../../pages/main/services/profile-mapper';
import { VideoReviewMapper } from '../../types/courses/video-review-mapper';

export const mapToVideoReview: VideoReviewMapper = (fromDto) => ({
  id: fromDto.id!.toString(),
  title: fromDto.title,
  userName: fromDto?.user_name,
  picVideoUrl: fromDto?.video_image_url || fromDto?.video_cropped_image || '',
  videoUrl: fromDto?.video_url || '',
  courseId: fromDto?.course,
  user: mapToProfileUser(fromDto?.user_profile),
});
