import { AppConst } from '../../../../../constants/app';
import { RequestPeriodEnum, CourseStatusEnum, RequestStatusEnum } from '../enums';
import { IManagementRequestsState } from '../interfaces';

export const requestsInitialState: IManagementRequestsState = {
  filter: {
    skip: 0,
    take: AppConst.perPageItemsCounts[1],
    currentPage: 1,
    search: undefined,

    courseStatus: CourseStatusEnum.All,
    requestPeriod: RequestPeriodEnum.All,
    requestStatus: RequestStatusEnum.All,

    possibleCourseStatuses: [
      CourseStatusEnum.All,
      CourseStatusEnum.Active,
      CourseStatusEnum.Draft,
      CourseStatusEnum.Hidden,
      CourseStatusEnum.Archived,
    ],
    possibleRequestPeriods: [
      RequestPeriodEnum.All,
      RequestPeriodEnum.Month,
      RequestPeriodEnum.Year,
    ],
    possibleRequestStatuses: [
      RequestStatusEnum.All,
      RequestStatusEnum.Review,
      RequestStatusEnum.Accepted,
      RequestStatusEnum.Canceled,
      RequestStatusEnum.Archived,
    ],
  },
};
