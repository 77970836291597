import { memo } from 'react';
import { useTranslation } from 'i18nano';

import { ContainerSection } from '../../../../../components/container-section';

import css from './index.module.scss';

export const TermsSection = memo(() => {
  const translate = useTranslation();

  return (
    <ContainerSection classes={css.footerTerms}>
      <div className={css.footerTermsCol}>
        <a
          href={
            'https://www.ranepa.ru/images/docs/svedeniya_ooo/Politika_personal_dannih_02.02.2017.pdf'
          }
          target='_blank'
          rel='noreferrer'
        >
          <p>{translate('footer.termsPrivacy')}</p>
        </a>
        <a
          href={
            'https://www.ranepa.ru/images/docs/svedeniya_ooo/Politika_personal_dannih_02.02.2017.pdf'
          }
          target='_blank'
          rel='noreferrer'
        >
          <p>{translate('footer.termsUserAgreement')}</p>
        </a>
      </div>
      <div className={css.footerTermsCol}>
        <a
          href={
            'https://www.ranepa.ru/images/docs/svedeniya_ooo/Politika_personal_dannih_02.02.2017.pdf'
          }
          target='_blank'
          rel='noreferrer'
        >
          <p>{translate('footer.termsNewsletterAgreement')}</p>
        </a>
        <a
          href={
            'https://www.ranepa.ru/images/docs/svedeniya_ooo/Politika_personal_dannih_02.02.2017.pdf'
          }
          target='_blank'
          rel='noreferrer'
        >
          <p>{translate('footer.termsCookies')}</p>
        </a>
      </div>
      <div className={css.footerTermsCol}>
        <span>{translate('footer.termsRights')}</span>
      </div>
    </ContainerSection>
  );
});
