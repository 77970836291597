import { FC, memo } from 'react';
import { NavLink } from 'react-router-dom';

import { HeaderNavMenu } from '../../components/nav-menu';
import { HeaderProfileMenu } from '../../components/profile-menu';

import css from './index.module.scss';

export const HeaderDesktopView: FC = memo(() => {
  return (
    <div className={css.header}>
      <div className={css.headerLogoWrapper}>
        <NavLink to={'/#'}>
          <img src={'/images/logo.svg'} className={css.headerLogo} />
        </NavLink>
      </div>
      <div>
        <HeaderNavMenu />
      </div>
      <div className={css.headerProfileWrapper}>
        <HeaderProfileMenu className={css.headerProfile} />
      </div>
    </div>
  );
});
