import { useTranslation } from 'i18nano';
import { FC } from 'react';
import { VBox } from '../../layout';
import { RcFileLoader } from '../../ui-kit/file-loader';
import { IRcFileLoaderProps } from '../../ui-kit/file-loader/props';

import css from './index.module.scss';

export const FileLoaderField: FC<IRcFileLoaderProps> = (props) => {
  const translate = useTranslation();

  return (
    <VBox gap={8}>
      {props.title && <span className={css.label}>{props.title}{props.isRequired && <sup>*</sup>}</span>}
      <RcFileLoader
        subtitle={translate('sharedComponents.fileLoader.placeholder')}
        buttonLabel={translate('sharedComponents.fileLoader.button')}
        {...props}
      />
    </VBox>
  );
};
