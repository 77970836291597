import { useEffect } from 'react';
import { selectCurrentUser } from '../pages/auth/store/helpers';
import { setFavoriteCourses } from '../pages/main/store/actions';
import { useAppDispatch, useAppSelector } from './hooks';
import { favouriteStorage } from '../services/storage/favourites-storage';
import {
  useApiListFavoritesCreateMutation,
  useLazyApiListFavoritesListQuery,
} from '../services/base-api';

export const useInitFavourites = () => {
  const user = useAppSelector(selectCurrentUser);
  const dispatch = useAppDispatch();
  const [getFavoriteCourses] = useLazyApiListFavoritesListQuery();
  const [postFavouriteCourses] = useApiListFavoritesCreateMutation();

  useEffect(() => {
    (async () => {
      const localCourses = favouriteStorage.getData();

      if (!user) {
        dispatch(setFavoriteCourses(localCourses));
      } else {
        if (localCourses.length > 0) {
          await postFavouriteCourses({ body: localCourses });
        }
        const { courses } = await getFavoriteCourses().unwrap();
        const coursesIds = courses?.map((course) => course.id) ?? [];
        dispatch(setFavoriteCourses(coursesIds));
        favouriteStorage.setStorage(coursesIds);
      }
    })();
  }, [user, dispatch, getFavoriteCourses, postFavouriteCourses]);

  return null;
};
