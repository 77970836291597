import { useCallback, useEffect, useMemo } from 'react';
import { mapToUser } from '../pages/auth/services/user-mapper';
import { selectCurrentUser } from '../pages/auth/store/helpers';
import { setProfile } from '../pages/auth/store/slice';
import { AuthStorage } from '../services/auth/auth-storage';
import { useLazyApiUsersProfileReadQuery } from '../services/base-api';
import { useAppDispatch, useAppSelector } from './hooks';

export const useUserProfile = () => {
  const user = useAppSelector(selectCurrentUser);
  const dispatch = useAppDispatch();
  const [getProfile, { isError, isFetching, isLoading }] = useLazyApiUsersProfileReadQuery();

  const refreshProfile = useCallback(async () => {
    const authData = AuthStorage.getData();

    if (!user && !!authData) {
      try {
        const profileResponse = await getProfile().unwrap();
        dispatch(setProfile({ user: mapToUser(profileResponse) }));
      } catch {
        /* */
      }
    }
  }, [user, dispatch, getProfile]);

  const updateProfile = useCallback(async () => {
    const profileResponse = await getProfile().unwrap();
    dispatch(setProfile({ user: mapToUser(profileResponse) }));
  }, [user, dispatch, getProfile]);

  useEffect(() => {
    refreshProfile();
  }, [refreshProfile]);

  return useMemo(
    () => ({
      user,
      updateProfile,
      isError,
      isFetching,
      isLoading,
    }),
    [user, updateProfile, isError, isFetching, isLoading],
  );
};
