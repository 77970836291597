import { FC } from 'react';
import { Navigate, Outlet, useLocation } from 'react-router-dom';
import { PagePathsEnum } from '../../enums/routes';
import { useUserProfile } from '../../hooks/auth';
import { SuspenseFallback } from '../suspense-fallback/suspense-fallback';
import { CustomOutletProps } from './props';

export const CustomOutlet: FC<CustomOutletProps> = ({ shouldRedirect, navigatePath, withAuth }) => {
  const auth = useUserProfile();
  const location = useLocation();

  if (withAuth && !auth.user && (auth.isFetching || auth.isLoading)) {
    return <SuspenseFallback />;
  }

  if (withAuth && !auth.user && !(auth.isFetching || auth.isLoading)) {
    return (
      <Navigate to={`/${PagePathsEnum.Auth}/${PagePathsEnum.Login}`} state={{ from: location }} />
    );
  }

  return shouldRedirect(auth.user) ? (
    <Navigate to={navigatePath || '#/'} state={{ from: location }} />
  ) : (
    <Outlet />
  );
};
