export const socialItems: Array<{ key: string; resKey: string; path: string }> = [
  {
    key: 'vk',
    resKey: 'footer.socialVk',
    path: 'https://vk.com/theacademy',
  },
  {
    key: 'tg',
    resKey: 'footer.socialTg',
    path: 'https://t.me/mainranepa',
  },
  {
    key: 'youtube',
    resKey: 'footer.socialYoutube',
    path: 'https://www.youtube.com/channel/UCGl0bgQpEBVxel-QuRXDqgA',
  },
  {
    key: 'tiktok',
    resKey: 'footer.socialTiktok',
    path: 'https://www.tiktok.com/@planeta_ranepa/',
  },
  {
    key: 'ok',
    resKey: 'footer.socialOk',
    path: 'https://ok.ru/group/62253910065263',
  },
  {
    key: 'dzen',
    resKey: 'footer.socialDzen',
    path: 'https://dzen.ru/theacademy',
  },
];
