export class CustomStorage {
  constructor(public storageKey: string) {}

  setStorage(value: string[]) {
    localStorage.setItem(this.storageKey, JSON.stringify(value));
  }

  update(id: string) {
    const currentIds = localStorage.getItem(this.storageKey);

    if (currentIds) {
      const currentIdsArray = JSON.parse(currentIds);

      const newIds = currentIds.includes(id)
        ? currentIdsArray.filter((currentId: string) => currentId !== id)
        : [...currentIdsArray, id];

      this.setStorage(newIds);
    } else {
      this.setStorage([id]);
    }
  }

  getData(): string[] {
    const currentIds = localStorage.getItem(this.storageKey);

    return currentIds ? JSON.parse(currentIds) : [];
  }

  clear(): void {
    localStorage.removeItem(this.storageKey);
  }
}
