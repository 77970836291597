import { FC, memo } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { useTranslation } from 'i18nano';

import { useAppDispatch } from '../../../../../../../hooks/hooks';
import { setProfile } from '../../../../../../auth/store/slice';
import { AuthStorage } from '../../../../../../../services/auth/auth-storage';
import { PagePathsEnum } from '../../../../../../../enums/routes';
import { useApiUsersLogoutCreateMutation } from '../../../../../../../services/base-api';
import { ThemeSwitcher } from '../theme-switcher';
import { MaterialIconText } from '../../../../../../../components/material-icon-text';
import { MyDataPathsEnum, StudyPathsEnum } from '../../../../../../study/enums';
import { withTestId } from '../../../../../../../helpers';
import { IUserProfileMenuProps } from './props';

import css from './index.module.scss';

export const UserProfileSubmenu: FC<IUserProfileMenuProps> = memo((props) => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const translate = useTranslation();
  const [logout] = useApiUsersLogoutCreateMutation();

  const handleExit = async () => {
    const authData = AuthStorage.getData();

    try {
      await logout({ body: { refresh_token: authData?.refreshtoken } });
    } finally {
      dispatch(setProfile({ user: undefined }));
      AuthStorage.clearData();
      navigate('/');
      handleNavigateNotify();
    }
  };

  const handleNavigateNotify = () => props.onNavigateClick && props.onNavigateClick();

  return (
    <div className={css.profileSubmenu} {...withTestId('profileSubmenu')}>
      <div className={css.profileSubmenuSettingsWrapper}>
        <ThemeSwitcher />
        <p className={css.profileSubmenuSettingsTitle}>
          {translate('header.profileMenu.settings.title')}
        </p>
        <ul className={css.profileSubmenuSettingsLinksWrapper}>
          <li>
            <Link
              className={css.profileSubmenuSettingsLink}
              to={`/${PagePathsEnum.Study}/${StudyPathsEnum.MyData}/${MyDataPathsEnum.Personal}`}
              onClick={() => handleNavigateNotify()}
            >
              <MaterialIconText
                iconName='settings'
                classes={css.profileSubmenuSettingsLinkIcon}
                size={'28'}
                isSymbols
              />
              <div>
                <p className={css.profileSubmenuSettingsLinkTitle}>
                  {translate('header.profileMenu.settings.one.title')}
                </p>
                <p className={css.profileSubmenuSettingsLinkDescription}>
                  {translate('header.profileMenu.settings.one.description')}
                </p>
              </div>
            </Link>
          </li>
          <li>
            <Link
              className={css.profileSubmenuSettingsLink}
              to={`/${PagePathsEnum.Study}/${StudyPathsEnum.AllInformation}`}
              onClick={() => handleNavigateNotify()}
            >
              <MaterialIconText
                iconName='home_work'
                classes={css.profileSubmenuSettingsLinkIcon}
                size={'28'}
                isSymbols
              />
              <div>
                <p className={css.profileSubmenuSettingsLinkTitle}>
                  {translate('header.profileMenu.settings.two.title')}
                </p>
                <p className={css.profileSubmenuSettingsLinkDescription}>
                  {translate('header.profileMenu.settings.two.description')}
                </p>
              </div>
            </Link>
          </li>
        </ul>
      </div>
      <ul className={css.profileSubmenuFooterWrapper}>
        <li>
          <a
            className={css.profileSubmenuSettingsLink}
            href='https://www.ranepa.ru/'
            target='_blank'
            rel='noreferrer'
            onClick={() => handleNavigateNotify()}
          >
            <MaterialIconText
              iconName='public'
              classes={css.profileSubmenuSettingsLinkIcon}
              size={'28'}
              isSymbols
            />
            <p className={css.profileSubmenuFooterWrapperTitle}>Ranepa.ru</p>
          </a>
        </li>
        <li>
          <Link
            className={css.profileSubmenuSettingsLink}
            to={`/${PagePathsEnum.Study}/${StudyPathsEnum.MyData}/${MyDataPathsEnum.Document}`}
            onClick={() => handleNavigateNotify()}
          >
            <MaterialIconText
              iconName='group'
              classes={css.profileSubmenuSettingsLinkIcon}
              size={'28'}
              isSymbols
            />
            <p className={css.profileSubmenuFooterWrapperTitle}>
              {translate('header.profileMenu.orgSettings')}
            </p>
          </Link>
        </li>
        <li>
          <button className={css.profileSubmenuSettingsLink} onClick={handleExit} {...withTestId('profileSubmenuLogout')}>
            <MaterialIconText
              iconName='logout'
              classes={css.profileSubmenuSettingsLinkIcon}
              size={'28'}
              isSymbols
            />
            <p className={css.profileSubmenuFooterWrapperTitle}>
              {translate('header.profileMenu.exit')}
            </p>
          </button>
        </li>
      </ul>
    </div>
  );
});
