import { FC, memo } from 'react';

import { PromoSection } from './components/promo';
import { MenuSection } from './components/menu';
import { TermsSection } from './components/terms';
import { withTestId } from '../../../helpers';

import css from './index.module.scss';

export const Footer: FC = memo(() => (
  <div className={css.footer} {...withTestId('footerBlock')}>
    <MenuSection />
    <PromoSection />
    <TermsSection />
  </div>
));
