import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { KeyValue } from '../interfaces/core/key-value';
import { AlertNotification } from '../interfaces/core/notification';

import { initialState } from './initial-state';
import { ERootState } from '../interfaces/root-state';

export const appSlice = createSlice({
  name: ERootState.app,
  initialState,
  reducers: {
    addNotification: (state, action: PayloadAction<KeyValue<string, AlertNotification>>) => {
      state.notifications.push(action.payload);
    },
    removeNotification: (state, action: PayloadAction<string>) => {
      state.notifications = state.notifications.filter((item) => item.key !== action.payload);
    },
  },
});
