import { StudyPageState } from '../interfaces/state';

export const initialState: StudyPageState = {
  requestForm: {},
  myCourses: {
    activeTabId: 0,
    searchValue: '',
  },
  myRequests: {
    activeTabId: 0,
  },
  myGroups: {
    activeTabId: 0,
    searchValue: '',
  },
  achievements: {
    searchValue: '',
    documentTypeId: undefined,
  },
  documents: {
    searchValue: '',
    documentTypeId: 0,
    coursesTypeId: undefined,
  },
  promocodes: {
    activeTabId: 0,
    request: null,
  },
};
