import { useMemo } from 'react';
import { useApiListFavoritesListQuery } from '../../../services/base-api';
import { mapToCourse } from '../../../services/mappers/courses';

export const useFavoriteCourses = () => {
  const { isFetching, data, isLoading, isError } = useApiListFavoritesListQuery();

  const noData = !data;

  return useMemo(() => {
    return {
      favourites: data ? data.courses.map(mapToCourse) : [],
      noData,
      isLoading,
      isError,
      isFetching,
    };
  }, [noData, data, isLoading, isError]);
};
