import { FC } from 'react';

import { VBox } from '../../layout';
import { ISelectProps, Select } from '../../ui-kit/select';

import css from './index.module.scss';

export const SelectField: FC<ISelectProps & { label: string }> = (props) => {
  return (
    <VBox gap={8}>
      <label className={css.label}>{props.label}{props.isRequired && <sup>*</sup>}</label>
      <Select readonly={true} {...props} />
    </VBox>
  );
};
