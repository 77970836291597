import { FC, useMemo } from 'react';

import { UiKitButtonProps } from './props';
import { classNames } from '../../../helpers/classnames';
import { Loader } from '../loader';

import css from './index.module.scss';
import { withTestId } from '../../../helpers';

export const Button: FC<UiKitButtonProps> = ({
  type = 'primary',
  size = 'medium',
  label,
  selectedLabel = null,
  icon,
  style,
  isDisabled,
  isLoading,
  isSelected,
  className,
  testId,
  buttonType = 'button',
  onMouseEnter,
  onMouseLeave,
  isResponsive,
  ...props
}: UiKitButtonProps) => {
  const classes = useMemo(
    () =>
      classNames(
        css.button,
        {
          [css.button_primary]: type === 'primary',
          [css.button_btnlink]: type === 'btnlink',
          [css.button_secondary]: type === 'secondary',
          [css.button_transparent]: type === 'transparent',
          [css.button_small]: size === 'small',
          [css.button_medium]: size === 'medium',
          [css.button_disabled]: isDisabled || isLoading,
          [css.button_selected]: !(isDisabled || isLoading) && isSelected,
          [css.button_noninteractable]: !label && (isDisabled || isLoading),
          [css.button_responsive]: isResponsive,
        },
        className,
      ),
    [type, size, isDisabled, isLoading, isSelected, className, label],
  );

  const iconClasses = useMemo(
    () =>
      classNames(css.button__icon, {
        [css.button__icon_small]: size === 'small',
        [css.button__icon_medium]: size === 'medium',
        [css.button__icon_no_margins]: !label,
        [css.button__icon_disabled]: isDisabled,
      }),
    [size, label, isDisabled],
  );

  const currentStyles: React.CSSProperties = style && Object.keys(style).length ? style : {};

  return (
    <button
      type={buttonType}
      disabled={isDisabled || isLoading}
      style={currentStyles}
      className={classes}
      onMouseEnter={onMouseEnter}
      onMouseLeave={onMouseLeave}
      {...withTestId(testId)}
      {...props}
    >
      {!isLoading && icon && <span className={iconClasses}>{icon}</span>}
      {!isLoading && isSelected && selectedLabel ? selectedLabel : label}
      {isLoading && (
        <Loader style={{ width: '20px', height: '20px' }} strokeWidth='2' animationDuration='.5s' />
      )}
    </button>
  );
};
