import { useState, useEffect } from 'react';

import { ResponsiveInfo } from './responsive-info';
import { DeviceType } from './device-type';

export const useResponsive = (): ResponsiveInfo => {
  const [deviceType, setDeviceType] = useState<DeviceType>(DeviceType.Desktop);

  useEffect(() => {
    let hasTouchScreen = false;

    if ('maxTouchPoints' in navigator) {
      hasTouchScreen = navigator.maxTouchPoints > 0;
    } else {
      const mQ = window.matchMedia && window.matchMedia('(pointer:coarse)');
      if (mQ && mQ.media === '(pointer:coarse)') {
        hasTouchScreen = !!mQ.matches;
      } else if ('orientation' in window) {
        hasTouchScreen = true; // deprecated, but good fallback
      } else {
        // Only as a last resort, fall back to user agent sniffing
        const UA = navigator['userAgent'];

        hasTouchScreen =
          /\b(BlackBerry|webOS|iPhone|IEMobile)\b/i.test(UA) ||
          /\b(Android|Windows Phone|iPad|iPod)\b/i.test(UA);
      }
    }

    if (hasTouchScreen) {
        setDeviceType(DeviceType.Mobile);
        return;
    }
    
    setDeviceType(DeviceType.Desktop);
  }, []);

  return ({
    isMobile: deviceType === DeviceType.Mobile,
    isDesktop: deviceType === DeviceType.Desktop,
  });
};
