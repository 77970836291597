import { FC, memo, useLayoutEffect, useMemo, useRef, useState } from 'react';
import { classNames } from '../../../helpers/classnames';
import { IDropDownProps } from './props';
import { withTestId } from '../../../helpers';

import css from './index.module.scss';

interface IDropdownState {
  style: React.CSSProperties;
}

export const Dropdown: FC<IDropDownProps> = memo(({ stretched, ...rest }) => {
  const ref = useRef<HTMLDivElement>(null);
  const [state, setState] = useState<IDropdownState>({ style: {} });

  const className = useMemo(() => classNames({
    [css.dropdown]: true,
    [css.dropdown_stretched]: stretched,
  }), [classNames, stretched]);

  useLayoutEffect(() => {
    const elTextRectangle = ref.current?.getBoundingClientRect();
    const wrapperHeight = elTextRectangle?.height || 0;
    const wrapperWidth = elTextRectangle?.width || 0;
    const wrapperOffsetTop = elTextRectangle?.top || 0;
    const wrapperOffsetLeft = elTextRectangle?.left || 0;
    const windowHeight = window.innerHeight;
    const windowWidth = window.innerWidth;

    if (windowHeight - wrapperOffsetTop < wrapperHeight * 0.6) {
      setState({
        style: {
          bottom: '52px',
          top: 'auto',
          left: state.style.left,
          right: state.style.right,
        },
      });
    }

    if (windowWidth - wrapperOffsetLeft < wrapperWidth) {
      setState({
        style: {
          right: 0,
          left: 'auto',
          top: state.style.top,
          bottom: state.style.bottom,
        },
      });
    }
  }, []);

  return <div className={className} {...withTestId('dropdownElement')}>
    <div className={css.dropdownInner} style={state.style} ref={ref}>
      {rest.children}
    </div>
  </div>;
});
