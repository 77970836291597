import { FC, memo } from 'react';

import { classNames } from '../../helpers/classnames';

import css from './index.module.scss';

type AvailableSizes = '12' | '14' | '16' | '18' | '20' | '24' | '28' | '32' | '36' | '48';

export const MaterialIconText: FC<{
  iconName: string;
  size?: AvailableSizes;
  classes?: string;
  isDisabled?: boolean;
  isSymbols?: boolean;
  onClick?: React.MouseEventHandler<HTMLSpanElement>;
  testId?: string;
}> = memo(({ iconName, size, classes, isDisabled, isSymbols, onClick, testId }) => {
  const cmpClasses = classNames(
    css.materialIconsText,
    'material-icons-outlined',
    { [css.materialIconsText_disabled]: isDisabled },
    classes,
    { 'material-symbols-outlined': isSymbols },
    css[`${classes}`],
  );

  const handleClick: React.MouseEventHandler<HTMLSpanElement> = (e) => {
    if (onClick) {
      onClick(e);
    }
  };

  return (
    <span
      onClick={handleClick}
      data-testid={testId}
      className={cmpClasses}
      style={{ fontSize: `${size || '24'}px` }}
    >
      {iconName}
    </span>
  );
});
