import { PayloadAction } from '@reduxjs/toolkit';

import { PageSliceCaseReducers } from '../../../types';
import { ProfessorsFilterTypeEnum } from '../enums';

export const professorsReducers: PageSliceCaseReducers = {
    setProfessorsFilterSearch: (state, action: PayloadAction<string | undefined>) => {
        state.professors.filter.search = action.payload;
    },
    setProfessorsFilterType: (state, action: PayloadAction<ProfessorsFilterTypeEnum>) => {
        state.professors.filter.type = action.payload;
    },
};
