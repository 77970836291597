import { DocumentsFilterTypeEnum } from '../enums';
import { IManagementDocumentsState } from '../interfaces';

export const documentsInitialState: IManagementDocumentsState = {
  filter: {
    skip: 0,
    take: 0,
    search: undefined,
    type: DocumentsFilterTypeEnum.All,
    possibleTypes: [
      DocumentsFilterTypeEnum.All,
      DocumentsFilterTypeEnum.Viewed,
      DocumentsFilterTypeEnum.Unviewed,
      DocumentsFilterTypeEnum.Deleted,
    ],
  },
};
