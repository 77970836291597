import { CSSProperties, FC, memo, useMemo } from 'react';
import { IVBox } from './props';
import { useResponsive } from '../../../hooks/responsive';

import css from './index.module.scss';

export const VBox: FC<IVBox> = memo((props) => {
  const { isMobile } = useResponsive();
  
  const gap = useMemo(
    () => props.gap ? `${isMobile ? props.gap / 2 : props.gap}px` : undefined,
    [props.gap, isMobile],
  );
  
  const styles: CSSProperties = useMemo(
    () => ({
      gap,
      width: props.stretched === false ? undefined : '100%',
      alignItems: props.align,
      justifyContent: props.justify,
      marginBottom: props.marginBottom,
    }),
    [props.stretched, gap],
  );

  return (
    <div className={props.className || css.vbox} style={styles}>
      {props.children}
    </div>
  );
});
