import { useEffect, useRef } from 'react';

export const useOutsideClick = (callback: Function) => {
  const ref = useRef();

  useEffect(() => {
    const handleClick = (event: MouseEvent) => {
      if (ref.current && !(ref.current as HTMLElement).contains(event.target as Node)) {
        callback();
      }
    };

    document.addEventListener('click', handleClick, true);

    return () => {
      document.removeEventListener('click', handleClick, true);
    };
  }, [ref, callback]);

  return ref as unknown as null;
};
