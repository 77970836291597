import { FC, lazy } from 'react';
import { useRoutes } from 'react-router-dom';
import { PagePathsEnum } from '../../../enums/routes';
import { NoMatch } from '../../../components';
import { ErrorInfo } from '../../../components/error-info';
import { CustomOutlet } from '../../../components/custom-outlet';

const MainPage = lazy(() => import(/* webpackChunkName: "main-page" */ '../../../pages/main'));
const CoursesPage = lazy(
  () => import(/* webpackChunkName: "courses-page" */ '../../../pages/courses'),
);
const CoursePage = lazy(
  () => import(/* webpackChunkName: "course-page" */ '../../../pages/course'),
);
const StudyPage = lazy(() => import(/* webpackChunkName: "study-page" */ '../../../pages/study'));
const ManagementPage = lazy(
  () => import(/* webpackChunkName: "management-page" */ '../../../pages/management'),
);
const StudyRequestPage = lazy(
  () => import(/* webpackChunkName: "request-page" */ '../../study/containers/study-request'),
);

export const LayoutRoutes: FC = () =>
  useRoutes([
    {
      path: PagePathsEnum.Root,
      element: <MainPage />,
      errorElement: <ErrorInfo />,
    },
    {
      path: PagePathsEnum.Courses,
      element: <CoursesPage />,
      errorElement: <ErrorInfo />,
    },
    {
      path: `${PagePathsEnum.Courses}/:courseId`,
      element: <CoursePage />,
      errorElement: <ErrorInfo />,
    },
    {
      path: `${PagePathsEnum.StudyRequest}/*`,
      element: <StudyRequestPage />,
      errorElement: <ErrorInfo />,
    },
    {
      path: `${PagePathsEnum.Study}/*`,
      element: (
        <CustomOutlet
          shouldRedirect={(user) => user?.isStaff}
          navigatePath={`/${PagePathsEnum.Management}`}
        />
      ),
      children: [{ path: '*', element: <StudyPage /> }],
      errorElement: <ErrorInfo />,
    },
    {
      path: `${PagePathsEnum.Management}/*`,
      element: (
        <CustomOutlet
          withAuth={true}
          shouldRedirect={(user) => !user?.isStaff}
          navigatePath={`/${PagePathsEnum.Study}`}
        />
      ),
      children: [
        {
          path: '*',
          element: <ManagementPage />,
        },
      ],
      errorElement: <ErrorInfo />,
    },
    { path: '*', element: <NoMatch /> },
  ]);
