import { FC, memo, useCallback, useEffect, useMemo, useState } from 'react';
import { classNames } from '../../../../helpers/classnames';
import { NotificationProgress } from '../progress';
import { IToastProps } from './props';
import { MaterialIconText } from '../../../material-icon-text';
import { withTestId } from '../../../../helpers';
import { act } from 'react-dom/test-utils';

import css from './index.module.scss';
import { useResponsive } from '../../../../hooks/responsive';

interface IToastState {
  finalize: boolean;
  initialize: boolean;
  completed: number;
}

export const Toast: FC<IToastProps> = memo(({ notification, testId, onClose }) => {
  const [state, setState] = useState<IToastState>({
    finalize: false,
    initialize: false,
    completed: 0,
  });
  const tacts = notification.delay! / 500;
  const diff = 100 / tacts;
  const { isMobile } = useResponsive();

  const initAnimation = useCallback(() => {
    setTimeout(() => act(() => setState((prevState) => ({ ...prevState, initialize: true }))), 50);
  }, []);

  const finalAnimation = useCallback((delay = 2000) => {
    act(() => setState((prevState) => ({ ...prevState, finalize: true })));
    setTimeout(() => onClose(), delay);
  }, []);

  const progress = useCallback((completed: number) => {
    if (completed >= 100) {
      act(() => setState((prevState) => ({ ...prevState, completed: 0 })));
      return;
    }

    act(() =>
      setState((prevState) => ({ ...prevState, completed: Math.min(completed + diff, 100) })),
    );
  }, []);

  useEffect(() => {
    act(() => initAnimation());

    if (notification.delay !== Infinity) {
      setTimeout(() => act(() => finalAnimation(250)), notification.delay);
    }
  }, []);

  useEffect(() => {
    if (notification.delay !== Infinity) {
      const timer = setInterval(() => act(() => progress(state.completed)), 500);

      return () => clearInterval(timer);
    }
  }, [state.completed]);

  const className = useMemo(
    () =>
      classNames(css.notification, {
        [css.notification_ok]: notification.type === 'ok',
        [css.notification_error]: notification.type === 'error',
        [css.notification_info]: notification.type === 'info',
        [css.notification_init]: state.initialize,
        [css.notification_closing]: state.finalize,
      }),
    [notification.type, state.initialize, state.finalize],
  );

  return (
    <div role={'alert'} className={className} {...withTestId(testId || 'toast')}>
      {notification.text}
      {notification.closeable && (
        <div onClick={() => finalAnimation(250)} className={css.notificationClose}>
          <MaterialIconText size={!isMobile ? '16' : '32'} iconName={'close'} classes={'md-light'} />
        </div>
      )}
      {notification.delay !== Infinity && (
        <NotificationProgress
          value={state.completed}
          containerInlineStyles={{
            position: 'absolute',
            bottom: 0,
            left: '4px',
            width: 'calc(100% - 8px)',
          }}
        />
      )}
    </div>
  );
});
