import * as React from 'react';
import { IconProps } from '../types';

export default function ShoppingCartIcon30(props: IconProps): React.ReactElement {
  const { type, width = 30, heigth = 30 } = props;
  const mainColor = type === 'primary' ? '#DA6289' : '#AEAEAE';
  const secondaryColor = type === 'primary' ? '#EDB1C4' : '#D9D9D9';
  return (
    <svg
    width={width}
    height={heigth}
    fill="none"
  >
      <g>
        <path d="M29.15 5.125C28.4375 4.25 27.375 3.75 26.25 3.75H9.05L9 3.3125C8.775 1.425 7.175 0 5.275 0H3.75C3.0625 0 2.5 0.5625 2.5 1.25C2.5 1.9375 3.0625 2.5 3.75 2.5H5.275C5.9125 2.5 6.4375 2.975 6.5125 3.6L8.2375 18.225C8.6125 21.375 11.275 23.75 14.45 23.75H25C25.6875 23.75 26.25 23.1875 26.25 22.5C26.25 21.8125 25.6875 21.25 25 21.25H14.45C12.8375 21.25 11.45 20.2125 10.925 18.75H22.7C25.675 18.75 28.25 16.6375 28.825 13.725L29.925 8.2375C30.15 7.1375 29.8625 6 29.15 5.125Z" fill={mainColor}/>
        <path d="M11.25 30C12.625 30 13.75 28.875 13.75 27.5C13.75 26.125 12.625 25 11.25 25C9.875 25 8.75 26.125 8.75 27.5C8.75 28.875 9.875 30 11.25 30Z" fill={secondaryColor}/>
        <path d="M22.5 30C23.875 30 25 28.875 25 27.5C25 26.125 23.875 25 22.5 25C21.125 25 20 26.125 20 27.5C20 28.875 21.125 30 22.5 30Z" fill={secondaryColor}/>
        <path d="M1.25 6.25C0.5625 6.25 0 6.8125 0 7.5C0 8.1875 0.5625 8.75 1.25 8.75H3.175C3.8625 8.75 4.425 8.1875 4.425 7.5C4.425 6.8125 3.8625 6.25 3.175 6.25H1.25Z" fill={secondaryColor}/>
        <path d="M1.25 11.25C0.5625 11.25 0 11.8125 0 12.5C0 13.1875 0.5625 13.75 1.25 13.75H3.75C4.4375 13.75 5 13.1875 5 12.5C5 11.8125 4.4375 11.25 3.75 11.25H1.25Z" fill={secondaryColor}/>
        <path d="M5 18.75C5.6875 18.75 6.25 18.1875 6.25 17.5C6.25 16.8125 5.6875 16.25 5 16.25H1.25C0.5625 16.25 0 16.8125 0 17.5C0 18.1875 0.5625 18.75 1.25 18.75H5Z" fill={secondaryColor}/>
      </g>
      <defs>
        <clipPath id="clip0_5765_118342">
        <rect width="30" height="30" fill="white"/>
        </clipPath>
      </defs>
    </svg>
  );
}