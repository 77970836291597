import { MainPageState } from '../interfaces/state';

export const initialState: MainPageState = {
  filter: {
    skip: 0,
    take: 6,
    areas: [],
    period: undefined,
    format: undefined,
    certificate: undefined,
    region: undefined,
    eduRequirement: undefined,
    isFreeOnly: undefined,
    category: undefined,
    lang: undefined,
    university: undefined,
    courseCount: 30,
    price: undefined,
    startDate: undefined,
  },
  favoriteCourses: [],
  selectedCourses: [],
  filterOptions: {},
  isFilterOpen: false,
};
