import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { initialState } from './intitial-state';
import { DictionaryItem } from '../../../interfaces/core/dictionary-item';
import { IdCourse } from '../../../types/courses/id-course';
import { CourseFilterNames } from '../../../types/courses/courses-filter-names';
import { FiltersOptions } from '../../../interfaces/courses/filtersOptions';
import { ERootState } from '../../../interfaces/root-state';

export const slice = createSlice({
  name: ERootState.main,
  initialState,
  reducers: {
    selectGroup: (state, action: PayloadAction<DictionaryItem | undefined>) => {
      state.filter.group = action.payload;
    },
    setSearch: (state, action: PayloadAction<string | undefined>) => {
      state.filter.search = action.payload;
    },
    selectAreas: (state, action: PayloadAction<DictionaryItem[] | undefined>) => {
      state.filter.areas = action.payload;
    },
    selectPeriod: (state, action: PayloadAction<DictionaryItem | undefined>) => {
      state.filter.period = action.payload;
    },
    selectRegion: (state, action: PayloadAction<DictionaryItem | undefined>) => {
      state.filter.region = action.payload;
    },
    selectEduRequirement: (state, action: PayloadAction<DictionaryItem | undefined>) => {
      state.filter.eduRequirement = action.payload;
    },
    selectFormat: (state, action: PayloadAction<DictionaryItem | undefined>) => {
      state.filter.format = action.payload;
    },
    selectCertificate: (state, action: PayloadAction<DictionaryItem | undefined>) => {
      state.filter.certificate = action.payload;
    },
    selectByPrice: (state, action: PayloadAction<DictionaryItem | undefined>) => {
      state.filter.price = action.payload;
    },
    selectStartDate: (state, action: PayloadAction<DictionaryItem | undefined>) => {
      state.filter.startDate = action.payload;
    },
    resetFilterByName: (state, action: PayloadAction<CourseFilterNames>) => {
      state.filter[action.payload] = undefined;
    },
    toggleIsFree: (state, action: PayloadAction<boolean>) => {
      state.filter.isFreeOnly = action.payload;
    },
    selectCategory: (state, action: PayloadAction<DictionaryItem | undefined>) => {
      state.filter.category = action.payload;
      state.filter.group = undefined;
    },
    selectLanguage: (state, action: PayloadAction<DictionaryItem | undefined>) => {
      state.filter.lang = action.payload;
    },
    selectUniversity: (state, action: PayloadAction<DictionaryItem | undefined>) => {
      state.filter.university = action.payload;
    },
    addCourseToShow: (state, action: PayloadAction<number>) => {
      state.filter.courseCount! += action.payload;
    },
    resetFilter: (state) => {
      state.filter = initialState.filter;
    },
    setFiltersOptions: (state, action: PayloadAction<FiltersOptions>) => {
      state.filterOptions = action.payload;
    },
    setFavoriteCourses: (state, action: PayloadAction<IdCourse[]>) => {
      state.favoriteCourses = action.payload;
    },
    setSelectedCourses: (state, action: PayloadAction<IdCourse[]>) => {
      state.selectedCourses = action.payload;
    },
    toggleFiltersOpen: (state) => {
      state.isFilterOpen = !state.isFilterOpen;
    },
  },
});
