import { ReviewsDateTypeEnum, ReviewsFilterTypeEnum, ReviewsScoreTypeEnum } from '../enums';
import { IManagementReviewsState } from '../interfaces';

export const reviewsInitialState: IManagementReviewsState = {
  filter: {
    skip: 0,
    take: 0,
    search: undefined,
    type: ReviewsFilterTypeEnum.All,
    period: ReviewsDateTypeEnum.All,
    score: ReviewsScoreTypeEnum.All,
    possibleTypes: [
      ReviewsFilterTypeEnum.All,
      ReviewsFilterTypeEnum.Active,
      ReviewsFilterTypeEnum.Draft,
      ReviewsFilterTypeEnum.Hidden,
      ReviewsFilterTypeEnum.Archived,
    ],
    possiblePeriods: [
      ReviewsDateTypeEnum.All,
      ReviewsDateTypeEnum.Month,
      ReviewsDateTypeEnum.Year,
    ],
    possibleScores: [
      ReviewsScoreTypeEnum.All,
      ReviewsScoreTypeEnum.OneStar,
      ReviewsScoreTypeEnum.TwoStar,
      ReviewsScoreTypeEnum.ThreeStar,
      ReviewsScoreTypeEnum.FourStar,
      ReviewsScoreTypeEnum.FiveStar,
    ],
  },
};
