import * as SvgPathes from '../../icons/svg/paths';
import { IconSvg } from '../../icons';

export const iconDown =
    <IconSvg
        pathEl={SvgPathes.dropDownArrow.path}
        viewBoxX={SvgPathes.dropDownArrow.viewBoxX}
        viewBoxY={SvgPathes.dropDownArrow.viewBoxY}
        width={10}
        height={10}
        fill='neutral'
    />;

export const iconUp =
    <IconSvg
        pathEl={SvgPathes.dropDownArrow.path}
        viewBoxX={SvgPathes.dropDownArrow.viewBoxX}
        viewBoxY={SvgPathes.dropDownArrow.viewBoxY}
        transform='rotate(180)'
        width={10}
        height={10}
        fill='neutral'
    />;