import { FC, memo } from 'react';

import { classNames } from '../../../../../../../helpers/classnames';

import css from './index.module.scss';
import { IHeaderDropdown } from '../../../../interfaces/header-dropdown';

export const HeaderDropdown: FC<IHeaderDropdown> = memo(({ isVisible, className, ...rest }) => {
  const classes = classNames(
    {
      [css.headerDropdown]: true,
      [css.headerDropdownVisible]: isVisible,
    },
    className,
  );

  return <div className={classes}>{rest.children}</div>;
});
