import { FiltersOptions } from '../interfaces/courses/filtersOptions';
import { Course } from '../interfaces/courses/course';

export const getFiltersOptions = (courses: Course[]): FiltersOptions => {
  const optionsFromFilteredCourses: FiltersOptions = {
    groups: [],
    areas: [],
    regions: [],
    formats: [],
    certificates: [],
    periods: [],
    eduRequirements: [],
    categories: [],
    universities: [],
    // ниже опции не реализованы динамически, hardcode в селекторах
    langs: [],
    prices: [],
    startDates: [],
  };

  courses.forEach((course) => {
    if (course.groups && course.groups.length > 0) {
      course.groups.forEach((group) => {
        if (!optionsFromFilteredCourses.groups.some((option) => option.id === group.id)) {
          optionsFromFilteredCourses.groups.push(group);
        }
      });
    }
    if (course.areas && course.areas.length > 0) {
      course.areas.forEach((area) => {
        if (!optionsFromFilteredCourses.areas.some((option) => option.id === area.id)) {
          optionsFromFilteredCourses.areas.push(area);
        }
      });
    }
    if (course.regions && course.regions.length > 0) {
      course.regions.forEach((region) => {
        if (!optionsFromFilteredCourses.regions.some((option) => option.id === region.id)) {
          optionsFromFilteredCourses.regions.push(region);
        }
      });
    }
    if (course.formats && course.formats.length > 0) {
      course.formats.forEach((format) => {
        if (!optionsFromFilteredCourses.formats.some((option) => option.id === format.id)) {
          optionsFromFilteredCourses.formats.push(format);
        }
      });
    }
    if (course.certificates && course.certificates.length > 0) {
      course.certificates.forEach((certificate) => {
        if (
          !optionsFromFilteredCourses.certificates.some((option) => option.id === certificate.id)
        ) {
          optionsFromFilteredCourses.certificates.push(certificate);
        }
      });
    }
    if (course.periods && course.periods.length > 0) {
      course.periods.forEach((period) => {
        if (!optionsFromFilteredCourses.periods.some((option) => option.id === period.id)) {
          optionsFromFilteredCourses.periods.push(period);
        }
      });
    }
    if (course.eduRequirement) {
      if (
        !optionsFromFilteredCourses.eduRequirements.some(
          (option) => option.id === course.eduRequirement!.id,
        )
      ) {
        optionsFromFilteredCourses.eduRequirements.push(course.eduRequirement);
      }
    }
    if (course.categories && course.categories.length > 0) {
      course.categories.forEach((category) => {
        if (!optionsFromFilteredCourses.categories.some((option) => option.id === category.id)) {
          optionsFromFilteredCourses.categories.push(category);
        }
      });
    }
    if (course.university) {
      if (
        !optionsFromFilteredCourses.universities.some(
          (option) => option.id === course.university!.id,
        )
      ) {
        optionsFromFilteredCourses.universities.push(course.university);
      }
    }
    if (course.lang) {
      if (!optionsFromFilteredCourses.langs.some((option) => option.id === course.lang!.id)) {
        optionsFromFilteredCourses.langs.push(course.lang);
      }
    }
    if (course.price) {
      if (!optionsFromFilteredCourses.prices.some((option) => option.id === course.price!.id)) {
        optionsFromFilteredCourses.prices.push(course.price);
      }
    }
    if (course.startDate) {
      if (
        !optionsFromFilteredCourses.startDates.some((option) => option.id === course.startDate!.id)
      ) {
        optionsFromFilteredCourses.startDates.push(course.startDate);
      }
    }
  });

  // сортировка для вывода опций интервалов стоимости от меньшей к большей
  optionsFromFilteredCourses.prices.sort((a, b) => {
    if (!/\d+/.test(a.title)) {
      return -1;
    }

    if (!/\d+/.test(b.title)) {
      return 1;
    }

    return +a.title.match(/\d+/)![0] - +b.title.match(/\d+/)![0];
  });

  return optionsFromFilteredCourses;
};
