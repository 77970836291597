import { PayloadAction } from '@reduxjs/toolkit';

import { PageSliceCaseReducers } from '../../../types';
import { StatsPeriodTypeEnum } from '../enums';

export const statsReducers: PageSliceCaseReducers = {
    setStatsUserPeriod: (state, action: PayloadAction<StatsPeriodTypeEnum>) => {
        state.stats.filter.userPeriod = action.payload;
    },
    setStatsCoursePeriod: (state, action: PayloadAction<StatsPeriodTypeEnum>) => {
        state.stats.filter.coursePeriod = action.payload;
    },
    setStatsLocationPeriod: (state, action: PayloadAction<StatsPeriodTypeEnum>) => {
        state.stats.filter.locationPeriod = action.payload;
    },
};
