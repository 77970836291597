import { useEffect } from 'react';
import { selectCurrentUser } from '../pages/auth/store/helpers';
import { setSelectedCourses } from '../pages/main/store/actions';
import { useAppDispatch, useAppSelector } from './hooks';
import { useApiListCartCreateMutation, useLazyApiListCartListQuery } from '../services/base-api';
import { selectedStorage } from '../services/storage/selected-storage';
import { Course as MappedCourse} from '../../app/interfaces/courses/course';
import { useAllCoursesList } from '../../app/pages/main/hooks/all-courses-list';


export const useInitSelectedCourses = () => {
  const user = useAppSelector(selectCurrentUser);
  const dispatch = useAppDispatch();
  const [getSelectedCourses] = useLazyApiListCartListQuery();
  const [postSelectedCourses] = useApiListCartCreateMutation();
  
  const { noData, courses, isMore, getMore, allCourses } = useAllCoursesList();

  useEffect(() => {
    (async () => {
      const localCourses = selectedStorage.getData();
      
      let activeLocalCourses = localCourses;
      if (allCourses.length > 0) {
        activeLocalCourses = localCourses.filter((courseId: string) => {
            return allCourses.some((course: MappedCourse) => {
              return course.id === courseId;
            });
          });
      }

      if (!user) {
        dispatch(setSelectedCourses(activeLocalCourses));        
        selectedStorage.setStorage(activeLocalCourses);
      } else {
        if (activeLocalCourses.length > 0) {
          await postSelectedCourses({ body: activeLocalCourses });
        }
        const data = await getSelectedCourses().unwrap();
        const coursesIds = data?.map((list) => list.course.id) ?? [];
        dispatch(setSelectedCourses(coursesIds));
        selectedStorage.setStorage(coursesIds);
      }
    })();
  }, [user, dispatch, getSelectedCourses, postSelectedCourses, allCourses]);

  return null;
};
