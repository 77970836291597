import { FC } from 'react';

import { Toast } from './toast';
import { useAppDispatch, useAppSelector } from '../../../hooks/hooks';
import { removeNotification } from '../../../state/actions';
import { withTestId } from '../../../helpers';

import css from './toasts.module.scss';

export const Toasts: FC = () => {
  const dispatch = useAppDispatch();
  const notifications = useAppSelector((state) => state.app.notifications);

  return (
    <div className={css.toastsContainer} {...withTestId('toastsContainer')}>
      {notifications?.map((item) => (
        <Toast
          key={item.key}
          notification={item.value}
          onClose={() => dispatch(removeNotification(item.key))}
        />
      ))}
    </div>
  );
};
