import { IManagementPageState } from '../interfaces';
import { documentsInitialState } from '../containers/documents/store/intitial-state';
import { groupsInitialState } from '../containers/groups/store/intitial-state';
import { professorsInitialState } from '../containers/professors/store/intitial-state';
import { requestsInitialState } from '../containers/requests/store/intitial-state';
import { reviewsInitialState } from '../containers/reviews/store/intitial-state';
import { statsInitialState } from '../containers/stats/store/intitial-state';
import { coursesInitialState } from '../containers/courses/store/intitial-state';

export const initialState: IManagementPageState = {
  professors: professorsInitialState,
  documents: documentsInitialState,
  reviews: reviewsInitialState,
  requests: requestsInitialState,
  groups: groupsInitialState,
  stats: statsInitialState,
  courses: coursesInitialState,
};
