import { FC, memo, useCallback, useEffect, useMemo, useState } from 'react';
import { IDateFieldProps } from './props';
import { useOutsideClick } from '../../../../hooks/outside-click';

import { formatDate, toDate } from '../helpers';
import { IDay } from '../day';
import { TextField } from '../../text-field';
import { MaterialIconText } from '../../../material-icon-text';
import { withTestId } from '../../../../helpers';
import { Dropdown } from '../../dropdown';
import { SingleDatePicker } from '../single-date-picker';
import { classNames } from '../../../../helpers/classnames';
import { formatWithPrefix, stringToDate } from '../../../../helpers/date';
import { KeyboardKeyEnum } from '../../../../enums';

import css from './index.module.scss';

interface IDateFieldState {
  isOpened?: boolean;
  inputDay?: string;
  range?: Date[];
  selectedDay?: Date;
}

const formatInput = (date: Date): string => {
  return new Intl.DateTimeFormat('ru', {
    year: 'numeric',
    month: '2-digit',
    day: '2-digit',
  }).format(date);
};

export const DateField: FC<IDateFieldProps> = memo((props) => {
  const getInputDay = (): string => {
    if (!props.isRange) {
      return props.value ?? '';
    }

    if (props.range) {
      const _value = [...props.range].map((val: Date) => formatInput(val));
      return (_value && _value.join('-')) || '';
    }

    return '';
  };

  const [state, setState] = useState<IDateFieldState>({
    isOpened: false,
    inputDay: getInputDay(),
    range: props.range,
  });

  const selectRef = useOutsideClick(() => {
    if (props.isDisabled || !state.isOpened) {
      return;
    }

    setState((prevState) => ({
      ...prevState,
      isOpened: false,
    }));
  });

  const clearDateHours = (date: Date) => formatDate(date, props.locale);

  const handleSelectDay = (value?: IDay) => {
    props.onSelect && props.onSelect(value && value.date ? clearDateHours(value.date) : null);

    setState((prevState) => ({
      ...prevState,
      inputDay: formatDate(value?.date, props.locale),
      selectedDay: value?.date,
      isOpened: false,
    }));
  };

  const handleClick = () => {
    if (props.isDisabled) {
      return;
    }

    if (!state.isOpened) {
      setState((prevState) => ({
        ...prevState,
        isOpened: true,
      }));
    }
  };

  const handleEnter: React.KeyboardEventHandler<HTMLDivElement> = (event) => {
    if (event.key === KeyboardKeyEnum.Enter && event.target === document.activeElement) {
      if (state.isOpened) {
        setState((prevState) => ({
          ...prevState,
          isOpened: false,
        }));
      }
    }
  };

  const separator = useMemo(() => props.locale === 'ru' ? '.' : '/', [props.locale]);
  const handleChange = useCallback((value?: string) => {
    if (props.isReadOnly || props.isDisabled || !value) {
      return;
    }

    const newStringDateFromInput = formatWithPrefix(value, separator);
    const newDateFromInput = stringToDate(newStringDateFromInput, separator);
    
    setState((prevState) => ({
      ...prevState,
      inputDay: newStringDateFromInput,
      selectedDay: newDateFromInput,
    }));
  }, [separator, props.locale]);

  const rightElement = useMemo(
    () => (
      <MaterialIconText
        classes={css.dateFieldIcon}
        iconName={'calendar_month'}
        onClick={handleClick}
      />
    ),
    [],
  );
  const defaultPlaceholder = useMemo(
    () => (props.locale === 'ru' ? 'ДД.ММ.ГГГГ' : 'DD/MM/YYYY'),
    [props.locale],
  );

  useEffect(() => {
    setState((prevState) => ({
      ...prevState,
      inputDay: formatDate(toDate(props.value, props.locale), props.locale),
      selectedDay: toDate(props.value, props.locale),
    }));
  }, [props.value, props.locale]);

  return (
    <div
      ref={selectRef}
      className={classNames(css.dateField, props.className)}
      style={props.fullWidth && { width: '100%' }}
      onKeyDown={handleEnter}
      {...withTestId('dateField')}
    >
      <TextField
        maxWidth={props.maxWidth}
        onClear={handleSelectDay}
        onClick={handleClick}
        onChange={(value) => handleChange(value)}
        placeholder={props.placeholder || defaultPlaceholder}
        rightElement={rightElement}
        value={state.inputDay || ''}
        testId={'dateFieldInput'}
        readOnly={props.isReadOnly === undefined ? true : props.isReadOnly}
        {...props}
      />
      {state.isOpened && (
        <Dropdown testId={'dateFieldDropdown'}>
          <SingleDatePicker
            selectedDay={state.selectedDay}
            onSelect={handleSelectDay}
            minDate={props.minDate}
            maxDate={props.maxDate}
            locale={props.locale}
          />
        </Dropdown>
      )}
    </div>
  );
});
