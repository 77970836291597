import { FC, memo, useMemo, useState } from 'react';
import { NavLink, useLocation, useNavigate } from 'react-router-dom';
import { useTranslation } from 'i18nano';

import { MaterialIconText } from '../../../../../components/material-icon-text';
import { MobileSideMenu } from '../../../../../components/mobile-side-menu';
import { AppConst } from '../../../../../constants/app';
import { HBox, VBox } from '../../../../../components/layout';
import { HeaderNavMenu } from '../nav-menu';
import { useUserProfile } from '../../../../../hooks/auth';
import { PagePathsEnum } from '../../../../../enums/routes';
import { UserProfile } from '../profile-menu/components/user-profile';
import { UserProfileSubmenu } from '../profile-menu/components/user-profile-submenu';
import { LabelValuePair } from '../../../../../components';

import css from './index.module.scss';

export const HeaderMobileView: FC = memo(() => {
  const [isNavMenuOpened, setIsNavMenuOpened] = useState<boolean>(false);
  const [isProfileMenuOpened, setIsProfileMenuOpened] = useState<boolean>(false);

  const { user } = useUserProfile();
  const navigate = useNavigate();
  const location = useLocation();
  const translate = useTranslation();

  const toggleNavMenuOpen = () => {
    if (isProfileMenuOpened) { toggleProfileMenuOpen(); }

    setIsNavMenuOpened(!isNavMenuOpened);
    toggleScroll();
  };

  const toggleProfileMenuOpen = () => {
    if (isNavMenuOpened) { toggleNavMenuOpen(); }

    setIsProfileMenuOpened(!isProfileMenuOpened);
    toggleScroll();
  };

  const login = () => {
    navigate(PagePathsEnum.Auth, { state: { prevPage: location.pathname } });
  };

  const toggleScroll = () => {
    document.querySelector('html')?.classList.toggle(`${AppConst.Name}-lock-scroll`);
  };

  const profileRole = useMemo(() => user?.isStaff
    ? translate('header.admin')
    : user?.profileName ?? translate('header.profileNameFallback'),
    [user, translate]
  );

  const profileName = useMemo(() => {
    if (!user) { return ''; }

    return `${user.firstName} ${user.lastName}`;
  }, [user]);

  const profileLabelClasses = useMemo(() => ({ color: '#000000', fontSize: '16px' }), []);
  const profileValueClasses = useMemo(() => ({ fontSize: '16px' }), []);

  return (
    <div className={css.header}>
      <HBox align={'center'} stretched={true} justify={'space-between'}>
        <MaterialIconText size={'32'} iconName={isNavMenuOpened ? 'close' : 'menu'} classes={css.backIcon} onClick={toggleNavMenuOpen} />
        <NavLink to={'/#'}>
          <img src={'/images/logo.svg'} className={css.headerLogo} />
        </NavLink>
        {!user && <MaterialIconText size={'32'} iconName={'account_circle'} classes={css.backIcon} onClick={login} />}
        {!!user && <span onClick={toggleProfileMenuOpen}><UserProfile /></span>}
      </HBox>
      {
        isNavMenuOpened &&
        <MobileSideMenu>
          <HeaderNavMenu onMenuItemClick={toggleNavMenuOpen} />
        </MobileSideMenu>
      }
      {
        isProfileMenuOpened &&
        <MobileSideMenu>
          <div className={css.headerProfile}>
            <VBox gap={8}>
              {profileName && <LabelValuePair label={translate('header.profileNameLabel')} labelStyles={profileLabelClasses} valueStyles={profileValueClasses} value={profileName} />}
              {profileRole && <LabelValuePair label={translate('header.profileRoleLabel')} labelStyles={profileLabelClasses} valueStyles={profileValueClasses} value={profileRole} />}
            </VBox>
          </div>
          <UserProfileSubmenu onNavigateClick={toggleProfileMenuOpen} />
        </MobileSideMenu>
      }
    </div>
  );
});
