import { FC, memo, useEffect, useRef, useState } from 'react';

import { close } from '../icons/svg/paths';
import { IconSvg } from '../icons';
import { ITextFieldProps } from './props';
import { classNames } from '../../../helpers/classnames';
import { withTestId } from '../../../helpers';

import css from './index.module.scss';

interface ITextFieldState {
  canClear?: boolean;
  value?: string | number;
}

export const TextField: FC<ITextFieldProps> = memo(({ ...props }) => {
  const [state, setState] = useState<ITextFieldState>({
    value: props.value ?? '',
    canClear: props.canClear && !!props.value,
  });

  useEffect(() => {
    setState((prevState) => ({
      ...prevState,
      value: props.value,
      canClear: props.canClear && !!props.value,
    }));
  }, [props.value]);

  const wrapperStyleClasses = classNames(css.textField, {
    [css.textFieldDisabled]: props.isDisabled,
    [css.textFieldResponsive]: props.isResponsive,
  });

  const inputStyleClass = classNames(css.textFieldInput, {
    [css.textFieldReadOnly]: props.readOnly,
    [css.textFieldInputClearable]: props.canClear,
  });

  const fieldWidth = props.maxWidth === Infinity ? '100%' : `${props.maxWidth}px`;

  const handleClear: React.MouseEventHandler<HTMLSpanElement> = (e) => {
    e.stopPropagation();

    setState({ canClear: false, value: '' });

    if (props.onClear) {
      props.onClear();
    }

    if (props.onChange) {
      props.onChange('');
    }
  };

  const handleClick = () => {
    if (props.onClick) {
      props.onClick();
    }
  };

  const handleChange: React.ChangeEventHandler<HTMLInputElement> = (e) => {
    const nextValue = e.target.value;

    setState(() => ({
      canClear: props.canClear && !!nextValue,
      value: nextValue,
    }));

    if (props.onChange) {
      props.onChange(nextValue);
    }
  };

  const handleFocus = () => {
    if (props.onFocus) {
      props.onFocus();
    }
  };

  const handleBlur = () => {
    if (props.onBlur) {
      props.onBlur();
    }
  };

  const inputRef = useRef();

  return (
    <div
      className={wrapperStyleClasses}
      style={{ width: fieldWidth }}
      onClick={handleClick}
      {...withTestId(props.testId || 'textWrapper')}
    >
      {
        props.title && <label className={css.textFieldLabel}>
          {props.title}
          {props.isRequired && <sup>*</sup>}
        </label>
      }
      <div className={css.textFieldMain}>
        <input
          ref={inputRef.current}
          type={props.type || 'text'}
          className={inputStyleClass}
          placeholder={props.placeholder}
          name={props.name}
          onChange={handleChange}
          onFocus={handleFocus}
          onBlur={handleBlur}
          value={state.value}
          disabled={props.isDisabled || false}
          maxLength={props.maxLength}
          role={'textbox'}
          readOnly={props.readOnly}
          aria-required={props.isRequired}
          aria-placeholder={props.placeholder}
        />
        <div className={css.textFieldRightIcons}>
          {state.canClear && !props.isDisabled && (
            <span className={css.textFieldClearButton} data-testid='clearBtn' onClick={handleClear}>
              <IconSvg
                pathEl={close.path}
                viewBoxX={close.viewBoxX}
                viewBoxY={close.viewBoxY}
                width={10}
                height={10}
                fill='neutral'
              />
            </span>
          )}
          {props.rightElement && (
            <span className={css.textFieldIconRight} onClick={handleClick}>
              {props.rightElement}
            </span>
          )}
        </div>
      </div>
      {props.disclaimer && <div className={css.textFieldDisclaimer}>{props.disclaimer}</div>}
      {!!props.errorMessage && !props.isValid && (
        <div className={css.textFieldErrorMessage}>{props.errorMessage}</div>
      )}
    </div>
  );
});
