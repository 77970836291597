import { FC, memo, useRef } from 'react';
import DOMPurify from 'dompurify';

import { ISelectItemProps } from './item-props';
import { withTestId } from '../../../../helpers';
import { classNames } from '../../../../helpers/classnames';

import css from './item.module.scss';

export const SelectItem: FC<ISelectItemProps> = memo((props) => {
  const ref = useRef<HTMLDivElement>(null);

  const handleClick = () => {
    props.onClick && props.onClick();
  };

  return <div ref={ref} className={classNames(css.selectItem, {[css.selectItem_empty]: props.isEmpty })} onClick={handleClick} {...withTestId('selectItem')}>
    <div className={css.selectItem__title} dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(props.html || props.title  || '') }} />
    {props.subtitle && <div className={css.selectItem__subtitle}>{props.subtitle}</div>}
    </div>;
});
