import { GroupsStatusEnum } from '../enums';
import { IManagementGroupsState } from '../interfaces';

export const groupsInitialState: IManagementGroupsState = {
  filter: {
    skip: 0,
    take: 0,
    search: undefined,
    groupStatus: GroupsStatusEnum.All,
    possibleGroupStatuses: [
      GroupsStatusEnum.All,
      GroupsStatusEnum.Active,
      GroupsStatusEnum.Draft,
      GroupsStatusEnum.Hidden,
      GroupsStatusEnum.Archived,
    ],
  },
};
