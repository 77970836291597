import { CSSProperties, FC, memo, useMemo } from 'react';
import { ICircleLogo } from './props';

import css from './index.module.scss';
import { handleImageError } from '../../helpers/image-error';

export const CircleLogo: FC<ICircleLogo> = memo((props) => {
  const styles: CSSProperties = useMemo(
    () =>
      props.size
        ? {
            width: `${props.size}px`,
            height: `${props.size}px`,
          }
        : {},
    [props.size],
  );

  return (
    <img
      className={css.circleLogo}
      src={props.url || props.fallbackUrl || '/images/profiles/userIcon.svg'}
      alt={'logo'}
      style={styles}
      onError={handleImageError(props.fallbackUrl || '/images/profiles/userIcon.svg')}
    />
  );
});
