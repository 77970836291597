export const enum PagePathsEnum {
  Root = '/',
  Auth = 'auth',
  Login = 'login',
  Register = 'register',
  Layout = 'layout',
  Main = 'main',
  Courses = 'courses',
  Study = 'study',
  Profile = 'profile',
  News = 'news',
  Info = 'info',
  Contacts = 'contacts',
  StudyRequest = 'request',

  Management = 'management',
  ManagementCommon = 'common',
  ManagementProfile = 'profile',
  ManagementCompanies = 'companies',
  ManagementGroups = 'groups',
  ManagementCourses = 'courses',
  ManagementCoursesList = 'list',
  ManagementCoursesNew = 'new',
  ManagementCalendar = 'calendar',
  ManagementProfessors = 'professors',
  ManagementStats = 'stats',
  ManagementRequests = 'requests',
  ManagementReviews = 'reviews',
  ManagementDocuments = 'documents',
}
