import { FC, memo, useEffect, useMemo, useState } from 'react';
import { TextField } from '../text-field';
import { Dropdown } from '../dropdown';
import { SelectItem } from './item';
import { iconDown, iconUp } from './icons';
import { Loader } from '../loader';
import { IDropdownItem, ISelectProps } from './select-props';
import { useOutsideClick } from '../../../hooks/outside-click';
import { withTestId } from '../../../helpers';

import css from './select.module.scss';
import { classNames } from '../../../helpers/classnames';

type NotDefinedString = string | null | undefined;

interface ISelectState {
  isOpened?: boolean;
  selectedItem?: IDropdownItem | null;
  dropdownItems?: IDropdownItem[];
  originalDropdownItems: IDropdownItem[];
  searchingInProgress?: boolean;
  searchString: NotDefinedString;
}

export const Select: FC<ISelectProps> = memo(
  ({ maxWidth = Infinity, timeout = 500, canClear = true, stretched = true, ...restProps }) => {
    const [state, setState] = useState<ISelectState>({
      isOpened: false,
      selectedItem: restProps.selectedItem,
      dropdownItems: [],
      originalDropdownItems: [],
      searchingInProgress: false,
      searchString: '',
    });
    const selectRef = useOutsideClick(() => {
      if (restProps.isDisabled) {
        return;
      }

      setState((prevState) => ({
        ...prevState,
        isOpened: false,
        searchString: state.selectedItem?.title || '',
      }));
    });

    const load = (searchString?: NotDefinedString, skip?: number, take?: number): void => {
      if (state.searchingInProgress) {
        return;
      }

      setState((prevState) => ({ ...prevState, searchingInProgress: true }));
      restProps.query &&
        restProps.query(searchString || state.searchString, skip || 0, take || 30, queryCallback);
    };

    const queryCallback = (items: IDropdownItem[]) => {
      setState((prevState) => ({
        ...prevState,
        dropdownItems: items,
        originalDropdownItems: items,
        searchingInProgress: false,
      }));
    };

    const handleFind = (value?: string) => {
      let timer: NodeJS.Timeout | null = null;
      const searchString = value || '';
      setState((prevState) => ({
        ...prevState,
        searchString,
        searchingInProgress: true,
      }));
      timer && clearTimeout(timer);

      timer = setTimeout(() => {
        setState((prevState) => ({ ...prevState, searchingInProgress: false }));
        const filteredItems = state.originalDropdownItems.filter((item) =>
          item.title?.toLowerCase().includes(searchString.toLowerCase()),
        );
        setState((prevState) => ({
          ...prevState,
          dropdownItems: filteredItems,
        }));
      }, timeout);
    };

    const handleClear = () => handleSelect(null);

    const handleSelect = (item?: IDropdownItem | null) => {
      if (!item) {
        setState((prevState) => ({
          ...prevState,
          selectedItem: null,
          isOpened: false,
          searchString: '',
        }));
        restProps.onSelect && restProps.onSelect();

        return;
      }

      if (state.selectedItem && item.id === state.selectedItem.id) {
        setState((prevState) => ({
          ...prevState,
          isOpened: false,
          searchString: state.selectedItem!.title,
        }));
        return;
      }

      setState((prevState) => ({
        ...prevState,
        isOpened: false,
        searchString: item.title,
        selectedItem: item,
      }));
      restProps.onSelect && restProps.onSelect({ id: item.id!, title: item.title! });
    };

    const handleClick = () => {
      if (restProps.isDisabled) {
        return;
      }

      if (!state.isOpened) {
        setState((prevState) => ({
          ...prevState,
          isOpened: true,
          searchString: state.selectedItem?.title ?? '',
          dropdownItems: [],
        }));

        load();
        return;
      }

      setState((prevState) => ({
        ...prevState,
        isOpened: false,
      }));
    };

    const handleBlur = () => {
      if (restProps.onBlur == null)
        return;
      restProps.onBlur();
    };

    // const queryCallback = (items: IDropdownItem[]) => {
    //   setState((prevState) => ({ ...prevState, dropdownItems: items, searchingInProgress: false }));
    // };

    useEffect(() => {
      setState((prevState) => ({
        ...prevState,
        selectedItem: restProps.selectedItem,
        searchString: restProps.selectedItem?.title ?? '',
      }));
    }, [restProps.selectedItem]);

    const emptyItem = useMemo(
      () => <SelectItem isEmpty={true} onClick={handleClear} title={restProps.emptyText} />,
      [restProps.emptyText],
    );

    const rightElement = useMemo(
      () =>
        !state.searchingInProgress ? (
          state.isOpened ? (
            iconUp
          ) : (
            iconDown
          )
        ) : (
          <Loader
            style={{ width: '20px', height: '20px' }}
            strokeWidth='2'
            animationDuration='.5s'
          />
        ),
      [state.searchingInProgress, state.isOpened],
    );

    const fieldWidth = useMemo(
      () => (maxWidth === Infinity ? '100%' : `${maxWidth}px`),
      [maxWidth],
    );

    const classes = useMemo(
      () => classNames(css.select, { [css.select_responsive]: restProps.isResponsive }),
      [restProps.isResponsive],
    );

    return (
      <div
        className={classes}
        ref={selectRef}
        style={{ width: fieldWidth }}
        {...withTestId('selectWrapper')}
      >
        <TextField
          maxWidth={maxWidth}
          onChange={handleFind}
          onClear={handleClear}
          onClick={handleClick}
          onBlur={handleBlur}
          placeholder={restProps.placeholder}
          rightElement={restProps.fullDisabled ? undefined : rightElement}
          value={
            restProps.dependofValue
              ? restProps.value && state.searchString
                ? state.searchString
                : ''
              : state.searchString
              ? state.searchString
              : ''
          }
          canClear={canClear}
          testId={'selectTextSearch'}
          readOnly={restProps.readonly || restProps.fullDisabled}
          isDisabled={restProps.fullDisabled}
          isResponsive={restProps.isResponsive}
        />
        {state.isOpened && !restProps.fullDisabled && (
          <Dropdown stretched={stretched} testId={'selectDropdown'}>
            <div
              className={css.select__list}
              style={{
                height: restProps.maxHeight
                  ? `${restProps.maxHeight}px`
                  : `${Math.min(Math.max(state.dropdownItems?.length! * 35, 48), 270)}px`,
                overflowY: 'auto',
              }}
              {...withTestId('selectList')}
            >
              {canClear && restProps.emptyText && emptyItem}
              {state.dropdownItems?.map((p: IDropdownItem) => {
                return <SelectItem {...p} key={p.id} onClick={() => handleSelect(p)} />;
              })}
            </div>
          </Dropdown>
        )}
      </div>
    );
  },
);
