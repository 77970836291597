/* eslint-disable camelcase */
import { BaseQueryFn, FetchArgs, FetchBaseQueryError } from '@reduxjs/toolkit/dist/query';
import { Mutex } from 'async-mutex';

import { UsersTokenRefreshResponse200 } from './generated';
import { baseQuery } from './base-query';
import { AuthStorage } from '../auth/auth-storage';
import { setProfile } from '../../pages/auth/store/slice';

const mutex = new Mutex(); // to avoid multiple refresh requests

export const queryWithReauth: BaseQueryFn<
  string | FetchArgs,
  unknown,
  FetchBaseQueryError
> = async (args, api, extraOptions) => {
  await mutex.waitForUnlock();

  let result = await baseQuery(args, api, extraOptions);
  if (result.error && (result.error.status === 401 || result.error.status === 403)) {
    const authData = AuthStorage.getData();
    if (!authData?.token && !authData?.refreshtoken) {
      return result;
    }

    if (!mutex.isLocked()) {
      const release = await mutex.acquire();

      try {
        // try to get a new token
        const refreshResult = await baseQuery(
          {
            url: '/api/users/login/refresh/',
            method: 'POST',
            body: {
              refresh: authData.refreshtoken,
              access: authData.token,
            },
          },
          api,
          extraOptions,
        );
        const refreshData = refreshResult.data as UsersTokenRefreshResponse200;

        if (refreshData) {
          AuthStorage.setData({
            login: authData.login,
            token: refreshData.access,
            refreshtoken: authData.refreshtoken,
          });

          // retry the initial query
          result = await baseQuery(args, api, extraOptions); // retry the initial query
        } else {
          // logout
          api.dispatch(setProfile({ user: undefined }));
          AuthStorage.clearData();

          await baseQuery(
            {
              url: '/api/users/logout/',
              method: 'POST',
              body: { refresh_token: authData!.refreshtoken },
            },
            api,
            extraOptions,
          );

          window.location.href = '#/auth';
        }
      } finally {
        release(); // release must be called once the mutex should be released again.
      }
    } else {
      // wait until the mutex is available without locking it
      await mutex.waitForUnlock();
      result = await baseQuery(args, api, extraOptions);
    }
  }

  return result;
};
