import { AppState } from './state';
import { baseApi } from '../services/base-api';
import { AuthState } from '../pages/auth/interfaces/state';
import { MainPageState } from '../pages/main/interfaces/state';
import { StudyPageState } from '../pages/study/interfaces/state';
import { IManagementPageState } from '../pages/management/interfaces';

export interface RootState {
  app: AppState;
  auth: AuthState;
  main: MainPageState;
  study: StudyPageState;
  management: IManagementPageState;
  remoteApi?: typeof baseApi;
}

export enum ERootState {
  app = 'app',
  auth = 'auth',
  main = 'main',
  study = 'study',
  management = 'management',
}
