import { slice } from './slice';

export const {
  selectGroup,
  setSearch,
  selectAreas,
  selectPeriod,
  selectRegion,
  selectFormat,
  selectEduRequirement,
  selectCertificate,
  selectByPrice,
  selectStartDate,
  toggleIsFree,
  selectCategory,
  resetFilter,
  selectLanguage,
  selectUniversity,
  resetFilterByName,
  addCourseToShow,
  setFiltersOptions,
  setFavoriteCourses,
  setSelectedCourses,
  toggleFiltersOpen,
} = slice.actions;
