import { FC, memo } from 'react';
import { useTranslation } from 'i18nano';

import { ISearchFieldProps } from './props';
import { MaterialIconText } from '../material-icon-text';
import { TextField } from '../ui-kit/text-field';

import css from './index.module.scss';

export const SearchField: FC<ISearchFieldProps> = memo((props) => {
  const translate = useTranslation();

  return (
    <TextField
      placeholder={translate('filters.search.placeholder')}
      rightElement={<MaterialIconText iconName={'search'} classes={css.searchIcon} />}
      value={props.value}
      onChange={props.onChange}
      onClear={props.onClear}
      onFocus={props.onFocus}
      testId={'searchField'}
      canClear={true}
    />
  );
});
