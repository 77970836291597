import { memo } from 'react';

import css from './index.module.scss';

export const InfiniteProgressBar: React.FC = memo(() => {
  return (
    <div className={css.progressBar} role='progressbar'>
      <div className={css.progressBarValue} />
    </div>
  );
});
