import { FC, memo } from 'react';
import { INotificationProgressProps } from './props';
import { withTestId } from '../../../../helpers';

import css from './index.module.scss';

export const NotificationProgress: FC<INotificationProgressProps> = memo(({
  value,
  containerInlineStyles,
}) => {
  const inlineStyles = { transform: '' };

  if (value !== undefined) {
    inlineStyles.transform = `scaleX(${value / 100})`;
  }

  return (
    <div className={css.notificationProgress} style={containerInlineStyles} role='progressbar' {...withTestId('progressbar')}>
      <div className={css.notificationProgress__bar} style={inlineStyles} />
    </div>
  );
});
