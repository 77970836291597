import { AppConst } from '../../../../../constants/app';
import { CoursesStatusEnum } from '../enums';
import { IManagementCoursesState } from '../interfaces';

export const coursesInitialState: IManagementCoursesState = {
  filter: {
    skip: 0,
    take: AppConst.perPageItemsCounts[1],
    currentPage: 1,
    search: undefined,
    coursesStatus: CoursesStatusEnum.All,
    possibleCoursesStatuses: [
      CoursesStatusEnum.All,
      CoursesStatusEnum.Active,
      CoursesStatusEnum.Draft,
      CoursesStatusEnum.Hidden,
      CoursesStatusEnum.Archived,
    ],
  },
};
