import { PayloadAction } from '@reduxjs/toolkit';

import { PageSliceCaseReducers } from '../../../types';
import { ReviewsDateTypeEnum, ReviewsFilterTypeEnum, ReviewsScoreTypeEnum } from '../enums';

export const reviewsReducers: PageSliceCaseReducers = {
    setReviewsFilterSearch: (state, action: PayloadAction<string | undefined>) => {
        state.reviews.filter.search = action.payload;
    },
    setReviewsFilterType: (state, action: PayloadAction<ReviewsFilterTypeEnum>) => {
        state.reviews.filter.type = action.payload;
    },
    setReviewsFilterPeriod: (state, action: PayloadAction<ReviewsDateTypeEnum>) => {
        state.reviews.filter.period = action.payload;
    },
    setReviewsFilterScore: (state, action: PayloadAction<ReviewsScoreTypeEnum>) => {
        state.reviews.filter.score = action.payload;
    },
};
