import { FC, memo } from 'react';

import { AppConst } from '../../constants/app';
import { withTestId } from '../../helpers';
import { ITestable } from '../../interfaces';

interface IContainerSectionProps extends ITestable {
  classes?: string;
  elementRef?: React.RefObject<HTMLHeadingElement>;
}

export const ContainerSection: FC<IContainerSectionProps> = memo(
  ({ classes, elementRef, ...rest }) => {
    return (
      <section className={`${AppConst.Name}-container ${classes || ''}`} ref={elementRef} {...withTestId(rest.testId)}>
        {rest.children}
      </section>
    );
  },
);
