export const AppConst = {
  Name: 'rc',
  AuthStorageKey: 'auth',
  BaseScreenRootMargin: '150px',
  FavouritesStorageKey: 'favourites',
  SelectedStorageKey: 'selectedCourses',
  UnAuthStorageKey: 'unAuth',
  textReviewCards: 2,
  videoReviewCards: 3,
  perPageItemsCounts: [3, 7, 15],
  groupDefaultShowCount: 5,
  groupLeadRole: 'Куратор',
  mngCountersPollingInterval: 10000,
};
