import { CSSProperties, FC, memo, useMemo } from 'react';
import { IContentBox } from './props';
import { classNames } from '../../../helpers/classnames';
import { withTestId } from '../../../helpers';

import css from './index.module.scss';

export const ContentBox: FC<IContentBox> = memo((props) => {
  const styles: CSSProperties = useMemo(() => ({
    paddingTop: props.paddingY! >= 0 ? `${props.paddingY}px` : undefined,
    paddingBottom: props.paddingY! >= 0 ? `${props.paddingY}px` : undefined,
    paddingLeft: props.paddingX! >= 0 ? `${props.paddingX}px` : undefined,
    paddingRight: props.paddingX! >= 0 ? `${props.paddingX}px` : undefined,
    overflow: props.overflow
  }), [props.paddingX, props.paddingY, props.overflow]);
  const classes = useMemo(() => classNames(
    css.contentBox,
    { [css.contentBox_interactable]: props.isInteractable }
  ), [props.isInteractable]);

  return (
    <div className={classes} style={styles} onClick={() => props.onClick && props.onClick()} {...withTestId(props.testId)}>
      {props.children}
    </div>
  );
});
