export type ClassValue =
  | string
  | ClassDictionary
  | ClassArray
  | undefined
  | null
  | false
  | object
  | boolean;
export interface ClassDictionary {
  [id: string]: boolean | undefined | null;
}
export type ClassArray = Array<ClassValue>;
export interface ClassNamesFn {
  (...classes: ClassValue[]): string;
}
/**
 * Fork classNames library, modified for Typescript
 * @param classes List of classes
 */
export const classNames = (...classes: ClassValue[]): string => {
  const hasOwn = {}.hasOwnProperty;
  const preparedClasses = [];

  for (let i = 0; i < classes.length; i++) {
    const arg = classes[i];
    if (!arg) continue;

    const argType = typeof arg;

    if (argType === 'string') {
      (arg as string).length > 1 && preparedClasses.push(arg);
    } else if (Array.isArray(arg) && arg.length) {
      const inner = classNames.apply(null, arg);
      if (inner) {
        preparedClasses.push(inner);
      }
    } else if (argType === 'object') {
      const arAsObject = arg as ClassDictionary;
      for (const key in arAsObject) {
        if (hasOwn.call(arAsObject, key) && !!arAsObject[key]) {
          preparedClasses.push(key);
        }
      }
    }
  }

  return preparedClasses.join(' ');
};
