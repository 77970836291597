import { FC, useState, useEffect } from 'react';
import { NavLink } from 'react-router-dom';

import FavoriteIcon30 from '../../../../../../../components/ui-kit/icons/components/Favorite30.icon';
import { PagePathsEnum } from '../../../../../../../enums/routes';
import { StudyPathsEnum } from '../../../../../../study/enums';
import { PopupWindow } from '../popup-window';
import { useFavoriteCourses } from '../../../../../../study/hooks/favorites';
import { Course } from '../../../../../../../interfaces/courses/course';
import { useUserProfile } from '../../../../../../../hooks/auth';
import { useSelectedCourses } from '../../../../../../study/hooks/selected';
import { selectFavoriteCourses } from '../../../../../../main/store/selectors';
import { useAppSelector } from '../../../../../../../hooks/hooks';

export const FavoriteItem: FC = () => {
  const [isHover, setIsHover] = useState<boolean>(false);
  const [isFocus, setIsFocus] = useState<boolean>(false);  
  const { noData, favourites, isLoading } = useFavoriteCourses();
  const { user } = useUserProfile();
  const [unAuthFavouritesCourses, setUnAuthFavouritesCourses] = useState<Course[]>([]);
  const { allCourses, allCoursesIsLoading } = useSelectedCourses();
  const favouriteCourses = useAppSelector(selectFavoriteCourses);

  useEffect(() => {
    if (!user?.userId) {
      setUnAuthFavouritesCourses(
        allCourses ? allCourses.filter((course) => favouriteCourses.includes(course.id)) : [],
      );
    } else {
      setUnAuthFavouritesCourses([]);
    }
  }, [user, allCourses, favouriteCourses]);

  const favCourses = user?.userId ? favourites : unAuthFavouritesCourses;

  function onMouseEnter() {
    setIsHover(true);
    setIsFocus(true);
  }

  function onMouseLeave() {
    setIsHover(false);
    setTimeout(() => {
      setIsFocus(false);
    }, 300);
  }

  return (
    <div onMouseEnter={onMouseEnter} onMouseLeave={onMouseLeave}>
      <NavLink to={`${PagePathsEnum.Study}/${StudyPathsEnum.Favourites}`}>
        <div>
          <FavoriteIcon30 type={isHover ? 'primary' : 'secondary'} />        
        </div>
      </NavLink>      
      { favCourses && <PopupWindow isVisible={isHover || isFocus} isLoading={isLoading} courses={favCourses} /> }
    </div>
  );
};
