import { FC, useMemo, SyntheticEvent } from 'react';
import { useNavigate } from 'react-router-dom';
import { PopupItemProps } from './props';
import { handleImageError } from '../../../../../../../helpers/image-error';
import { Rating } from '../../../../../../../components/rating';
import { CardTextWithIcon } from '../../../../../../../components/courses/card-text-with-icon';

import css from './index.module.scss';
import { PagePathsEnum } from '../../../../../../../enums/routes';

export const PopupItem: FC<PopupItemProps> = ({ course }) => {
  const navigate = useNavigate();
  const handleClick = (e: SyntheticEvent) => {
    e.stopPropagation();
    navigate(`/${PagePathsEnum.Courses}/${course.id}`);
  };

  const formattedPrice = useMemo(() => {
    if (course?.price?.value === '0') {
      return course.price.title;
    } else if (course?.price?.value) {
      return new Intl.NumberFormat('ru-RU', {
        style: 'currency',
        currency: 'RUB',
        maximumFractionDigits: 0,
      }).format(+course.price.value);
    }
    return '';
  }, [course.price]);

  
  let rateCount = 0;
  let rate = 0;
  course.textReviews.forEach((review) => {
    if (review.isDeleted) return;

    rateCount += 1;
    rate += review.rate;
  });
  rate = rateCount === 0 ? 0 : rate / rateCount;

  return (
    <div className={css.popupItem} onClick={handleClick}>
    {<>
      <img
        key={course.id}
        src={course.imageSrc}
        alt={course.title}
        onError={handleImageError('/images/course/fallback.jpeg')}
      />
      <div className={css.popupItemContent}>
        <div className={css.popupItemTitleWrapper}>
          <p className={css.popupItemTitle}>{course.title}</p>
        </div>
        <div className={css.popupItemInfoWrapper}>
          <div>
            <Rating rating={rate} ratingCount={rateCount} />
            
          </div>
          <div className={css.popupItemInfoCost}>
            <CardTextWithIcon iconName={'payments'} text={formattedPrice} />
          </div>
        </div>
      </div>
    </>}
    </div>
  );
};
