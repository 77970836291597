import { CSSProperties, FC, useMemo } from 'react';
import { IGridColumnsBox } from './props';
import css from './index.module.scss';
import { classNames } from '../../../helpers/classnames';

export const GridColumnsBox: FC<IGridColumnsBox> = ({
  columns,
  columnGap,
  rowGap,
  stretched,
  className,
  gridTemplateColumns,
  children,
}) => {
  const styles: CSSProperties = useMemo(
    () => ({
      columnGap: `${columnGap ? columnGap : 24}px`,
      rowGap: `${rowGap ? rowGap : 24}px`,
      width: stretched === false ? undefined : '100%',
      gridTemplateColumns: gridTemplateColumns ?? `repeat(${columns ? columns : 2}, 1fr)`,
    }),
    [columnGap, rowGap, stretched, columns],
  );

  return (
    <section className={classNames(css.manyColumnsBox, className)} style={!className ? styles : {}}>
      {children}
    </section>
  );
};
