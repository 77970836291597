import { FC, memo} from 'react';

import { HBox } from '../../layout';
import { Select } from '../../ui-kit/select';
import { IPerPageSelectorProps } from './props';

export const PerPageSelector: FC<IPerPageSelectorProps> = memo((props) => {
  const selectItems = props.counts.map(count => ({ id: `${count}`, title: `${count}` }));

  return (
    <HBox gap={10} testId={'perPageSelector'} stretched={true} align={'center'} justify={'end'}>
      {props.label && <label>{props.label}</label>}
      <Select
          readonly={true}
          maxWidth={100}
          selectedItem={{ id: `${props.selectedCount}`, title: `${props.selectedCount}` }}
          query={async (search, skip, take, callback) => callback(selectItems)}
          onSelect={(selectEvent) => {
            const selectedItem = Number.parseInt(selectEvent?.id || `${props.counts[0]}`, 10);
            props.onChanePerPageItems && props.onChanePerPageItems(selectedItem);
          }}
        />
    </HBox>
  );
});
