import { FC } from 'react';

import { ILabelValuePairProps } from './props';
import { HBox } from '../layout';

import css from './index.module.scss';

export const LabelValuePair: FC<ILabelValuePairProps> = ({ label, value, labelStyles, valueStyles }) => {
  if (!value) { return null; }

  return (
    <HBox gap={8}>
      <span className={css.typo__key} style={labelStyles}>{`${label}:`}</span>
      <span className={css.typo__value} style={valueStyles}>{value}</span>
    </HBox>
  );
};
