import { lazy } from 'react';
import { createBrowserRouter } from 'react-router-dom';

import { PagePathsEnum } from '../enums/routes';
import { NoMatch } from '../components';
import { LayoutPage } from '../pages/layout';

const AuthPage = lazy(() => import(/* webpackChunkName: "auth-page" */ '../pages/auth'));

export const appRouter = createBrowserRouter([
  {
    path: `/${PagePathsEnum.Auth}/*`,
    element: <AuthPage />,
    errorElement: <NoMatch />,
  },
  {
    path: '*',
    element: <LayoutPage />,
    errorElement: <NoMatch />,
  },
]);
