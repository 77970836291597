import { FC, memo } from 'react';
import { Translation } from 'i18nano';

import { Button } from '../ui-kit/button';

export const ErrorInfo: FC = memo(() => (
  <div style={{ margin: '40px' }}>
    <p style={{ fontSize: '16px', color: 'red', marginBottom: '16px' }}>
      <Translation path='notification.error.common' />
    </p>
    <Button
      type='secondary'
      label={<Translation path='reload' />}
      onClick={() => window.location.reload()}
    />
  </div>
));
