import { FC, memo } from 'react';
import { useTranslation } from 'i18nano';

import { socialItems } from '../../../../../constants/social-items';
import { ContainerSection } from '../../../../../components/container-section';

import css from './index.module.scss';

export const MenuSection: FC = memo(() => {
  const translate = useTranslation();

  return (
    <ContainerSection>
      <div className={css.footerMenu}>
        <div className={css.footerMenuPrograms}>
          <h2>{translate('footer.programsHeader')}</h2>
          <p>{translate('footer.programsIt')}</p>
          <p>{translate('footer.programsMarketing')}</p>
          <p>{translate('footer.programsBusiness')}</p>
        </div>
        <div className={css.footerMenuAbout}>
          <h2>{translate('footer.aboutHeader')}</h2>
          <p>{translate('footer.aboutProgramms')}</p>
          <p>{translate('footer.aboutQuestions')}</p>
          <p>{translate('footer.aboutConditions')}</p>
        </div>
        <div className={css.footerMenuContacts}>
          <h2>{translate('footer.contactsHeader')}</h2>
          <div className={css.footerMenuContactsSocial}>
            {socialItems.map((item) => (
              <a key={item.key} href={item.path} target='_blank' rel='noreferrer'>
                <span className={item.key}></span>
                <span>{translate(item.resKey)}</span>
              </a>
            ))}
          </div>
        </div>
      </div>
    </ContainerSection>
  );
});
