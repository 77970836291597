import { StatsPeriodTypeEnum } from '../enums';
import { IManagementStatsState } from '../interfaces';

export const statsInitialState: IManagementStatsState = {
  filter: {
    skip: 0,
    take: 0,

    userPeriod: StatsPeriodTypeEnum.Week,
    possibleUserPeriods: [
      StatsPeriodTypeEnum.Day,
      StatsPeriodTypeEnum.Week,
      StatsPeriodTypeEnum.Month,
      StatsPeriodTypeEnum.Quarter,
      StatsPeriodTypeEnum.Year,
    ],

    coursePeriod: StatsPeriodTypeEnum.Week,
    possibleCoursePeriods: [
      StatsPeriodTypeEnum.Week,
      StatsPeriodTypeEnum.Month,
      StatsPeriodTypeEnum.Year,
    ],

    locationPeriod: StatsPeriodTypeEnum.Month,
    possibleLocationPeriods: [
      StatsPeriodTypeEnum.Month,
      StatsPeriodTypeEnum.Year,
    ],
  },
};
