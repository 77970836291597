import { FC, memo } from 'react';

import { LoaderProps } from './loader-props';
import { classNames } from '../../../helpers/classnames';

import css from './index.module.scss';

export const Loader: FC<LoaderProps> = memo(
  ({
    strokeWidth = '2',
    fill = 'none',
    animationDuration = '2s',
    style,
    className,
  }: LoaderProps) => {
    const classes = classNames(css.loader, className);

    return (
      <div style={style} className={classes}>
        <svg
          className={css.loader__svg}
          viewBox='25 25 50 50'
          style={{ animationDuration: animationDuration }}
        >
          <circle
            className={css.loader__circle}
            cx='50'
            cy='50'
            r='20'
            fill={fill}
            strokeWidth={strokeWidth}
            strokeMiterlimit='10'
          />
        </svg>
      </div>
    );
  },
);
