import { ChangeEvent, FC, memo, useCallback, useEffect, useMemo, useRef, useState } from 'react';

import { IRemoteFilesLite } from './remote-files-lite';
import { IRcFileLoaderProps } from './props';

import { classNames } from '../../../helpers/classnames';
import { MaterialIconText } from '../../material-icon-text';
import { Button } from '../button';

import css from './index.module.scss';

interface IRcFileFieldState {
  files: IRemoteFilesLite[];
  over: boolean;
}

export const RcFileLoader: FC<IRcFileLoaderProps> = memo((props) => {
  const [state, setState] = useState<IRcFileFieldState>({
    files: props.files || [],
    over: false,
  });
  const inputFileLabelRef = useRef<HTMLLabelElement>(null);

  useEffect(() => {
    setState((prevState) => ({ ...prevState, files: props.files || state.files }));
  }, [props.files]);

  const getWrongFileFormats = useCallback((allowedFormatsStr: string, files: IRemoteFilesLite[]) => {
    const allowedFormats = allowedFormatsStr.replace(/\s/g,'').split(',');
    const wrongFormats: string[] = [];

    files.forEach((file: IRemoteFilesLite) => {
      let fileFormat = file.name?.split('.')?.pop()?.toLowerCase();
      fileFormat = `.${fileFormat}`;

      if (!allowedFormats.some((format: string) => format.toLowerCase() === fileFormat)) {
        wrongFormats.push(fileFormat);
      }
    });

    return wrongFormats;
  }, []);

  const updateFiles = (files: IRemoteFilesLite[]) => {
    const newFiles = state.files.concat(files);
    setState((prevState) => ({ ...prevState, files: newFiles }));

    props.onChange && props.onChange(newFiles);
    props.onAddFileToList && props.onAddFileToList(newFiles);
  };

  const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
    const { limit, accept, onSizeLimitExceeded, onAddForbiddenFormat } = props;

    const eventFiles = Array.from(event.target.files || []);
    const files = [...eventFiles];

    if (limit) {
      const totalFiles = state.files.concat(files);
      const totalSize = totalFiles.reduce((acc, file) => acc + file.size, 0);
      const isFilesSizeExceeded = totalSize > convertMbytesToBytes(limit);

      if (isFilesSizeExceeded) {
        onSizeLimitExceeded && onSizeLimitExceeded();
        return;
      }
    }

    if (accept) {
      const wrongFormats: string[] = getWrongFileFormats(accept, files);
      
      if (wrongFormats.length) {
        onAddForbiddenFormat && onAddForbiddenFormat(wrongFormats.join(', '));
        return;
      }
    }
    
    updateFiles(files);
  };

  const removeHandler = (file: IRemoteFilesLite) => {
    console.log(file);
    props.onDeleteFileFromList && props.onDeleteFileFromList(file);
    props.onChange && props.onChange(state.files.filter(file => file.name !== file.name));
  };

  const downloadHandler = (file: IRemoteFilesLite) => {
    props.onDownloadFileFromList && props.onDownloadFileFromList(file);
  };

  const dragOverHandler = (ev: React.DragEvent<HTMLDivElement>) => {
    ev.preventDefault();
    ev.stopPropagation();

    setState((prevState) => ({ ...prevState, over: true }));
  };

  const dragLeaveHandler = (ev: React.DragEvent<HTMLDivElement>) => {
    ev.preventDefault();
    ev.stopPropagation();

    setState((prevState) => ({ ...prevState, over: false }));
  };

  const dropHandler = (ev: React.DragEvent<HTMLDivElement>) => {
    ev.preventDefault();
    ev.stopPropagation();

    const files: IRemoteFilesLite[] = [];

    if (ev.dataTransfer.items) {
      for (let i = 0; i < ev.dataTransfer.items.length; i++) {
        if (ev.dataTransfer.items[i].kind === 'file') {
          files.push(ev.dataTransfer.items[i].getAsFile() as IRemoteFilesLite);
        }
      }
    } else {
      for (let i = 0; i < ev.dataTransfer.files.length; i++) {
        files.push(ev.dataTransfer.files[i]);
      }
    }

    if (props.accept && props.onAddForbiddenFormat) {
      const wrongFormats = getWrongFileFormats(props.accept, files);

      if (ev.dataTransfer.items && ev.dataTransfer.items.length > 1 && !props.multiple) {
        return;
      }

      if (!wrongFormats.length) {
        updateFiles(files);
      } else {
        props.onAddForbiddenFormat(wrongFormats.join(', '));
      }
    } else {
      updateFiles(files);
    }

    setState((prevState) => ({ ...prevState, over: false }));
  };

  const convertBytesToMbytes = (size: number): number => Number((size / (1024 ** 2)).toFixed(2));
  const convertMbytesToBytes = (size: number): number => Number((size * (1024 ** 2)).toFixed(2));

  const wrapperStyleClasses = useMemo(() => classNames(
    css.fileLoader,
    { [css.fileLoader_disabled]: props.isDisabled },
    { [css.fileLoader_not_valid]: typeof (props.isValid) === 'boolean' && !props.isValid },
    { [css.fileLoader_with_files]: state.files && state.files.length },
  ), [props.isDisabled, props.isValid, state.files.length]);

  const { limit, accept, multiple, maxWidth, errorMessage, isValid, isDisabled } = props;
  const fieldWidth = maxWidth ? `${maxWidth}px` : '100%';
  const files = state.files || [];

  return (
    <div className={css.fileLoaderWrapper}>
      <div className={classNames(css.fileLoaderContainer)}
        onDragOver={dragOverHandler}
        onDragLeave={dragLeaveHandler}
        onDrop={dropHandler}
      >
        <input
          id={'nativeFileInput'}
          name={props.inputName || 'fileInput'}
          type={'file'}
          onChange={handleChange}
          accept={accept || '*'}
          disabled={isDisabled}
          multiple={multiple || false}
          style={{ display: 'none' }}
        />
        <label htmlFor='nativeFileInput' className={css.fileLoaderButton} ref={inputFileLabelRef}>
          <div className={css.fileLoaderButtonMessage}>{props.subtitle}</div>
          <Button size={'small'} style={{ width: '140px' }} type={state.over ? 'primary' : 'secondary'} label={props.buttonLabel} onClick={() => inputFileLabelRef.current?.click()} />
        </label>
        {
          (limit || accept) &&
          <div className={css.fileLoaderValidationInfo}>
            {limit && <p className={css.fileLoaderValidationInfoLimit}>{props.limitWarning}</p>}
            {accept && <p className={css.fileLoaderValidationInfoAccept}>{accept}</p>}
          </div>
        }
      </div>
      {!isValid && errorMessage && <div className={css.fileLoaderErrorMessage}>{errorMessage}</div>}

      {
        files.length > 0 &&
        <div className={wrapperStyleClasses} style={{ width: fieldWidth }}>
          <div className={css.fileLoaderFiles}>
            {
              files.map((file: IRemoteFilesLite, index: number) => (
                <div key={index} className={classNames(css.fileLoaderFile, { [css.fileLoaderFile_downloadable]: file.url })}>
                  <MaterialIconText iconName={'insert_drive_file'} size={'36'} onClick={() => downloadHandler(file)} />
                  <div className={css.fileLoaderFile__body}>
                    <div className={css.fileLoaderFile__name}>{file.name}</div>
                    {file.addedOn && file.size && (
                      <div className={css.fileLoaderFile__info}>
                        {`${file.addedOn} • ${convertBytesToMbytes(file.size)} MB`}
                      </div>
                    )}
                  </div>
                  <MaterialIconText iconName={'delete'} size={'18'} onClick={() => removeHandler(file)} />
                </div>
              ))
            }
          </div>
        </div>
      }
    </div>
  );
});
