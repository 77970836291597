import { TextReviewMapper } from '../../types/courses/text-review-mapper';
import { mapToProfileUser } from '../../pages/main/services/profile-mapper';

export const mapToTextReview: TextReviewMapper = (fromDto) => ({
  id: fromDto.id!.toString(),
  title: fromDto.title,
  description: fromDto?.description,
  imageUrl: fromDto?.image_url || fromDto?.cropped_image || '',
  userName: fromDto?.user_name,
  courseId: fromDto?.course,
  user: mapToProfileUser(fromDto.user_profile!),  
  rate: fromDto.rate ?? 0,
  isAvailable: fromDto.is_available ?? false,
  isAccepted: fromDto.is_accepted ?? false,
  isDeleted: fromDto.is_deleted ?? false,
});
