import { createSlice } from '@reduxjs/toolkit';

import { initialState } from './intitial-state';
import { ERootState } from '../../../interfaces/root-state';
import { professorsReducers } from '../containers/professors/store/reducers';
import { documentsReducers } from '../containers/documents/store/reducers';
import { reviewsReducers } from '../containers/reviews/store/reducers';
import { requestsReducers } from '../containers/requests/store/reducers';
import { groupsReducers } from '../containers/groups/store/reducers';
import { statsReducers } from '../containers/stats/store/reducers';
import { coursesReducers } from '../containers/courses/store/reducers';

export const slice = createSlice({
  name: ERootState.management,
  initialState,
  reducers: {
    ...professorsReducers,
    ...documentsReducers,
    ...reviewsReducers,
    ...requestsReducers,
    ...groupsReducers,
    ...statsReducers,
    ...coursesReducers,
  },
});
