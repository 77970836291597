import { FC } from 'react';
import { Loader } from '../ui-kit';

import css from './index.module.scss';

export const DataLoaderWrapper: FC<{ isLoading: boolean }> = ({ isLoading, ...rest }) => {
  if (isLoading) {
    return (
      <div className={css.loaderWrapper}>
        <Loader />
      </div>
    );
  }

  return <>{rest.children}</>;
};
