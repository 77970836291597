import { FC, memo } from 'react';
import * as ReactDOM from 'react-dom';

import css from './index.module.scss';

export const MobileSideMenu: FC = memo((props) => {
  return ReactDOM.createPortal(
    <aside className={css.sideMenuWrapper}>
      <div className={css.sideMenu}>
        {props.children}
      </div>
    </aside>,
    document.getElementById('root')!,
  );
});
