import { FC } from 'react';

import { toDateShort } from '../../helpers/date';

import css from './index.module.scss';

type VersionData = {
  version: string;
  completion: string;
};

type Versions = {
  [date: string]: VersionData;
};

export const VersionApp: FC = () => {
  const currentDate = toDateShort(new Date());

  const versions: Versions = {
    [toDateShort(new Date(2023, 3, 15))]: { version: '1.2.8.14042024', completion: '84' },
    [toDateShort(new Date(2023, 3, 16))]: { version: '1.2.8.14042025', completion: '85' },
    [toDateShort(new Date(2023, 3, 17))]: { version: '1.2.9.17042023', completion: '86' },
    [toDateShort(new Date(2023, 3, 18))]: { version: '1.3.2.18042023', completion: '90' },
    [toDateShort(new Date(2023, 3, 19))]: { version: '1.3.4.19042023', completion: '94' },
    [toDateShort(new Date(2023, 3, 20))]: { version: '1.4.6.20042023', completion: '98' },
    [toDateShort(new Date(2023, 3, 21))]: { version: '2.0.1.21042023', completion: '✅ 100' },
  };
  const versionData: VersionData = versions[currentDate] || {
    version: '2.0.1.21042023',
    completion: '✅ 100',
  };
  const versionText = `Версия Единой площадки контента курсов ${versionData.version}, общая готовность - ${versionData.completion}%\u00A0\u00A0🚀🚀🚀`;

  return (
    <div className={css.versionContainer}>
      <div className={css.versionText}>{versionText}</div>
    </div>
  );
};
