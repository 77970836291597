import { FC, memo } from 'react';

import { MaterialIconText } from '../material-icon-text';

import css from './index.module.scss';

export const Rating: FC<{ rating: number; ratingCount?: number }> = memo(
  ({ rating, ratingCount }) => {
    return (
      <div className={css.wrapper}>
        {[...Array(5)].map((_, index) => {
          const currentClasses = index + 1 <= rating ? 'md-yellow' : 'md-gray';

          return (
            <MaterialIconText
              key={index}
              iconName='star'
              classes={currentClasses}
              size='14'
              isDisabled
            />
          );
        })}
        {(ratingCount || ratingCount === 0) && <span className={css.wrapperText}>{`${rating} (${ratingCount ?? '?'})`}</span>}
      </div>
    );
  },
);
