import * as React from 'react';
import { IconProps } from '../types';

export default function FavoriteIcon30(props: IconProps): React.ReactElement {
  const { type, width = 30, heigth = 30 } = props;
  const mainColor = type === 'primary' ? '#EDB1C4' : '#D7D7D7';
  const secondaryColor = type === 'primary' ? '#DA6289' : '#AEAEAE';
  return (
    <svg
      width={width}
      height={heigth}
    >
      <path
        d="M16.8199 24.4037C15.8261 25.4124 14.1937 25.3952 13.2214 24.3657L4.37598 15L16.6524 2.29026C19.7061 -0.763419 24.657 -0.763419 27.7107 2.29026C30.7644 5.34393 30.7644 10.2949 27.7107 13.3486L16.8199 24.4037Z"
        fill={secondaryColor}
      />
      <path
        d="M2.29026 13.3486C-0.763419 10.2949 -0.763419 5.34393 2.29026 2.29026C5.34393 -0.763419 10.2949 -0.763419 13.3486 2.29026L26.0875 15L16.781 24.4429C15.8016 25.4366 14.1985 25.4364 13.2194 24.4426L2.29026 13.3486Z"
        fill={mainColor}
      />
    </svg>
  );
}