import { CSSProperties, FC, memo, useMemo } from 'react';
import { IHBox } from './props';
import { classNames } from '../../../helpers/classnames';
import { withTestId } from '../../../helpers';
import { useResponsive } from '../../../hooks/responsive';

import css from './index.module.scss';

export const HBox: FC<IHBox> = memo((props) => {
  const { isMobile } = useResponsive();
  
  const gap = useMemo(
    () => props.gap ? `${isMobile ? props.gap / 2 : props.gap}px` : undefined,
    [props.gap, isMobile],
  );

  const classes = useMemo(
    () => classNames(css.hbox, { [css.hbox_wrap]: props.wrap || isMobile }),
    [props.wrap, isMobile],
  );
  const styles: CSSProperties = useMemo(
    () => ({
      gap,
      alignItems: props.align,
      justifyContent: props.justify,
      width: props.stretched === false ? undefined : '100%',
    }),
    [gap, props.align, props.stretched, props.justify],
  );

  return (
    <div className={props.className || classes} style={styles} {...withTestId(props.testId)}>
      {props.children}
    </div>
  );
});
