import { baseApi as api } from './fixture';
const injectedRtkApi = api.injectEndpoints({
  endpoints: (build) => ({
    apiAnalyticsUserEmailRead: build.query<
      ApiAnalyticsUserEmailReadApiResponse,
      ApiAnalyticsUserEmailReadApiArg
    >({
      query: (queryArg) => ({ url: `/api/analytics/user/email/${queryArg.userEmail}/` }),
    }),
    apiAnalyticsUserRead: build.query<ApiAnalyticsUserReadApiResponse, ApiAnalyticsUserReadApiArg>({
      query: (queryArg) => ({ url: `/api/analytics/user/${queryArg.userId}/` }),
    }),
    apiCartCreate: build.mutation<ApiCartCreateApiResponse, ApiCartCreateApiArg>({
      query: (queryArg) => ({
        url: `/api/cart/${queryArg.courseId}/`,
        method: 'POST',
        body: queryArg.body,
      }),
    }),
    apiCartDelete: build.mutation<ApiCartDeleteApiResponse, ApiCartDeleteApiArg>({
      query: (queryArg) => ({ url: `/api/cart/${queryArg.courseId}/`, method: 'DELETE' }),
    }),
    apiCertificatesList: build.query<ApiCertificatesListApiResponse, ApiCertificatesListApiArg>({
      query: () => ({ url: `/api/certificates/` }),
    }),
    apiCompaniesList: build.query<ApiCompaniesListApiResponse, ApiCompaniesListApiArg>({
      query: () => ({ url: `/api/companies/` }),
    }),
    apiCompaniesCreate: build.mutation<ApiCompaniesCreateApiResponse, ApiCompaniesCreateApiArg>({
      query: (queryArg) => ({ url: `/api/companies/`, method: 'POST', body: queryArg.postCompany }),
    }),
    apiCompaniesResponsibleList: build.query<
      ApiCompaniesResponsibleListApiResponse,
      ApiCompaniesResponsibleListApiArg
    >({
      query: () => ({ url: `/api/companies/responsible/` }),
    }),
    apiCompaniesResponsibleCreate: build.mutation<
      ApiCompaniesResponsibleCreateApiResponse,
      ApiCompaniesResponsibleCreateApiArg
    >({
      query: (queryArg) => ({
        url: `/api/companies/responsible/`,
        method: 'POST',
        body: queryArg.postResponsible,
      }),
    }),
    apiCompaniesResponsibleRead: build.query<
      ApiCompaniesResponsibleReadApiResponse,
      ApiCompaniesResponsibleReadApiArg
    >({
      query: (queryArg) => ({ url: `/api/companies/responsible/${queryArg.id}` }),
    }),
    apiCompaniesResponsibleUpdate: build.mutation<
      ApiCompaniesResponsibleUpdateApiResponse,
      ApiCompaniesResponsibleUpdateApiArg
    >({
      query: (queryArg) => ({
        url: `/api/companies/responsible/${queryArg.id}`,
        method: 'PUT',
        body: queryArg.postResponsible,
      }),
    }),
    apiCompaniesResponsibleDelete: build.mutation<
      ApiCompaniesResponsibleDeleteApiResponse,
      ApiCompaniesResponsibleDeleteApiArg
    >({
      query: (queryArg) => ({ url: `/api/companies/responsible/${queryArg.id}`, method: 'DELETE' }),
    }),
    apiCompaniesAvatarUpdate: build.mutation<
      ApiCompaniesAvatarUpdateApiResponse,
      ApiCompaniesAvatarUpdateApiArg
    >({
      query: (queryArg) => ({
        url: `/api/companies/${queryArg.companyId}/avatar/`,
        method: 'PUT',
        body: queryArg.body,
      }),
    }),
    apiCompaniesRead: build.query<ApiCompaniesReadApiResponse, ApiCompaniesReadApiArg>({
      query: (queryArg) => ({ url: `/api/companies/${queryArg.id}` }),
    }),
    apiCompaniesUpdate: build.mutation<ApiCompaniesUpdateApiResponse, ApiCompaniesUpdateApiArg>({
      query: (queryArg) => ({
        url: `/api/companies/${queryArg.id}`,
        method: 'PUT',
        body: queryArg.postCompany,
      }),
    }),
    apiCompaniesDelete: build.mutation<ApiCompaniesDeleteApiResponse, ApiCompaniesDeleteApiArg>({
      query: (queryArg) => ({ url: `/api/companies/${queryArg.id}`, method: 'DELETE' }),
    }),
    apiConformityUserCoursesCreate: build.mutation<
      ApiConformityUserCoursesCreateApiResponse,
      ApiConformityUserCoursesCreateApiArg
    >({
      query: (queryArg) => ({
        url: `/api/conformity/user/courses/`,
        method: 'POST',
        body: queryArg.conformityUserCoursesRequestBody,
      }),
    }),
    apiConformityUserCoursesEmailCreate: build.mutation<
      ApiConformityUserCoursesEmailCreateApiResponse,
      ApiConformityUserCoursesEmailCreateApiArg
    >({
      query: (queryArg) => ({
        url: `/api/conformity/user/courses/email/`,
        method: 'POST',
        body: queryArg.conformityUserCoursesByEmailRequestBody,
      }),
    }),
    getApiCourseRequestByRequestId: build.query<
      GetApiCourseRequestByRequestIdApiResponse,
      GetApiCourseRequestByRequestIdApiArg
    >({
      query: (queryArg) => ({ url: `/api/course-request/${queryArg.requestId}` }),
    }),
    putApiCourseRequestByRequestId: build.mutation<
      PutApiCourseRequestByRequestIdApiResponse,
      PutApiCourseRequestByRequestIdApiArg
    >({
      query: (queryArg) => ({
        url: `/api/course-request/${queryArg.requestId}`,
        method: 'PUT',
        body: queryArg.courseRequestsUpdateBody,
      }),
    }),
    postApiCourseRequests: build.mutation<
      PostApiCourseRequestsApiResponse,
      PostApiCourseRequestsApiArg
    >({
      query: (queryArg) => ({
        url: `/api/course-requests/`,
        method: 'POST',
        body: queryArg.courseRequestsPostBody,
      }),
    }),
    getApiCourseRequestsCsvByRequestId: build.query<
      GetApiCourseRequestsCsvByRequestIdApiResponse,
      GetApiCourseRequestsCsvByRequestIdApiArg
    >({
      query: (queryArg) => ({ url: `/api/course-requests/csv/${queryArg.requestId}` }),
    }),
    getApiCourseRequestsDocxByRequestId: build.query<
      GetApiCourseRequestsDocxByRequestIdApiResponse,
      GetApiCourseRequestsDocxByRequestIdApiArg
    >({
      query: (queryArg) => ({ url: `/api/course-requests/docx/${queryArg.requestId}` }),
    }),
    getApiCourseRequestsEmailAuth: build.query<
      GetApiCourseRequestsEmailAuthApiResponse,
      GetApiCourseRequestsEmailAuthApiArg
    >({
      query: () => ({ url: `/api/course-requests/email/auth/` }),
    }),
    getApiCourseRequestsMngmReview: build.query<
      GetApiCourseRequestsMngmReviewApiResponse,
      GetApiCourseRequestsMngmReviewApiArg
    >({
      query: (queryArg) => ({
        url: `/api/course-requests/mngm/review/`,
        params: {
          ordering: queryArg.ordering,
          search: queryArg.search,
          status: queryArg.status,
          course: queryArg.course,
          created_at__gte: queryArg.createdAtGte,
          created_at__lte: queryArg.createdAtLte,
          limit: queryArg.limit,
          offset: queryArg.offset,
        },
      }),
    }),
    putApiCourseRequestsMngmReviewByRequestId: build.mutation<
      PutApiCourseRequestsMngmReviewByRequestIdApiResponse,
      PutApiCourseRequestsMngmReviewByRequestIdApiArg
    >({
      query: (queryArg) => ({
        url: `/api/course-requests/mngm/review/${queryArg.requestId}`,
        method: 'PUT',
        body: queryArg.reviewCourseRequest,
      }),
    }),
    postApiCourseRequestsPassportScan: build.mutation<
      PostApiCourseRequestsPassportScanApiResponse,
      PostApiCourseRequestsPassportScanApiArg
    >({
      query: (queryArg) => ({
        url: `/api/course-requests/passport_scan/`,
        method: 'POST',
        body: queryArg.body,
      }),
    }),
    postApiCourseRequestsWorkBookScan: build.mutation<
      PostApiCourseRequestsWorkBookScanApiResponse,
      PostApiCourseRequestsWorkBookScanApiArg
    >({
      query: (queryArg) => ({
        url: `/api/course-requests/work_book_scan/`,
        method: 'POST',
        body: queryArg.body,
      }),
    }),
    getApiCourseRequestsByCourseRequestIdComments: build.query<
      GetApiCourseRequestsByCourseRequestIdCommentsApiResponse,
      GetApiCourseRequestsByCourseRequestIdCommentsApiArg
    >({
      query: (queryArg) => ({ url: `/api/course-requests/${queryArg.courseRequestId}/comments` }),
    }),
    postApiCourseRequestsByCourseRequestIdComments: build.mutation<
      PostApiCourseRequestsByCourseRequestIdCommentsApiResponse,
      PostApiCourseRequestsByCourseRequestIdCommentsApiArg
    >({
      query: (queryArg) => ({
        url: `/api/course-requests/${queryArg.courseRequestId}/comments`,
        method: 'POST',
        body: queryArg.courseRequestComment,
      }),
    }),
    getApiCourseRequestsByCourseRequestIdServiceComments: build.query<
      GetApiCourseRequestsByCourseRequestIdServiceCommentsApiResponse,
      GetApiCourseRequestsByCourseRequestIdServiceCommentsApiArg
    >({
      query: (queryArg) => ({
        url: `/api/course-requests/${queryArg.courseRequestId}/service_comments`,
      }),
    }),
    postApiCourseRequestsByCourseRequestIdServiceComments: build.mutation<
      PostApiCourseRequestsByCourseRequestIdServiceCommentsApiResponse,
      PostApiCourseRequestsByCourseRequestIdServiceCommentsApiArg
    >({
      query: (queryArg) => ({
        url: `/api/course-requests/${queryArg.courseRequestId}/service_comments`,
        method: 'POST',
        body: queryArg.courseRequestServiceComment,
      }),
    }),
    getApiCourseRequestsByEmail: build.query<
      GetApiCourseRequestsByEmailApiResponse,
      GetApiCourseRequestsByEmailApiArg
    >({
      query: (queryArg) => ({ url: `/api/course-requests/${queryArg.email}/` }),
    }),
    apiCoursesList: build.query<ApiCoursesListApiResponse, ApiCoursesListApiArg>({
      query: (queryArg) => ({
        url: `/api/courses/`,
        params: {
          ordering: queryArg.ordering,
          search: queryArg.search,
          limit: queryArg.limit,
          offset: queryArg.offset,
        },
      }),
    }),
    apiCoursesCategoryFiltersList: build.query<
      ApiCoursesCategoryFiltersListApiResponse,
      ApiCoursesCategoryFiltersListApiArg
    >({
      query: () => ({ url: `/api/courses/category_filters` }),
    }),
    apiCoursesMngmList: build.query<ApiCoursesMngmListApiResponse, ApiCoursesMngmListApiArg>({
      query: (queryArg) => ({
        url: `/api/courses/mngm`,
        params: {
          ordering: queryArg.ordering,
          search: queryArg.search,
          is_available: queryArg.isAvailable,
          limit: queryArg.limit,
          offset: queryArg.offset,
        },
      }),
    }),
    apiCoursesRecommendationList: build.query<
      ApiCoursesRecommendationListApiResponse,
      ApiCoursesRecommendationListApiArg
    >({
      query: () => ({ url: `/api/courses/recommendation` }),
    }),
    apiCoursesRecommendationCreate: build.mutation<
      ApiCoursesRecommendationCreateApiResponse,
      ApiCoursesRecommendationCreateApiArg
    >({
      query: (queryArg) => ({
        url: `/api/courses/recommendation`,
        method: 'POST',
        body: queryArg.course,
      }),
    }),
    postApiCoursesSendToMail: build.mutation<
      PostApiCoursesSendToMailApiResponse,
      PostApiCoursesSendToMailApiArg
    >({
      query: (queryArg) => ({
        url: `/api/courses/send-to-mail`,
        method: 'POST',
        body: queryArg.courseSendToMail,
      }),
    }),
    apiCoursesUploadFileCreate: build.mutation<
      ApiCoursesUploadFileCreateApiResponse,
      ApiCoursesUploadFileCreateApiArg
    >({
      query: (queryArg) => ({
        url: `/api/courses/upload/file`,
        method: 'POST',
        body: queryArg.body,
      }),
    }),
    apiCoursesRead: build.query<ApiCoursesReadApiResponse, ApiCoursesReadApiArg>({
      query: (queryArg) => ({ url: `/api/courses/${queryArg.courseId}/` }),
    }),
    apiCoursesScheduleList: build.query<
      ApiCoursesScheduleListApiResponse,
      ApiCoursesScheduleListApiArg
    >({
      query: (queryArg) => ({ url: `/api/courses/${queryArg.courseId}/schedule` }),
    }),
    apiDictionariesCitiesList: build.query<
      ApiDictionariesCitiesListApiResponse,
      ApiDictionariesCitiesListApiArg
    >({
      query: () => ({ url: `/api/dictionaries/cities/` }),
    }),
    apiDictionariesCitiesRead: build.query<
      ApiDictionariesCitiesReadApiResponse,
      ApiDictionariesCitiesReadApiArg
    >({
      query: (queryArg) => ({ url: `/api/dictionaries/cities/${queryArg.regionId}/` }),
    }),
    apiDictionariesCityRead: build.query<
      ApiDictionariesCityReadApiResponse,
      ApiDictionariesCityReadApiArg
    >({
      query: (queryArg) => ({ url: `/api/dictionaries/city/${queryArg.cityId}/` }),
    }),
    apiDictionariesCountriesList: build.query<
      ApiDictionariesCountriesListApiResponse,
      ApiDictionariesCountriesListApiArg
    >({
      query: () => ({ url: `/api/dictionaries/countries/` }),
    }),
    apiDictionariesCountryRead: build.query<
      ApiDictionariesCountryReadApiResponse,
      ApiDictionariesCountryReadApiArg
    >({
      query: (queryArg) => ({ url: `/api/dictionaries/country/${queryArg.countryId}/` }),
    }),
    apiDictionariesRegionRead: build.query<
      ApiDictionariesRegionReadApiResponse,
      ApiDictionariesRegionReadApiArg
    >({
      query: (queryArg) => ({ url: `/api/dictionaries/region/${queryArg.regionId}/` }),
    }),
    apiDictionariesRegionsList: build.query<
      ApiDictionariesRegionsListApiResponse,
      ApiDictionariesRegionsListApiArg
    >({
      query: () => ({ url: `/api/dictionaries/regions/` }),
    }),
    apiDictionariesRegionsRead: build.query<
      ApiDictionariesRegionsReadApiResponse,
      ApiDictionariesRegionsReadApiArg
    >({
      query: (queryArg) => ({ url: `/api/dictionaries/regions/${queryArg.countryId}/` }),
    }),
    apiDictionaryCoursesAreasList: build.query<
      ApiDictionaryCoursesAreasListApiResponse,
      ApiDictionaryCoursesAreasListApiArg
    >({
      query: () => ({ url: `/api/dictionary/courses/areas` }),
    }),
    apiDictionaryCoursesCategoriesList: build.query<
      ApiDictionaryCoursesCategoriesListApiResponse,
      ApiDictionaryCoursesCategoriesListApiArg
    >({
      query: () => ({ url: `/api/dictionary/courses/categories` }),
    }),
    apiDictionaryCoursesCertsList: build.query<
      ApiDictionaryCoursesCertsListApiResponse,
      ApiDictionaryCoursesCertsListApiArg
    >({
      query: () => ({ url: `/api/dictionary/courses/certs` }),
    }),
    getApiDictionaryCoursesEduRequirements: build.query<
      GetApiDictionaryCoursesEduRequirementsApiResponse,
      GetApiDictionaryCoursesEduRequirementsApiArg
    >({
      query: () => ({ url: `/api/dictionary/courses/edu-requirements` }),
    }),
    apiDictionaryCoursesFormatsList: build.query<
      ApiDictionaryCoursesFormatsListApiResponse,
      ApiDictionaryCoursesFormatsListApiArg
    >({
      query: () => ({ url: `/api/dictionary/courses/formats` }),
    }),
    apiDictionaryCoursesGroupsList: build.query<
      ApiDictionaryCoursesGroupsListApiResponse,
      ApiDictionaryCoursesGroupsListApiArg
    >({
      query: () => ({ url: `/api/dictionary/courses/groups` }),
    }),
    apiDictionaryCoursesPeriodsList: build.query<
      ApiDictionaryCoursesPeriodsListApiResponse,
      ApiDictionaryCoursesPeriodsListApiArg
    >({
      query: () => ({ url: `/api/dictionary/courses/periods` }),
    }),
    apiDictionaryCoursesPlatformsList: build.query<
      ApiDictionaryCoursesPlatformsListApiResponse,
      ApiDictionaryCoursesPlatformsListApiArg
    >({
      query: () => ({ url: `/api/dictionary/courses/platforms` }),
    }),
    apiDictionaryCoursesProfilesList: build.query<
      ApiDictionaryCoursesProfilesListApiResponse,
      ApiDictionaryCoursesProfilesListApiArg
    >({
      query: () => ({ url: `/api/dictionary/courses/profiles` }),
    }),
    apiDictionaryCoursesRegionsList: build.query<
      ApiDictionaryCoursesRegionsListApiResponse,
      ApiDictionaryCoursesRegionsListApiArg
    >({
      query: () => ({ url: `/api/dictionary/courses/regions` }),
    }),
    apiDictionaryCoursesSubjectsList: build.query<
      ApiDictionaryCoursesSubjectsListApiResponse,
      ApiDictionaryCoursesSubjectsListApiArg
    >({
      query: () => ({ url: `/api/dictionary/courses/subjects` }),
    }),
    apiDictionaryCoursesUniversitiesList: build.query<
      ApiDictionaryCoursesUniversitiesListApiResponse,
      ApiDictionaryCoursesUniversitiesListApiArg
    >({
      query: () => ({ url: `/api/dictionary/courses/universities` }),
    }),
    getApiEmploymentCenterDocTypes: build.query<
      GetApiEmploymentCenterDocTypesApiResponse,
      GetApiEmploymentCenterDocTypesApiArg
    >({
      query: () => ({ url: `/api/employment_center/doc-types` }),
    }),
    apiEmploymentCenterCreate: build.mutation<
      ApiEmploymentCenterCreateApiResponse,
      ApiEmploymentCenterCreateApiArg
    >({
      query: (queryArg) => ({
        url: `/api/employment_center/${queryArg.docType}`,
        method: 'POST',
        body: queryArg.body,
      }),
    }),
    apiFaqCategoriesList: build.query<ApiFaqCategoriesListApiResponse, ApiFaqCategoriesListApiArg>({
      query: () => ({ url: `/api/faq/categories/` }),
    }),
    apiFaqItemRead: build.query<ApiFaqItemReadApiResponse, ApiFaqItemReadApiArg>({
      query: (queryArg) => ({ url: `/api/faq/item/${queryArg.faqId}/` }),
    }),
    apiFaqListCategoryItemsList: build.query<
      ApiFaqListCategoryItemsListApiResponse,
      ApiFaqListCategoryItemsListApiArg
    >({
      query: (queryArg) => ({
        url: `/api/faq/list_category_items/`,
        params: {
          ordering: queryArg.ordering,
          search: queryArg.search,
          category: queryArg.category,
          course: queryArg.course,
          limit: queryArg.limit,
          offset: queryArg.offset,
        },
      }),
    }),
    apiFaqListItemsList: build.query<ApiFaqListItemsListApiResponse, ApiFaqListItemsListApiArg>({
      query: (queryArg) => ({
        url: `/api/faq/list_items/`,
        params: {
          ordering: queryArg.ordering,
          search: queryArg.search,
          category: queryArg.category,
          course: queryArg.course,
          limit: queryArg.limit,
          offset: queryArg.offset,
        },
      }),
    }),
    apiFaqMainPageList: build.query<ApiFaqMainPageListApiResponse, ApiFaqMainPageListApiArg>({
      query: (queryArg) => ({
        url: `/api/faq/main_page/`,
        params: {
          ordering: queryArg.ordering,
          search: queryArg.search,
          category: queryArg.category,
          course: queryArg.course,
          limit: queryArg.limit,
          offset: queryArg.offset,
        },
      }),
    }),
    apiFavoritesCreate: build.mutation<ApiFavoritesCreateApiResponse, ApiFavoritesCreateApiArg>({
      query: (queryArg) => ({
        url: `/api/favorites/${queryArg.courseId}/`,
        method: 'POST',
        body: queryArg.body,
      }),
    }),
    apiFavoritesDelete: build.mutation<ApiFavoritesDeleteApiResponse, ApiFavoritesDeleteApiArg>({
      query: (queryArg) => ({ url: `/api/favorites/${queryArg.courseId}/`, method: 'DELETE' }),
    }),
    apiFilesCreate: build.mutation<ApiFilesCreateApiResponse, ApiFilesCreateApiArg>({
      query: (queryArg) => ({ url: `/api/files/`, method: 'POST', body: queryArg.body }),
    }),
    apiGeneralMainBannerList: build.query<
      ApiGeneralMainBannerListApiResponse,
      ApiGeneralMainBannerListApiArg
    >({
      query: () => ({ url: `/api/general/main_banner/` }),
    }),
    apiGeneralProfessionsList: build.query<
      ApiGeneralProfessionsListApiResponse,
      ApiGeneralProfessionsListApiArg
    >({
      query: () => ({ url: `/api/general/professions/` }),
    }),
    apiGeneralSkillsList: build.query<ApiGeneralSkillsListApiResponse, ApiGeneralSkillsListApiArg>({
      query: () => ({ url: `/api/general/skills/` }),
    }),
    apiGeneralTopMenusList: build.query<
      ApiGeneralTopMenusListApiResponse,
      ApiGeneralTopMenusListApiArg
    >({
      query: () => ({ url: `/api/general/top_menus/` }),
    }),
    apiGroupsList: build.query<ApiGroupsListApiResponse, ApiGroupsListApiArg>({
      query: () => ({ url: `/api/groups/` }),
    }),
    apiGroupsCreate: build.mutation<ApiGroupsCreateApiResponse, ApiGroupsCreateApiArg>({
      query: (queryArg) => ({ url: `/api/groups/`, method: 'POST', body: queryArg.groupListItems }),
    }),
    apiGroupsUserAllList: build.query<ApiGroupsUserAllListApiResponse, ApiGroupsUserAllListApiArg>({
      query: () => ({ url: `/api/groups/user/all` }),
    }),
    apiGroupsUserList: build.query<ApiGroupsUserListApiResponse, ApiGroupsUserListApiArg>({
      query: (queryArg) => ({ url: `/api/groups/${queryArg.groupId}/user/` }),
    }),
    apiGroupsUserCreate: build.mutation<ApiGroupsUserCreateApiResponse, ApiGroupsUserCreateApiArg>({
      query: (queryArg) => ({
        url: `/api/groups/${queryArg.groupId}/user/`,
        method: 'POST',
        body: queryArg.postGroupUser,
      }),
    }),
    apiGroupsUserRead: build.query<ApiGroupsUserReadApiResponse, ApiGroupsUserReadApiArg>({
      query: (queryArg) => ({ url: `/api/groups/${queryArg.groupId}/user/${queryArg.userId}/` }),
    }),
    apiGroupsUserUpdate: build.mutation<ApiGroupsUserUpdateApiResponse, ApiGroupsUserUpdateApiArg>({
      query: (queryArg) => ({
        url: `/api/groups/${queryArg.groupId}/user/${queryArg.userId}/`,
        method: 'PUT',
        body: queryArg.postGroupUser,
      }),
    }),
    apiGroupsUserDelete: build.mutation<ApiGroupsUserDeleteApiResponse, ApiGroupsUserDeleteApiArg>({
      query: (queryArg) => ({
        url: `/api/groups/${queryArg.groupId}/user/${queryArg.userId}/`,
        method: 'DELETE',
      }),
    }),
    apiGroupsRead: build.query<ApiGroupsReadApiResponse, ApiGroupsReadApiArg>({
      query: (queryArg) => ({ url: `/api/groups/${queryArg.itemId}/` }),
    }),
    apiGroupsUpdate: build.mutation<ApiGroupsUpdateApiResponse, ApiGroupsUpdateApiArg>({
      query: (queryArg) => ({
        url: `/api/groups/${queryArg.itemId}/`,
        method: 'PUT',
        body: queryArg.groupItems,
      }),
    }),
    apiGroupsDelete: build.mutation<ApiGroupsDeleteApiResponse, ApiGroupsDeleteApiArg>({
      query: (queryArg) => ({ url: `/api/groups/${queryArg.itemId}/`, method: 'DELETE' }),
    }),
    apiIndividualProgramsCourseTopicsList: build.query<
      ApiIndividualProgramsCourseTopicsListApiResponse,
      ApiIndividualProgramsCourseTopicsListApiArg
    >({
      query: () => ({ url: `/api/individual_programs/course_topics/` }),
    }),
    apiIndividualProgramsCourseTopicsRequestsList: build.query<
      ApiIndividualProgramsCourseTopicsRequestsListApiResponse,
      ApiIndividualProgramsCourseTopicsRequestsListApiArg
    >({
      query: () => ({ url: `/api/individual_programs/course_topics/requests/` }),
    }),
    apiIndividualProgramsCourseTopicsRequestsCreate: build.mutation<
      ApiIndividualProgramsCourseTopicsRequestsCreateApiResponse,
      ApiIndividualProgramsCourseTopicsRequestsCreateApiArg
    >({
      query: (queryArg) => ({
        url: `/api/individual_programs/course_topics/requests/`,
        method: 'POST',
        body: queryArg.body,
      }),
    }),
    apiIndividualProgramsCourseTopicsRequestsNumberRead: build.query<
      ApiIndividualProgramsCourseTopicsRequestsNumberReadApiResponse,
      ApiIndividualProgramsCourseTopicsRequestsNumberReadApiArg
    >({
      query: (queryArg) => ({
        url: `/api/individual_programs/course_topics/requests/number/${queryArg['number']}/`,
      }),
    }),
    apiIndividualProgramsCourseTopicsRequestsUuidRead: build.query<
      ApiIndividualProgramsCourseTopicsRequestsUuidReadApiResponse,
      ApiIndividualProgramsCourseTopicsRequestsUuidReadApiArg
    >({
      query: (queryArg) => ({
        url: `/api/individual_programs/course_topics/requests/uuid/${queryArg.requestId}/`,
      }),
    }),
    apiIndividualProgramsCoursesRequestsList: build.query<
      ApiIndividualProgramsCoursesRequestsListApiResponse,
      ApiIndividualProgramsCoursesRequestsListApiArg
    >({
      query: () => ({ url: `/api/individual_programs/courses/requests/` }),
    }),
    apiIndividualProgramsCoursesRequestsCreate: build.mutation<
      ApiIndividualProgramsCoursesRequestsCreateApiResponse,
      ApiIndividualProgramsCoursesRequestsCreateApiArg
    >({
      query: (queryArg) => ({
        url: `/api/individual_programs/courses/requests/`,
        method: 'POST',
        body: queryArg.body,
      }),
    }),
    apiIndividualProgramsCoursesRequestsNumberRead: build.query<
      ApiIndividualProgramsCoursesRequestsNumberReadApiResponse,
      ApiIndividualProgramsCoursesRequestsNumberReadApiArg
    >({
      query: (queryArg) => ({
        url: `/api/individual_programs/courses/requests/number/${queryArg['number']}/`,
      }),
    }),
    apiIndividualProgramsCoursesRequestsToggleCreate: build.mutation<
      ApiIndividualProgramsCoursesRequestsToggleCreateApiResponse,
      ApiIndividualProgramsCoursesRequestsToggleCreateApiArg
    >({
      query: (queryArg) => ({
        url: `/api/individual_programs/courses/requests/toggle`,
        method: 'POST',
        body: queryArg.body,
      }),
    }),
    apiIndividualProgramsCoursesRequestsUuidRead: build.query<
      ApiIndividualProgramsCoursesRequestsUuidReadApiResponse,
      ApiIndividualProgramsCoursesRequestsUuidReadApiArg
    >({
      query: (queryArg) => ({
        url: `/api/individual_programs/courses/requests/uuid/${queryArg.requestId}/`,
      }),
    }),
    apiLearnOnOurPlatformList: build.query<
      ApiLearnOnOurPlatformListApiResponse,
      ApiLearnOnOurPlatformListApiArg
    >({
      query: () => ({ url: `/api/learn_on_our_platform/` }),
    }),
    apiListCartList: build.query<ApiListCartListApiResponse, ApiListCartListApiArg>({
      query: () => ({ url: `/api/list_cart/` }),
    }),
    apiListCartCreate: build.mutation<ApiListCartCreateApiResponse, ApiListCartCreateApiArg>({
      query: (queryArg) => ({ url: `/api/list_cart/`, method: 'POST', body: queryArg.body }),
    }),
    apiListFavoritesList: build.query<ApiListFavoritesListApiResponse, ApiListFavoritesListApiArg>({
      query: () => ({ url: `/api/list_favorites/` }),
    }),
    apiListFavoritesCreate: build.mutation<
      ApiListFavoritesCreateApiResponse,
      ApiListFavoritesCreateApiArg
    >({
      query: (queryArg) => ({ url: `/api/list_favorites/`, method: 'POST', body: queryArg.body }),
    }),
    getApiMediaAboutUs: build.query<GetApiMediaAboutUsApiResponse, GetApiMediaAboutUsApiArg>({
      query: (queryArg) => ({
        url: `/api/media-about-us/`,
        params: { limit: queryArg.limit, offset: queryArg.offset },
      }),
    }),
    getApiMngmNewRecordsCount: build.query<
      GetApiMngmNewRecordsCountApiResponse,
      GetApiMngmNewRecordsCountApiArg
    >({
      query: () => ({ url: `/api/mngm/new-records/count` }),
    }),
    apiPartnersList: build.query<ApiPartnersListApiResponse, ApiPartnersListApiArg>({
      query: () => ({ url: `/api/partners/` }),
    }),
    apiPayentServicesList: build.query<
      ApiPayentServicesListApiResponse,
      ApiPayentServicesListApiArg
    >({
      query: () => ({ url: `/api/payent_services/` }),
    }),
    apiPaymentSberpayCreate: build.mutation<
      ApiPaymentSberpayCreateApiResponse,
      ApiPaymentSberpayCreateApiArg
    >({
      query: (queryArg) => ({
        url: `/api/payment/sberpay`,
        method: 'POST',
        body: queryArg.sberPay,
      }),
    }),
    apiPaymentYandexCreate: build.mutation<
      ApiPaymentYandexCreateApiResponse,
      ApiPaymentYandexCreateApiArg
    >({
      query: (queryArg) => ({
        url: `/api/payment/yandex`,
        method: 'POST',
        body: queryArg.yandexPay,
      }),
    }),
    apiPromoCodesList: build.query<ApiPromoCodesListApiResponse, ApiPromoCodesListApiArg>({
      query: () => ({ url: `/api/promo_codes/` }),
    }),
    apiPromoCodesCheckRead: build.query<
      ApiPromoCodesCheckReadApiResponse,
      ApiPromoCodesCheckReadApiArg
    >({
      query: (queryArg) => ({ url: `/api/promo_codes/check/${queryArg.code}/` }),
    }),
    apiPromoCodesUserList: build.query<
      ApiPromoCodesUserListApiResponse,
      ApiPromoCodesUserListApiArg
    >({
      query: () => ({ url: `/api/promo_codes/user/` }),
    }),
    apiPromoCodesUserCreate: build.mutation<
      ApiPromoCodesUserCreateApiResponse,
      ApiPromoCodesUserCreateApiArg
    >({
      query: (queryArg) => ({
        url: `/api/promo_codes/user/`,
        method: 'POST',
        body: queryArg.userPromoCodeBody,
      }),
    }),
    apiPromoCodesUserActivateCreate: build.mutation<
      ApiPromoCodesUserActivateCreateApiResponse,
      ApiPromoCodesUserActivateCreateApiArg
    >({
      query: (queryArg) => ({
        url: `/api/promo_codes/user/activate/`,
        method: 'POST',
        body: queryArg.userPromoCodeBody,
      }),
    }),
    apiReviewsMngmReviewedCoursesList: build.query<
      ApiReviewsMngmReviewedCoursesListApiResponse,
      ApiReviewsMngmReviewedCoursesListApiArg
    >({
      query: () => ({ url: `/api/reviews/mngm/reviewed_courses/` }),
    }),
    apiReviewsMngmTextList: build.query<
      ApiReviewsMngmTextListApiResponse,
      ApiReviewsMngmTextListApiArg
    >({
      query: (queryArg) => ({
        url: `/api/reviews/mngm/text/`,
        params: {
          ordering: queryArg.ordering,
          search: queryArg.search,
          rate: queryArg.rate,
          main_page: queryArg.mainPage,
          created_at__gte: queryArg.createdAtGte,
          created_at__lte: queryArg.createdAtLte,
          course: queryArg.course,
          limit: queryArg.limit,
          offset: queryArg.offset,
        },
      }),
    }),
    apiReviewsMngmTextUpdate: build.mutation<
      ApiReviewsMngmTextUpdateApiResponse,
      ApiReviewsMngmTextUpdateApiArg
    >({
      query: (queryArg) => ({
        url: `/api/reviews/mngm/text/${queryArg.reviewId}/`,
        method: 'PUT',
        body: queryArg.mngmUpdateTextCourseReview,
      }),
    }),
    apiReviewsMngmTextDelete: build.mutation<
      ApiReviewsMngmTextDeleteApiResponse,
      ApiReviewsMngmTextDeleteApiArg
    >({
      query: (queryArg) => ({
        url: `/api/reviews/mngm/text/${queryArg.reviewId}/`,
        method: 'DELETE',
      }),
    }),
    apiReviewsMngmVideoList: build.query<
      ApiReviewsMngmVideoListApiResponse,
      ApiReviewsMngmVideoListApiArg
    >({
      query: (queryArg) => ({
        url: `/api/reviews/mngm/video/`,
        params: {
          ordering: queryArg.ordering,
          search: queryArg.search,
          rate: queryArg.rate,
          main_page: queryArg.mainPage,
          created_at__gte: queryArg.createdAtGte,
          created_at__lte: queryArg.createdAtLte,
          course: queryArg.course,
          limit: queryArg.limit,
          offset: queryArg.offset,
        },
      }),
    }),
    apiReviewsMngmVideoUpdate: build.mutation<
      ApiReviewsMngmVideoUpdateApiResponse,
      ApiReviewsMngmVideoUpdateApiArg
    >({
      query: (queryArg) => ({
        url: `/api/reviews/mngm/video/${queryArg.reviewId}/`,
        method: 'PUT',
        body: queryArg.mngmUpdateVideoCourseReview,
      }),
    }),
    apiReviewsMngmVideoDelete: build.mutation<
      ApiReviewsMngmVideoDeleteApiResponse,
      ApiReviewsMngmVideoDeleteApiArg
    >({
      query: (queryArg) => ({
        url: `/api/reviews/mngm/video/${queryArg.reviewId}/`,
        method: 'DELETE',
      }),
    }),
    apiReviewsTextList: build.query<ApiReviewsTextListApiResponse, ApiReviewsTextListApiArg>({
      query: () => ({ url: `/api/reviews/text/` }),
    }),
    apiReviewsTextAddCreate: build.mutation<
      ApiReviewsTextAddCreateApiResponse,
      ApiReviewsTextAddCreateApiArg
    >({
      query: (queryArg) => ({
        url: `/api/reviews/text/add/`,
        method: 'POST',
        body: queryArg.textCourseReviewAddRequestBody,
      }),
    }),
    apiReviewsTextCategoryRead: build.query<
      ApiReviewsTextCategoryReadApiResponse,
      ApiReviewsTextCategoryReadApiArg
    >({
      query: (queryArg) => ({ url: `/api/reviews/text/category/${queryArg.categoryId}/` }),
    }),
    apiReviewsTextRemoveDelete: build.mutation<
      ApiReviewsTextRemoveDeleteApiResponse,
      ApiReviewsTextRemoveDeleteApiArg
    >({
      query: (queryArg) => ({
        url: `/api/reviews/text/remove/${queryArg.reviewId}/`,
        method: 'DELETE',
      }),
    }),
    apiReviewsTextUserList: build.query<
      ApiReviewsTextUserListApiResponse,
      ApiReviewsTextUserListApiArg
    >({
      query: () => ({ url: `/api/reviews/text/user/` }),
    }),
    apiReviewsTextRead: build.query<ApiReviewsTextReadApiResponse, ApiReviewsTextReadApiArg>({
      query: (queryArg) => ({ url: `/api/reviews/text/${queryArg.courseId}/` }),
    }),
    apiReviewsVideoList: build.query<ApiReviewsVideoListApiResponse, ApiReviewsVideoListApiArg>({
      query: () => ({ url: `/api/reviews/video/` }),
    }),
    apiReviewsVideoAddCreate: build.mutation<
      ApiReviewsVideoAddCreateApiResponse,
      ApiReviewsVideoAddCreateApiArg
    >({
      query: (queryArg) => ({
        url: `/api/reviews/video/add/`,
        method: 'POST',
        body: queryArg.videoCourseReviewAddRequestBody,
      }),
    }),
    apiReviewsVideoCategoryRead: build.query<
      ApiReviewsVideoCategoryReadApiResponse,
      ApiReviewsVideoCategoryReadApiArg
    >({
      query: (queryArg) => ({ url: `/api/reviews/video/category/${queryArg.categoryId}/` }),
    }),
    apiReviewsVideoRemoveDelete: build.mutation<
      ApiReviewsVideoRemoveDeleteApiResponse,
      ApiReviewsVideoRemoveDeleteApiArg
    >({
      query: (queryArg) => ({
        url: `/api/reviews/video/remove/${queryArg.reviewId}/`,
        method: 'DELETE',
      }),
    }),
    apiReviewsVideoUserList: build.query<
      ApiReviewsVideoUserListApiResponse,
      ApiReviewsVideoUserListApiArg
    >({
      query: () => ({ url: `/api/reviews/video/user/` }),
    }),
    apiReviewsVideoRead: build.query<ApiReviewsVideoReadApiResponse, ApiReviewsVideoReadApiArg>({
      query: (queryArg) => ({ url: `/api/reviews/video/${queryArg.courseId}/` }),
    }),
    apiSpeakersList: build.query<ApiSpeakersListApiResponse, ApiSpeakersListApiArg>({
      query: () => ({ url: `/api/speakers/` }),
    }),
    apiSpeakersCreate: build.mutation<ApiSpeakersCreateApiResponse, ApiSpeakersCreateApiArg>({
      query: (queryArg) => ({ url: `/api/speakers/`, method: 'POST', body: queryArg.speaker }),
    }),
    apiStatisticAllFileList: build.query<
      ApiStatisticAllFileListApiResponse,
      ApiStatisticAllFileListApiArg
    >({
      query: (queryArg) => ({ url: `/api/statistic/all/${queryArg.period}/file` }),
    }),
    apiStatisticCoursesViewsRead: build.query<
      ApiStatisticCoursesViewsReadApiResponse,
      ApiStatisticCoursesViewsReadApiArg
    >({
      query: (queryArg) => ({ url: `/api/statistic/courses_views/${queryArg.period}` }),
    }),
    apiStatisticCoursesViewsFileList: build.query<
      ApiStatisticCoursesViewsFileListApiResponse,
      ApiStatisticCoursesViewsFileListApiArg
    >({
      query: (queryArg) => ({ url: `/api/statistic/courses_views/${queryArg.period}/file` }),
    }),
    apiStatisticRequestsByCoursesRead: build.query<
      ApiStatisticRequestsByCoursesReadApiResponse,
      ApiStatisticRequestsByCoursesReadApiArg
    >({
      query: (queryArg) => ({ url: `/api/statistic/requests_by_courses/${queryArg.period}` }),
    }),
    apiStatisticRequestsByCoursesFileList: build.query<
      ApiStatisticRequestsByCoursesFileListApiResponse,
      ApiStatisticRequestsByCoursesFileListApiArg
    >({
      query: (queryArg) => ({ url: `/api/statistic/requests_by_courses/${queryArg.period}/file` }),
    }),
    apiStatisticRequestsStatusesRead: build.query<
      ApiStatisticRequestsStatusesReadApiResponse,
      ApiStatisticRequestsStatusesReadApiArg
    >({
      query: (queryArg) => ({ url: `/api/statistic/requests_statuses/${queryArg.period}` }),
    }),
    apiStatisticRequestsStatusesFileList: build.query<
      ApiStatisticRequestsStatusesFileListApiResponse,
      ApiStatisticRequestsStatusesFileListApiArg
    >({
      query: (queryArg) => ({ url: `/api/statistic/requests_statuses/${queryArg.period}/file` }),
    }),
    apiStatisticSpeakersHoursRead: build.query<
      ApiStatisticSpeakersHoursReadApiResponse,
      ApiStatisticSpeakersHoursReadApiArg
    >({
      query: (queryArg) => ({ url: `/api/statistic/speakers_hours/${queryArg.period}` }),
    }),
    apiStatisticSpeakersHoursFileList: build.query<
      ApiStatisticSpeakersHoursFileListApiResponse,
      ApiStatisticSpeakersHoursFileListApiArg
    >({
      query: (queryArg) => ({ url: `/api/statistic/speakers_hours/${queryArg.period}/file` }),
    }),
    apiStatisticSpeakersHoursByCoursesRead: build.query<
      ApiStatisticSpeakersHoursByCoursesReadApiResponse,
      ApiStatisticSpeakersHoursByCoursesReadApiArg
    >({
      query: (queryArg) => ({ url: `/api/statistic/speakers_hours_by_courses/${queryArg.period}` }),
    }),
    apiStatisticSpeakersHoursByCoursesFileList: build.query<
      ApiStatisticSpeakersHoursByCoursesFileListApiResponse,
      ApiStatisticSpeakersHoursByCoursesFileListApiArg
    >({
      query: (queryArg) => ({
        url: `/api/statistic/speakers_hours_by_courses/${queryArg.period}/file`,
      }),
    }),
    apiStatisticUsersRead: build.query<
      ApiStatisticUsersReadApiResponse,
      ApiStatisticUsersReadApiArg
    >({
      query: (queryArg) => ({ url: `/api/statistic/users/${queryArg.period}` }),
    }),
    apiStatisticUsersFileList: build.query<
      ApiStatisticUsersFileListApiResponse,
      ApiStatisticUsersFileListApiArg
    >({
      query: (queryArg) => ({ url: `/api/statistic/users/${queryArg.period}/file` }),
    }),
    apiStatisticUsersCategoriesRead: build.query<
      ApiStatisticUsersCategoriesReadApiResponse,
      ApiStatisticUsersCategoriesReadApiArg
    >({
      query: (queryArg) => ({ url: `/api/statistic/users_categories/${queryArg.period}` }),
    }),
    apiStatisticUsersCategoriesFileList: build.query<
      ApiStatisticUsersCategoriesFileListApiResponse,
      ApiStatisticUsersCategoriesFileListApiArg
    >({
      query: (queryArg) => ({ url: `/api/statistic/users_categories/${queryArg.period}/file` }),
    }),
    apiStatisticUsersLocationsRead: build.query<
      ApiStatisticUsersLocationsReadApiResponse,
      ApiStatisticUsersLocationsReadApiArg
    >({
      query: (queryArg) => ({ url: `/api/statistic/users_locations/${queryArg.period}` }),
    }),
    apiStatisticUsersLocationsFileList: build.query<
      ApiStatisticUsersLocationsFileListApiResponse,
      ApiStatisticUsersLocationsFileListApiArg
    >({
      query: (queryArg) => ({ url: `/api/statistic/users_locations/${queryArg.period}/file` }),
    }),
    apiStatisticUsersProfessionsRead: build.query<
      ApiStatisticUsersProfessionsReadApiResponse,
      ApiStatisticUsersProfessionsReadApiArg
    >({
      query: (queryArg) => ({ url: `/api/statistic/users_professions/${queryArg.period}` }),
    }),
    apiStatisticUsersProfessionsFileList: build.query<
      ApiStatisticUsersProfessionsFileListApiResponse,
      ApiStatisticUsersProfessionsFileListApiArg
    >({
      query: (queryArg) => ({ url: `/api/statistic/users_professions/${queryArg.period}/file` }),
    }),
    apiStatisticUsersProfilesRead: build.query<
      ApiStatisticUsersProfilesReadApiResponse,
      ApiStatisticUsersProfilesReadApiArg
    >({
      query: (queryArg) => ({ url: `/api/statistic/users_profiles/${queryArg.period}` }),
    }),
    apiStatisticUsersProfilesFileList: build.query<
      ApiStatisticUsersProfilesFileListApiResponse,
      ApiStatisticUsersProfilesFileListApiArg
    >({
      query: (queryArg) => ({ url: `/api/statistic/users_profiles/${queryArg.period}/file` }),
    }),
    apiStatisticUsersTargetsGroupsRead: build.query<
      ApiStatisticUsersTargetsGroupsReadApiResponse,
      ApiStatisticUsersTargetsGroupsReadApiArg
    >({
      query: (queryArg) => ({ url: `/api/statistic/users_targets_groups/${queryArg.period}` }),
    }),
    apiStatisticUsersTargetsGroupsFileList: build.query<
      ApiStatisticUsersTargetsGroupsFileListApiResponse,
      ApiStatisticUsersTargetsGroupsFileListApiArg
    >({
      query: (queryArg) => ({ url: `/api/statistic/users_targets_groups/${queryArg.period}/file` }),
    }),
    apiTeachersList: build.query<ApiTeachersListApiResponse, ApiTeachersListApiArg>({
      query: () => ({ url: `/api/teachers/` }),
    }),
    apiTeachersCreate: build.mutation<ApiTeachersCreateApiResponse, ApiTeachersCreateApiArg>({
      query: (queryArg) => ({ url: `/api/teachers/`, method: 'POST', body: queryArg.postTeacher }),
    }),
    apiTeachersRead: build.query<ApiTeachersReadApiResponse, ApiTeachersReadApiArg>({
      query: (queryArg) => ({ url: `/api/teachers/${queryArg.teacherId}` }),
    }),
    apiTeachersUpdate: build.mutation<ApiTeachersUpdateApiResponse, ApiTeachersUpdateApiArg>({
      query: (queryArg) => ({
        url: `/api/teachers/${queryArg.teacherId}`,
        method: 'PUT',
        body: queryArg.postTeacher,
      }),
    }),
    apiTeachersDelete: build.mutation<ApiTeachersDeleteApiResponse, ApiTeachersDeleteApiArg>({
      query: (queryArg) => ({ url: `/api/teachers/${queryArg.teacherId}`, method: 'DELETE' }),
    }),
    getApiTopSpeakers: build.query<GetApiTopSpeakersApiResponse, GetApiTopSpeakersApiArg>({
      query: (queryArg) => ({
        url: `/api/top-speakers/`,
        params: { limit: queryArg.limit, offset: queryArg.offset },
      }),
    }),
    apiTrainingDiagnosticsList: build.query<
      ApiTrainingDiagnosticsListApiResponse,
      ApiTrainingDiagnosticsListApiArg
    >({
      query: () => ({ url: `/api/training/diagnostics/` }),
    }),
    apiTrainingDiagnosticsUserList: build.query<
      ApiTrainingDiagnosticsUserListApiResponse,
      ApiTrainingDiagnosticsUserListApiArg
    >({
      query: () => ({ url: `/api/training/diagnostics/user/` }),
    }),
    apiUserCoursesList: build.query<ApiUserCoursesListApiResponse, ApiUserCoursesListApiArg>({
      query: () => ({ url: `/api/user/courses` }),
    }),
    apiUserCoursesAchievementsList: build.query<
      ApiUserCoursesAchievementsListApiResponse,
      ApiUserCoursesAchievementsListApiArg
    >({
      query: () => ({ url: `/api/user/courses/achievements` }),
    }),
    apiUsersAddressesList: build.query<
      ApiUsersAddressesListApiResponse,
      ApiUsersAddressesListApiArg
    >({
      query: () => ({ url: `/api/users/addresses/` }),
    }),
    apiUsersAddressesCreate: build.mutation<
      ApiUsersAddressesCreateApiResponse,
      ApiUsersAddressesCreateApiArg
    >({
      query: (queryArg) => ({
        url: `/api/users/addresses/`,
        method: 'POST',
        body: queryArg.userAddressesPost,
      }),
    }),
    apiUsersAddressesUpdate: build.mutation<
      ApiUsersAddressesUpdateApiResponse,
      ApiUsersAddressesUpdateApiArg
    >({
      query: (queryArg) => ({
        url: `/api/users/addresses/`,
        method: 'PUT',
        body: queryArg.userAddressesPost,
      }),
    }),
    apiUsersAvatarUpdate: build.mutation<
      ApiUsersAvatarUpdateApiResponse,
      ApiUsersAvatarUpdateApiArg
    >({
      query: (queryArg) => ({ url: `/api/users/avatar/`, method: 'PUT', body: queryArg.body }),
    }),
    apiUsersChangeFioDocumentUpdate: build.mutation<
      ApiUsersChangeFioDocumentUpdateApiResponse,
      ApiUsersChangeFioDocumentUpdateApiArg
    >({
      query: (queryArg) => ({
        url: `/api/users/change_fio_document/`,
        method: 'PUT',
        body: queryArg.body,
      }),
    }),
    apiUsersDeleteDelete: build.mutation<
      ApiUsersDeleteDeleteApiResponse,
      ApiUsersDeleteDeleteApiArg
    >({
      query: () => ({ url: `/api/users/delete/`, method: 'DELETE' }),
    }),
    apiUsersDictionariesEducationDocumentsTypesList: build.query<
      ApiUsersDictionariesEducationDocumentsTypesListApiResponse,
      ApiUsersDictionariesEducationDocumentsTypesListApiArg
    >({
      query: () => ({ url: `/api/users/dictionaries/education_documents/types/` }),
    }),
    apiUsersDocumentFlowList: build.query<
      ApiUsersDocumentFlowListApiResponse,
      ApiUsersDocumentFlowListApiArg
    >({
      query: () => ({ url: `/api/users/document_flow/` }),
    }),
    apiUsersDocumentFlowCoursesList: build.query<
      ApiUsersDocumentFlowCoursesListApiResponse,
      ApiUsersDocumentFlowCoursesListApiArg
    >({
      query: () => ({ url: `/api/users/document_flow/courses/` }),
    }),
    apiUsersDocumentFlowSigningUpdate: build.mutation<
      ApiUsersDocumentFlowSigningUpdateApiResponse,
      ApiUsersDocumentFlowSigningUpdateApiArg
    >({
      query: (queryArg) => ({
        url: `/api/users/document_flow/signing/${queryArg.documentId}/`,
        method: 'PUT',
      }),
    }),
    apiUsersDocumentFlowViewingUpdate: build.mutation<
      ApiUsersDocumentFlowViewingUpdateApiResponse,
      ApiUsersDocumentFlowViewingUpdateApiArg
    >({
      query: (queryArg) => ({
        url: `/api/users/document_flow/viewing/${queryArg.documentId}/`,
        method: 'PUT',
      }),
    }),
    apiUsersDocumentsCreate: build.mutation<
      ApiUsersDocumentsCreateApiResponse,
      ApiUsersDocumentsCreateApiArg
    >({
      query: (queryArg) => ({ url: `/api/users/documents/`, method: 'POST', body: queryArg.body }),
    }),
    apiUsersDocumentsListList: build.query<
      ApiUsersDocumentsListListApiResponse,
      ApiUsersDocumentsListListApiArg
    >({
      query: (queryArg) => ({
        url: `/api/users/documents/list`,
        params: { limit: queryArg.limit, offset: queryArg.offset },
      }),
    }),
    apiUsersDocumentsRecognitionAddressDocumentUpdate: build.mutation<
      ApiUsersDocumentsRecognitionAddressDocumentUpdateApiResponse,
      ApiUsersDocumentsRecognitionAddressDocumentUpdateApiArg
    >({
      query: (queryArg) => ({
        url: `/api/users/documents/recognition/address_document`,
        method: 'PUT',
        body: queryArg.body,
      }),
    }),
    apiUsersDocumentsRecognitionEducationDocumentUpdate: build.mutation<
      ApiUsersDocumentsRecognitionEducationDocumentUpdateApiResponse,
      ApiUsersDocumentsRecognitionEducationDocumentUpdateApiArg
    >({
      query: (queryArg) => ({
        url: `/api/users/documents/recognition/education_document`,
        method: 'PUT',
        body: queryArg.body,
      }),
    }),
    apiUsersDocumentsRecognitionPassportUpdate: build.mutation<
      ApiUsersDocumentsRecognitionPassportUpdateApiResponse,
      ApiUsersDocumentsRecognitionPassportUpdateApiArg
    >({
      query: (queryArg) => ({
        url: `/api/users/documents/recognition/passport`,
        method: 'PUT',
        body: queryArg.body,
      }),
    }),
    apiUsersDocumentsRecognitionWorkBookUpdate: build.mutation<
      ApiUsersDocumentsRecognitionWorkBookUpdateApiResponse,
      ApiUsersDocumentsRecognitionWorkBookUpdateApiArg
    >({
      query: (queryArg) => ({
        url: `/api/users/documents/recognition/work_book`,
        method: 'PUT',
        body: queryArg.body,
      }),
    }),
    apiUsersDocumentsViewCreate: build.mutation<
      ApiUsersDocumentsViewCreateApiResponse,
      ApiUsersDocumentsViewCreateApiArg
    >({
      query: (queryArg) => ({
        url: `/api/users/documents/view`,
        method: 'POST',
        body: queryArg.userDocumentsViews,
      }),
    }),
    apiUsersEducationDocumentsList: build.query<
      ApiUsersEducationDocumentsListApiResponse,
      ApiUsersEducationDocumentsListApiArg
    >({
      query: () => ({ url: `/api/users/education_documents/` }),
    }),
    apiUsersEducationDocumentsCreate: build.mutation<
      ApiUsersEducationDocumentsCreateApiResponse,
      ApiUsersEducationDocumentsCreateApiArg
    >({
      query: (queryArg) => ({
        url: `/api/users/education_documents/`,
        method: 'POST',
        body: queryArg.userEducationDocuments,
      }),
    }),
    apiUsersEducationDocumentsScanUpdate: build.mutation<
      ApiUsersEducationDocumentsScanUpdateApiResponse,
      ApiUsersEducationDocumentsScanUpdateApiArg
    >({
      query: (queryArg) => ({
        url: `/api/users/education_documents/scan/${queryArg.documentId}/`,
        method: 'PUT',
        body: queryArg.body,
      }),
    }),
    apiUsersEducationDocumentsRead: build.query<
      ApiUsersEducationDocumentsReadApiResponse,
      ApiUsersEducationDocumentsReadApiArg
    >({
      query: (queryArg) => ({ url: `/api/users/education_documents/${queryArg.documentId}/` }),
    }),
    apiUsersEducationDocumentsUpdate: build.mutation<
      ApiUsersEducationDocumentsUpdateApiResponse,
      ApiUsersEducationDocumentsUpdateApiArg
    >({
      query: (queryArg) => ({
        url: `/api/users/education_documents/${queryArg.documentId}/`,
        method: 'PUT',
        body: queryArg.userEducationDocuments,
      }),
    }),
    apiUsersEducationDocumentsDelete: build.mutation<
      ApiUsersEducationDocumentsDeleteApiResponse,
      ApiUsersEducationDocumentsDeleteApiArg
    >({
      query: (queryArg) => ({
        url: `/api/users/education_documents/${queryArg.documentId}/`,
        method: 'DELETE',
      }),
    }),
    apiUsersEmploymentCenterList: build.query<
      ApiUsersEmploymentCenterListApiResponse,
      ApiUsersEmploymentCenterListApiArg
    >({
      query: () => ({ url: `/api/users/employment_center/` }),
    }),
    apiUsersEmploymentCenterCreate: build.mutation<
      ApiUsersEmploymentCenterCreateApiResponse,
      ApiUsersEmploymentCenterCreateApiArg
    >({
      query: (queryArg) => ({
        url: `/api/users/employment_center/`,
        method: 'POST',
        body: queryArg.updateUserEmploymentCenter,
      }),
    }),
    apiUsersEmploymentCenterUpdate: build.mutation<
      ApiUsersEmploymentCenterUpdateApiResponse,
      ApiUsersEmploymentCenterUpdateApiArg
    >({
      query: (queryArg) => ({
        url: `/api/users/employment_center/`,
        method: 'PUT',
        body: queryArg.updateUserEmploymentCenter,
      }),
    }),
    apiUsersEmploymentCenterDelete: build.mutation<
      ApiUsersEmploymentCenterDeleteApiResponse,
      ApiUsersEmploymentCenterDeleteApiArg
    >({
      query: () => ({ url: `/api/users/employment_center/`, method: 'DELETE' }),
    }),
    apiUsersEmploymentCenterScanUpdate: build.mutation<
      ApiUsersEmploymentCenterScanUpdateApiResponse,
      ApiUsersEmploymentCenterScanUpdateApiArg
    >({
      query: (queryArg) => ({
        url: `/api/users/employment_center/scan/`,
        method: 'PUT',
        body: queryArg.body,
      }),
    }),
    apiUsersFamilyStatusesList: build.query<
      ApiUsersFamilyStatusesListApiResponse,
      ApiUsersFamilyStatusesListApiArg
    >({
      query: () => ({ url: `/api/users/family_statuses/` }),
    }),
    apiUsersGendersList: build.query<ApiUsersGendersListApiResponse, ApiUsersGendersListApiArg>({
      query: () => ({ url: `/api/users/genders/` }),
    }),
    apiUsersIdentityDocumentsList: build.query<
      ApiUsersIdentityDocumentsListApiResponse,
      ApiUsersIdentityDocumentsListApiArg
    >({
      query: () => ({ url: `/api/users/identity_documents/` }),
    }),
    apiUsersIdentityDocumentsCreate: build.mutation<
      ApiUsersIdentityDocumentsCreateApiResponse,
      ApiUsersIdentityDocumentsCreateApiArg
    >({
      query: (queryArg) => ({
        url: `/api/users/identity_documents/`,
        method: 'POST',
        body: queryArg.userIdentityDocuments,
      }),
    }),
    apiUsersIdentityDocumentsScanUpdate: build.mutation<
      ApiUsersIdentityDocumentsScanUpdateApiResponse,
      ApiUsersIdentityDocumentsScanUpdateApiArg
    >({
      query: (queryArg) => ({
        url: `/api/users/identity_documents/scan/${queryArg.documentId}/`,
        method: 'PUT',
        body: queryArg.body,
      }),
    }),
    apiUsersIdentityDocumentsScanCvUpdate: build.mutation<
      ApiUsersIdentityDocumentsScanCvUpdateApiResponse,
      ApiUsersIdentityDocumentsScanCvUpdateApiArg
    >({
      query: (queryArg) => ({
        url: `/api/users/identity_documents/scan_cv`,
        method: 'PUT',
        body: queryArg.body,
      }),
    }),
    apiUsersIdentityDocumentsRead: build.query<
      ApiUsersIdentityDocumentsReadApiResponse,
      ApiUsersIdentityDocumentsReadApiArg
    >({
      query: (queryArg) => ({ url: `/api/users/identity_documents/${queryArg.documentId}/` }),
    }),
    apiUsersIdentityDocumentsUpdate: build.mutation<
      ApiUsersIdentityDocumentsUpdateApiResponse,
      ApiUsersIdentityDocumentsUpdateApiArg
    >({
      query: (queryArg) => ({
        url: `/api/users/identity_documents/${queryArg.documentId}/`,
        method: 'PUT',
        body: queryArg.userIdentityDocuments,
      }),
    }),
    apiUsersIdentityDocumentsDelete: build.mutation<
      ApiUsersIdentityDocumentsDeleteApiResponse,
      ApiUsersIdentityDocumentsDeleteApiArg
    >({
      query: (queryArg) => ({
        url: `/api/users/identity_documents/${queryArg.documentId}/`,
        method: 'DELETE',
      }),
    }),
    apiUsersLoginCreate: build.mutation<ApiUsersLoginCreateApiResponse, ApiUsersLoginCreateApiArg>({
      query: (queryArg) => ({
        url: `/api/users/login/`,
        method: 'POST',
        body: queryArg.userTokenObtainPair,
      }),
    }),
    apiUsersLoginAdCreate: build.mutation<
      ApiUsersLoginAdCreateApiResponse,
      ApiUsersLoginAdCreateApiArg
    >({
      query: (queryArg) => ({ url: `/api/users/login/ad/`, method: 'POST', body: queryArg.body }),
    }),
    apiUsersLoginGuCreate: build.mutation<
      ApiUsersLoginGuCreateApiResponse,
      ApiUsersLoginGuCreateApiArg
    >({
      query: (queryArg) => ({ url: `/api/users/login/gu/`, method: 'POST', body: queryArg.body }),
    }),
    apiUsersLoginRefreshCreate: build.mutation<
      ApiUsersLoginRefreshCreateApiResponse,
      ApiUsersLoginRefreshCreateApiArg
    >({
      query: (queryArg) => ({
        url: `/api/users/login/refresh/`,
        method: 'POST',
        body: queryArg.userTokenRefresh,
      }),
    }),
    apiUsersLoginVkCreate: build.mutation<
      ApiUsersLoginVkCreateApiResponse,
      ApiUsersLoginVkCreateApiArg
    >({
      query: (queryArg) => ({ url: `/api/users/login/vk/`, method: 'POST', body: queryArg.body }),
    }),
    apiUsersLoginYaCreate: build.mutation<
      ApiUsersLoginYaCreateApiResponse,
      ApiUsersLoginYaCreateApiArg
    >({
      query: (queryArg) => ({ url: `/api/users/login/ya/`, method: 'POST', body: queryArg.body }),
    }),
    apiUsersLogoutCreate: build.mutation<
      ApiUsersLogoutCreateApiResponse,
      ApiUsersLogoutCreateApiArg
    >({
      query: (queryArg) => ({ url: `/api/users/logout/`, method: 'POST', body: queryArg.body }),
    }),
    apiUsersProfileRead: build.query<ApiUsersProfileReadApiResponse, ApiUsersProfileReadApiArg>({
      query: () => ({ url: `/api/users/profile/` }),
    }),
    apiUsersProfileUpdate: build.mutation<
      ApiUsersProfileUpdateApiResponse,
      ApiUsersProfileUpdateApiArg
    >({
      query: (queryArg) => ({
        url: `/api/users/profile/`,
        method: 'PUT',
        body: queryArg.updateUserProfile,
      }),
    }),
    apiUsersProfileDelete: build.mutation<
      ApiUsersProfileDeleteApiResponse,
      ApiUsersProfileDeleteApiArg
    >({
      query: () => ({ url: `/api/users/profile/`, method: 'DELETE' }),
    }),
    apiUsersRegisterCreate: build.mutation<
      ApiUsersRegisterCreateApiResponse,
      ApiUsersRegisterCreateApiArg
    >({
      query: (queryArg) => ({
        url: `/api/users/register/`,
        method: 'POST',
        body: queryArg.userRegistration,
      }),
    }),
    apiUsersWorkBookList: build.query<ApiUsersWorkBookListApiResponse, ApiUsersWorkBookListApiArg>({
      query: () => ({ url: `/api/users/work_book/` }),
    }),
    apiUsersWorkBookCreate: build.mutation<
      ApiUsersWorkBookCreateApiResponse,
      ApiUsersWorkBookCreateApiArg
    >({
      query: (queryArg) => ({
        url: `/api/users/work_book/`,
        method: 'POST',
        body: queryArg.userWorkBook,
      }),
    }),
    apiUsersWorkBookUploadScanList: build.query<
      ApiUsersWorkBookUploadScanListApiResponse,
      ApiUsersWorkBookUploadScanListApiArg
    >({
      query: () => ({ url: `/api/users/work_book/upload/scan/` }),
    }),
    apiUsersWorkBookUploadScanUpdate: build.mutation<
      ApiUsersWorkBookUploadScanUpdateApiResponse,
      ApiUsersWorkBookUploadScanUpdateApiArg
    >({
      query: (queryArg) => ({
        url: `/api/users/work_book/upload/scan/`,
        method: 'PUT',
        body: queryArg.body,
      }),
    }),
    apiUsersWorkBookUploadScanDelete: build.mutation<
      ApiUsersWorkBookUploadScanDeleteApiResponse,
      ApiUsersWorkBookUploadScanDeleteApiArg
    >({
      query: () => ({ url: `/api/users/work_book/upload/scan/`, method: 'DELETE' }),
    }),
    apiUsersWorkBookRead: build.query<ApiUsersWorkBookReadApiResponse, ApiUsersWorkBookReadApiArg>({
      query: (queryArg) => ({ url: `/api/users/work_book/${queryArg.documentId}/` }),
    }),
    apiUsersWorkBookUpdate: build.mutation<
      ApiUsersWorkBookUpdateApiResponse,
      ApiUsersWorkBookUpdateApiArg
    >({
      query: (queryArg) => ({
        url: `/api/users/work_book/${queryArg.documentId}/`,
        method: 'PUT',
        body: queryArg.userWorkBook,
      }),
    }),
    apiUsersWorkBookDelete: build.mutation<
      ApiUsersWorkBookDeleteApiResponse,
      ApiUsersWorkBookDeleteApiArg
    >({
      query: (queryArg) => ({
        url: `/api/users/work_book/${queryArg.documentId}/`,
        method: 'DELETE',
      }),
    }),
    externalApiCoursesList: build.query<
      ExternalApiCoursesListApiResponse,
      ExternalApiCoursesListApiArg
    >({
      query: (queryArg) => ({
        url: `/external_api/courses/`,
        headers: { 'X-Api-Key': queryArg['X-Api-Key'] },
      }),
    }),
    externalApiCoursesRead: build.query<
      ExternalApiCoursesReadApiResponse,
      ExternalApiCoursesReadApiArg
    >({
      query: (queryArg) => ({
        url: `/external_api/courses/${queryArg.courseId}/`,
        headers: { 'X-Api-Key': queryArg['X-Api-Key'] },
      }),
    }),
    externalApiFaqList: build.query<ExternalApiFaqListApiResponse, ExternalApiFaqListApiArg>({
      query: (queryArg) => ({
        url: `/external_api/faq/`,
        headers: { 'X-Api-Key': queryArg['X-Api-Key'] },
      }),
    }),
    externalApiListRequestsList: build.query<
      ExternalApiListRequestsListApiResponse,
      ExternalApiListRequestsListApiArg
    >({
      query: (queryArg) => ({
        url: `/external_api/list_requests/`,
        headers: { 'X-Api-Key': queryArg['X-Api-Key'] },
      }),
    }),
    externalApiPartnersList: build.query<
      ExternalApiPartnersListApiResponse,
      ExternalApiPartnersListApiArg
    >({
      query: (queryArg) => ({
        url: `/external_api/partners/`,
        headers: { 'X-Api-Key': queryArg['X-Api-Key'] },
      }),
    }),
    externalApiPromoCodesList: build.query<
      ExternalApiPromoCodesListApiResponse,
      ExternalApiPromoCodesListApiArg
    >({
      query: (queryArg) => ({
        url: `/external_api/promo_codes/`,
        headers: { 'X-Api-Key': queryArg['X-Api-Key'] },
      }),
    }),
    externalApiRecognizeDocumentCreate: build.mutation<
      ExternalApiRecognizeDocumentCreateApiResponse,
      ExternalApiRecognizeDocumentCreateApiArg
    >({
      query: (queryArg) => ({
        url: `/external_api/recognize_document/`,
        method: 'POST',
        body: queryArg.postSendRecognize,
        headers: { 'X-Api-Key': queryArg['X-Api-Key'] },
      }),
    }),
    externalApiReviewsTextList: build.query<
      ExternalApiReviewsTextListApiResponse,
      ExternalApiReviewsTextListApiArg
    >({
      query: (queryArg) => ({
        url: `/external_api/reviews/text/`,
        headers: { 'X-Api-Key': queryArg['X-Api-Key'] },
      }),
    }),
    externalApiReviewsVideoList: build.query<
      ExternalApiReviewsVideoListApiResponse,
      ExternalApiReviewsVideoListApiArg
    >({
      query: (queryArg) => ({
        url: `/external_api/reviews/video/`,
        headers: { 'X-Api-Key': queryArg['X-Api-Key'] },
      }),
    }),
    externalApiSendDocumentsCreate: build.mutation<
      ExternalApiSendDocumentsCreateApiResponse,
      ExternalApiSendDocumentsCreateApiArg
    >({
      query: (queryArg) => ({
        url: `/external_api/send_documents/`,
        method: 'POST',
        body: queryArg.postSendDocuments,
        headers: { 'X-Api-Key': queryArg['X-Api-Key'] },
      }),
    }),
    externalApiSpeakersList: build.query<
      ExternalApiSpeakersListApiResponse,
      ExternalApiSpeakersListApiArg
    >({
      query: (queryArg) => ({
        url: `/external_api/speakers/`,
        headers: { 'X-Api-Key': queryArg['X-Api-Key'] },
      }),
    }),
    externalApiStatisticCoursesViewsRead: build.query<
      ExternalApiStatisticCoursesViewsReadApiResponse,
      ExternalApiStatisticCoursesViewsReadApiArg
    >({
      query: (queryArg) => ({
        url: `/external_api/statistic/courses_views/${queryArg.period}`,
        headers: { 'X-Api-Key': queryArg['X-Api-Key'] },
      }),
    }),
    externalApiStatisticRequestsStatusesRead: build.query<
      ExternalApiStatisticRequestsStatusesReadApiResponse,
      ExternalApiStatisticRequestsStatusesReadApiArg
    >({
      query: (queryArg) => ({
        url: `/external_api/statistic/requests_statuses/${queryArg.period}`,
        headers: { 'X-Api-Key': queryArg['X-Api-Key'] },
      }),
    }),
    externalApiStatisticUsersTargetsGroupsRead: build.query<
      ExternalApiStatisticUsersTargetsGroupsReadApiResponse,
      ExternalApiStatisticUsersTargetsGroupsReadApiArg
    >({
      query: (queryArg) => ({
        url: `/external_api/statistic/users_targets_groups/${queryArg.period}`,
        headers: { 'X-Api-Key': queryArg['X-Api-Key'] },
      }),
    }),
    externalApiTargetGroupsList: build.query<
      ExternalApiTargetGroupsListApiResponse,
      ExternalApiTargetGroupsListApiArg
    >({
      query: (queryArg) => ({
        url: `/external_api/target_groups/`,
        headers: { 'X-Api-Key': queryArg['X-Api-Key'] },
      }),
    }),
    externalApiUsersEmploymentCenterScanRead: build.query<
      ExternalApiUsersEmploymentCenterScanReadApiResponse,
      ExternalApiUsersEmploymentCenterScanReadApiArg
    >({
      query: (queryArg) => ({
        url: `/external_api/users/employment_center/scan/${queryArg.userEmail}/`,
        headers: { 'X-Api-Key': queryArg['X-Api-Key'] },
      }),
    }),
    externalApiUsersEmploymentCenterScanCreate: build.mutation<
      ExternalApiUsersEmploymentCenterScanCreateApiResponse,
      ExternalApiUsersEmploymentCenterScanCreateApiArg
    >({
      query: (queryArg) => ({
        url: `/external_api/users/employment_center/scan/${queryArg.userEmail}/`,
        method: 'POST',
        body: queryArg.body,
        headers: { 'X-Api-Key': queryArg['X-Api-Key'] },
      }),
    }),
    externalApiUsersRead: build.query<ExternalApiUsersReadApiResponse, ExternalApiUsersReadApiArg>({
      query: (queryArg) => ({
        url: `/external_api/users/${queryArg.email}/`,
        headers: { 'X-Api-Key': queryArg['X-Api-Key'] },
      }),
    }),
  }),
  overrideExisting: false,
});
export { injectedRtkApi as baseApi };
export type ApiAnalyticsUserEmailReadApiResponse =
  /** status 200 Operation successful */ UserAnalytics200;
export type ApiAnalyticsUserEmailReadApiArg = {
  userEmail: string;
};
export type ApiAnalyticsUserReadApiResponse =
  /** status 200 Operation successful */ UserAnalytics200;
export type ApiAnalyticsUserReadApiArg = {
  userId: string;
};
export type ApiCartCreateApiResponse =
  /** status 200 Operation successful */ FavoriteCoursesResponseSuccess;
export type ApiCartCreateApiArg = {
  courseId: string;
  body: {};
};
export type ApiCartDeleteApiResponse =
  /** status 200 Operation successful */ FavoriteCoursesResponseSuccess;
export type ApiCartDeleteApiArg = {
  courseId: string;
};
export type ApiCertificatesListApiResponse = /** status 200  */ MainPageCertificates[];
export type ApiCertificatesListApiArg = void;
export type ApiCompaniesListApiResponse = /** status 200  */ Company[];
export type ApiCompaniesListApiArg = void;
export type ApiCompaniesCreateApiResponse = /** status 200 Created company */ PostCompany;
export type ApiCompaniesCreateApiArg = {
  postCompany: PostCompany;
};
export type ApiCompaniesResponsibleListApiResponse = /** status 200  */ Responsible[];
export type ApiCompaniesResponsibleListApiArg = void;
export type ApiCompaniesResponsibleCreateApiResponse =
  /** status 200 Created responsible */ PostResponsible;
export type ApiCompaniesResponsibleCreateApiArg = {
  postResponsible: PostResponsible;
};
export type ApiCompaniesResponsibleReadApiResponse = /** status 200  */ Responsible;
export type ApiCompaniesResponsibleReadApiArg = {
  /** A UUID string identifying this Ответственные. */
  id: string;
};
export type ApiCompaniesResponsibleUpdateApiResponse =
  /** status 200 Created responsible */ PostResponsible;
export type ApiCompaniesResponsibleUpdateApiArg = {
  /** A UUID string identifying this Ответственные. */
  id: string;
  postResponsible: PostResponsible;
};
export type ApiCompaniesResponsibleDeleteApiResponse = unknown;
export type ApiCompaniesResponsibleDeleteApiArg = {
  /** A UUID string identifying this Ответственные. */
  id: string;
};
export type ApiCompaniesAvatarUpdateApiResponse = /** status 200  */ Company;
export type ApiCompaniesAvatarUpdateApiArg = {
  companyId: string;
  body: {
    file?: Blob;
  };
};
export type ApiCompaniesReadApiResponse = /** status 200  */ Company;
export type ApiCompaniesReadApiArg = {
  /** A UUID string identifying this Организации. */
  id: string;
};
export type ApiCompaniesUpdateApiResponse = /** status 200 Created company */ PostCompany;
export type ApiCompaniesUpdateApiArg = {
  /** A UUID string identifying this Организации. */
  id: string;
  postCompany: PostCompany;
};
export type ApiCompaniesDeleteApiResponse = unknown;
export type ApiCompaniesDeleteApiArg = {
  /** A UUID string identifying this Организации. */
  id: string;
};
export type ApiConformityUserCoursesCreateApiResponse = /** status 200  */ {
  '999f3e68-58de-442a-8023-9a576056935c'?: {
    professions?: boolean;
    skills?: boolean;
    groups?: boolean;
    user_age?: boolean;
    places_available?: boolean;
    education_requirements?: boolean;
    schedule_intersect?: boolean;
  };
};
export type ApiConformityUserCoursesCreateApiArg = {
  conformityUserCoursesRequestBody: ConformityUserCoursesRequestBody;
};
export type ApiConformityUserCoursesEmailCreateApiResponse = /** status 200  */ {
  '999f3e68-58de-442a-8023-9a576056935c'?: {
    professions?: boolean;
    skills?: boolean;
    groups?: boolean;
    user_age?: boolean;
    places_available?: boolean;
    education_requirements?: boolean;
    schedule_intersect?: boolean;
  };
};
export type ApiConformityUserCoursesEmailCreateApiArg = {
  conformityUserCoursesByEmailRequestBody: ConformityUserCoursesByEmailRequestBody;
};
export type GetApiCourseRequestByRequestIdApiResponse = /** status 200  */ CourseRequest;
export type GetApiCourseRequestByRequestIdApiArg = {
  requestId: string;
};
export type PutApiCourseRequestByRequestIdApiResponse =
  /** status 201 Operation successful */ CourseRequestsResponse200;
export type PutApiCourseRequestByRequestIdApiArg = {
  requestId: string;
  courseRequestsUpdateBody: CourseRequestsUpdateBody;
};
export type PostApiCourseRequestsApiResponse =
  /** status 201 Operation successful */ CourseRequestsResponse201WithIDs;
export type PostApiCourseRequestsApiArg = {
  courseRequestsPostBody: CourseRequestsPostBody;
};
export type GetApiCourseRequestsCsvByRequestIdApiResponse = /** status 200 File Attachment */ Blob;
export type GetApiCourseRequestsCsvByRequestIdApiArg = {
  requestId: string;
};
export type GetApiCourseRequestsDocxByRequestIdApiResponse = /** status 200 File Attachment */ Blob;
export type GetApiCourseRequestsDocxByRequestIdApiArg = {
  requestId: string;
};
export type GetApiCourseRequestsEmailAuthApiResponse = /** status 200  */ CourseRequest[];
export type GetApiCourseRequestsEmailAuthApiArg = void;
export type GetApiCourseRequestsMngmReviewApiResponse = /** status 200  */ {
  count: number;
  next?: string | null;
  previous?: string | null;
  results: CourseRequestMngm[];
};
export type GetApiCourseRequestsMngmReviewApiArg = {
  /** Which field to use when ordering the results. */
  ordering?: string;
  /** A search term. */
  search?: string;
  status?: string;
  course?: string;
  createdAtGte?: string;
  createdAtLte?: string;
  /** Number of results to return per page. */
  limit?: number;
  /** The initial index from which to return the results. */
  offset?: number;
};
export type PutApiCourseRequestsMngmReviewByRequestIdApiResponse =
  /** status 200 Course request response */ CourseRequest;
export type PutApiCourseRequestsMngmReviewByRequestIdApiArg = {
  requestId: string;
  reviewCourseRequest: ReviewCourseRequest;
};
export type PostApiCourseRequestsPassportScanApiResponse =
  /** status 200 Operation successful */ CourseRequestsResponse200UploadScan;
export type PostApiCourseRequestsPassportScanApiArg = {
  body: {
    file?: Blob;
  };
};
export type PostApiCourseRequestsWorkBookScanApiResponse =
  /** status 200 Operation successful */ CourseRequestsResponse200UploadScan;
export type PostApiCourseRequestsWorkBookScanApiArg = {
  body: {
    file?: Blob;
  };
};
export type GetApiCourseRequestsByCourseRequestIdCommentsApiResponse =
  /** status 200 Course request comments */ GetCourseRequestComment[];
export type GetApiCourseRequestsByCourseRequestIdCommentsApiArg = {
  courseRequestId: string;
};
export type PostApiCourseRequestsByCourseRequestIdCommentsApiResponse =
  /** status 201  */ CourseRequestComment;
export type PostApiCourseRequestsByCourseRequestIdCommentsApiArg = {
  courseRequestId: string;
  courseRequestComment: CourseRequestComment;
};
export type GetApiCourseRequestsByCourseRequestIdServiceCommentsApiResponse =
  /** status 200 Course request comments */ GetCourseRequestComment[];
export type GetApiCourseRequestsByCourseRequestIdServiceCommentsApiArg = {
  courseRequestId: string;
};
export type PostApiCourseRequestsByCourseRequestIdServiceCommentsApiResponse =
  /** status 201  */ CourseRequestServiceComment;
export type PostApiCourseRequestsByCourseRequestIdServiceCommentsApiArg = {
  courseRequestId: string;
  courseRequestServiceComment: CourseRequestServiceComment;
};
export type GetApiCourseRequestsByEmailApiResponse =
  /** status 200 Get list of requests */ CourseRequest[];
export type GetApiCourseRequestsByEmailApiArg = {
  email: string;
};
export type ApiCoursesListApiResponse = /** status 200  */ {
  count: number;
  next?: string | null;
  previous?: string | null;
  results: Course[];
};
export type ApiCoursesListApiArg = {
  /** Which field to use when ordering the results. */
  ordering?: string;
  /** A search term. */
  search?: string;
  /** Number of results to return per page. */
  limit?: number;
  /** The initial index from which to return the results. */
  offset?: number;
};
export type ApiCoursesCategoryFiltersListApiResponse = /** status 200  */ ListCourseFilters[];
export type ApiCoursesCategoryFiltersListApiArg = void;
export type ApiCoursesMngmListApiResponse = /** status 200  */ {
  count: number;
  next?: string | null;
  previous?: string | null;
  results: Course[];
};
export type ApiCoursesMngmListApiArg = {
  /** Which field to use when ordering the results. */
  ordering?: string;
  /** A search term. */
  search?: string;
  isAvailable?: string;
  /** Number of results to return per page. */
  limit?: number;
  /** The initial index from which to return the results. */
  offset?: number;
};
export type ApiCoursesRecommendationListApiResponse =
  /** status 200 Operation successful */ ListRecomendationCoursesResponseSuccess;
export type ApiCoursesRecommendationListApiArg = void;
export type ApiCoursesRecommendationCreateApiResponse = /** status 201  */ Course;
export type ApiCoursesRecommendationCreateApiArg = {
  course: Course;
};
export type PostApiCoursesSendToMailApiResponse = unknown;
export type PostApiCoursesSendToMailApiArg = {
  courseSendToMail: CourseSendToMail;
};
export type ApiCoursesUploadFileCreateApiResponse = /** status 200 Courses loaded */ undefined;
export type ApiCoursesUploadFileCreateApiArg = {
  body: {
    file?: Blob;
  };
};
export type ApiCoursesReadApiResponse = /** status 200  */ Course;
export type ApiCoursesReadApiArg = {
  courseId: string;
};
export type ApiCoursesScheduleListApiResponse = /** status 200  */ CourseSchedule[];
export type ApiCoursesScheduleListApiArg = {
  courseId: string;
};
export type ApiDictionariesCitiesListApiResponse = /** status 200  */ ListCity[];
export type ApiDictionariesCitiesListApiArg = void;
export type ApiDictionariesCitiesReadApiResponse =
  /** status 200 Operation successful */ DictionariesResponse200ListCities[];
export type ApiDictionariesCitiesReadApiArg = {
  regionId: string;
};
export type ApiDictionariesCityReadApiResponse = /** status 200  */ ListCity;
export type ApiDictionariesCityReadApiArg = {
  cityId: string;
};
export type ApiDictionariesCountriesListApiResponse = /** status 200  */ ListCountry[];
export type ApiDictionariesCountriesListApiArg = void;
export type ApiDictionariesCountryReadApiResponse = /** status 200  */ ListCountry;
export type ApiDictionariesCountryReadApiArg = {
  countryId: string;
};
export type ApiDictionariesRegionReadApiResponse = /** status 200  */ ListRegion;
export type ApiDictionariesRegionReadApiArg = {
  regionId: string;
};
export type ApiDictionariesRegionsListApiResponse = /** status 200  */ ListRegion[];
export type ApiDictionariesRegionsListApiArg = void;
export type ApiDictionariesRegionsReadApiResponse =
  /** status 200 Operation successful */ DictionariesResponse200ListRegions[];
export type ApiDictionariesRegionsReadApiArg = {
  countryId: string;
};
export type ApiDictionaryCoursesAreasListApiResponse = /** status 200  */ CourseFieldActivity[];
export type ApiDictionaryCoursesAreasListApiArg = void;
export type ApiDictionaryCoursesCategoriesListApiResponse = /** status 200  */ CourseCategory[];
export type ApiDictionaryCoursesCategoriesListApiArg = void;
export type ApiDictionaryCoursesCertsListApiResponse = /** status 200  */ CourseCertificate[];
export type ApiDictionaryCoursesCertsListApiArg = void;
export type GetApiDictionaryCoursesEduRequirementsApiResponse =
  /** status 200  */ CourseEduRequirement[];
export type GetApiDictionaryCoursesEduRequirementsApiArg = void;
export type ApiDictionaryCoursesFormatsListApiResponse = /** status 200  */ CourseFormat[];
export type ApiDictionaryCoursesFormatsListApiArg = void;
export type ApiDictionaryCoursesGroupsListApiResponse = /** status 200  */ TargetGroup[];
export type ApiDictionaryCoursesGroupsListApiArg = void;
export type ApiDictionaryCoursesPeriodsListApiResponse = /** status 200  */ CoursePeriod[];
export type ApiDictionaryCoursesPeriodsListApiArg = void;
export type ApiDictionaryCoursesPlatformsListApiResponse =
  /** status 200  */ CoursePlatformFilter[];
export type ApiDictionaryCoursesPlatformsListApiArg = void;
export type ApiDictionaryCoursesProfilesListApiResponse = /** status 200  */ Profile[];
export type ApiDictionaryCoursesProfilesListApiArg = void;
export type ApiDictionaryCoursesRegionsListApiResponse = /** status 200  */ CourseRegion[];
export type ApiDictionaryCoursesRegionsListApiArg = void;
export type ApiDictionaryCoursesSubjectsListApiResponse = /** status 200  */ CourseSubject[];
export type ApiDictionaryCoursesSubjectsListApiArg = void;
export type ApiDictionaryCoursesUniversitiesListApiResponse = /** status 200  */ CourseUniversity[];
export type ApiDictionaryCoursesUniversitiesListApiArg = void;
export type GetApiEmploymentCenterDocTypesApiResponse =
  /** status 200 Employment Center documents types */ EmploymentCenterDocumentTypesSwagger[];
export type GetApiEmploymentCenterDocTypesApiArg = void;
export type ApiEmploymentCenterCreateApiResponse =
  /** status 200 Operation successful */ CourseRequestsResponse200UploadScan;
export type ApiEmploymentCenterCreateApiArg = {
  docType: string;
  body: {
    file?: Blob;
  };
};
export type ApiFaqCategoriesListApiResponse = /** status 200  */ FaqCategories[];
export type ApiFaqCategoriesListApiArg = void;
export type ApiFaqItemReadApiResponse = /** status 200  */ FaqItem;
export type ApiFaqItemReadApiArg = {
  faqId: string;
};
export type ApiFaqListCategoryItemsListApiResponse = /** status 200  */ {
  count: number;
  next?: string | null;
  previous?: string | null;
  results: FaqListItems[];
};
export type ApiFaqListCategoryItemsListApiArg = {
  /** Which field to use when ordering the results. */
  ordering?: string;
  /** A search term. */
  search?: string;
  category?: string;
  course?: string;
  /** Number of results to return per page. */
  limit?: number;
  /** The initial index from which to return the results. */
  offset?: number;
};
export type ApiFaqListItemsListApiResponse = /** status 200  */ {
  count: number;
  next?: string | null;
  previous?: string | null;
  results: FaqListItems[];
};
export type ApiFaqListItemsListApiArg = {
  /** Which field to use when ordering the results. */
  ordering?: string;
  /** A search term. */
  search?: string;
  category?: string;
  course?: string;
  /** Number of results to return per page. */
  limit?: number;
  /** The initial index from which to return the results. */
  offset?: number;
};
export type ApiFaqMainPageListApiResponse = /** status 200  */ {
  count: number;
  next?: string | null;
  previous?: string | null;
  results: FaqListItems[];
};
export type ApiFaqMainPageListApiArg = {
  /** Which field to use when ordering the results. */
  ordering?: string;
  /** A search term. */
  search?: string;
  category?: string;
  course?: string;
  /** Number of results to return per page. */
  limit?: number;
  /** The initial index from which to return the results. */
  offset?: number;
};
export type ApiFavoritesCreateApiResponse =
  /** status 200 Operation successful */ FavoriteCoursesResponseSuccess;
export type ApiFavoritesCreateApiArg = {
  courseId: string;
  body: {};
};
export type ApiFavoritesDeleteApiResponse =
  /** status 200 Operation successful */ FavoriteCoursesResponseSuccess;
export type ApiFavoritesDeleteApiArg = {
  courseId: string;
};
export type ApiFilesCreateApiResponse = /** status 201  */ UserFileUpload;
export type ApiFilesCreateApiArg = {
  body: {
    file: Blob;
  };
};
export type ApiGeneralMainBannerListApiResponse = /** status 200  */ MainBanner;
export type ApiGeneralMainBannerListApiArg = void;
export type ApiGeneralProfessionsListApiResponse = /** status 200  */ ListProfessions[];
export type ApiGeneralProfessionsListApiArg = void;
export type ApiGeneralSkillsListApiResponse = /** status 200  */ ListSkills[];
export type ApiGeneralSkillsListApiArg = void;
export type ApiGeneralTopMenusListApiResponse = /** status 200  */ TopMenus[];
export type ApiGeneralTopMenusListApiArg = void;
export type ApiGroupsListApiResponse =
  /** status 200 Operation successful */ ListGroupsResponseSuccess[];
export type ApiGroupsListApiArg = void;
export type ApiGroupsCreateApiResponse = /** status 200 Operation successful */ GroupItems;
export type ApiGroupsCreateApiArg = {
  groupListItems: GroupListItems;
};
export type ApiGroupsUserAllListApiResponse =
  /** status 200 Operation successful */ ListGroupsResponseSuccess[];
export type ApiGroupsUserAllListApiArg = void;
export type ApiGroupsUserListApiResponse = /** status 200  */ PostGroupUser[];
export type ApiGroupsUserListApiArg = {
  groupId: string;
};
export type ApiGroupsUserCreateApiResponse = /** status 200 Operation successful */ PostGroupUser;
export type ApiGroupsUserCreateApiArg = {
  groupId: string;
  postGroupUser: PostGroupUser;
};
export type ApiGroupsUserReadApiResponse = /** status 200  */ GroupUser;
export type ApiGroupsUserReadApiArg = {
  groupId: string;
  userId: string;
};
export type ApiGroupsUserUpdateApiResponse = /** status 200 Operation successful */ PostGroupUser;
export type ApiGroupsUserUpdateApiArg = {
  groupId: string;
  userId: string;
  postGroupUser: PostGroupUser;
};
export type ApiGroupsUserDeleteApiResponse = unknown;
export type ApiGroupsUserDeleteApiArg = {
  groupId: string;
  userId: string;
};
export type ApiGroupsReadApiResponse =
  /** status 200 Operation successful */ ListGroupsResponseSuccess[];
export type ApiGroupsReadApiArg = {
  itemId: string;
};
export type ApiGroupsUpdateApiResponse = /** status 200 Operation successful */ GroupItems;
export type ApiGroupsUpdateApiArg = {
  itemId: string;
  groupItems: GroupItems;
};
export type ApiGroupsDeleteApiResponse = unknown;
export type ApiGroupsDeleteApiArg = {
  itemId: string;
};
export type ApiIndividualProgramsCourseTopicsListApiResponse =
  /** status 200  */ CourseTopics200Swagger[];
export type ApiIndividualProgramsCourseTopicsListApiArg = void;
export type ApiIndividualProgramsCourseTopicsRequestsListApiResponse =
  /** status 200  */ TopicRequest200Swagger[];
export type ApiIndividualProgramsCourseTopicsRequestsListApiArg = void;
export type ApiIndividualProgramsCourseTopicsRequestsCreateApiResponse =
  /** status 200 Operation successful */ IndividualProgramsResponseWithId200;
export type ApiIndividualProgramsCourseTopicsRequestsCreateApiArg = {
  body: {
    topic_ids?: string[];
    start_date?: string;
  };
};
export type ApiIndividualProgramsCourseTopicsRequestsNumberReadApiResponse =
  /** status 200  */ TopicRequest200Swagger;
export type ApiIndividualProgramsCourseTopicsRequestsNumberReadApiArg = {
  number: number;
};
export type ApiIndividualProgramsCourseTopicsRequestsUuidReadApiResponse =
  /** status 200  */ TopicRequest200Swagger;
export type ApiIndividualProgramsCourseTopicsRequestsUuidReadApiArg = {
  requestId: string;
};
export type ApiIndividualProgramsCoursesRequestsListApiResponse =
  /** status 200  */ CoursesRequest200Swagger[];
export type ApiIndividualProgramsCoursesRequestsListApiArg = void;
export type ApiIndividualProgramsCoursesRequestsCreateApiResponse =
  /** status 200 Operation successful */ IndividualProgramsResponseWithId200;
export type ApiIndividualProgramsCoursesRequestsCreateApiArg = {
  body: {
    course_id?: string;
    start_date?: string;
  }[];
};
export type ApiIndividualProgramsCoursesRequestsNumberReadApiResponse =
  /** status 200  */ CoursesRequest200Swagger;
export type ApiIndividualProgramsCoursesRequestsNumberReadApiArg = {
  number: number;
};
export type ApiIndividualProgramsCoursesRequestsToggleCreateApiResponse =
  /** status 200 Operation successful */ IndividualProgramsResponseWithId200;
export type ApiIndividualProgramsCoursesRequestsToggleCreateApiArg = {
  body: {
    request_item_id?: number;
    done?: boolean;
  };
};
export type ApiIndividualProgramsCoursesRequestsUuidReadApiResponse =
  /** status 200  */ CoursesRequest200Swagger;
export type ApiIndividualProgramsCoursesRequestsUuidReadApiArg = {
  requestId: string;
};
export type ApiLearnOnOurPlatformListApiResponse =
  /** status 200 Created requests by email */ LearnOnOurPlatform;
export type ApiLearnOnOurPlatformListApiArg = void;
export type ApiListCartListApiResponse = /** status 200  */ ListCart[];
export type ApiListCartListApiArg = void;
export type ApiListCartCreateApiResponse =
  /** status 200 Operation successful */ FavoriteCoursesResponseSuccess;
export type ApiListCartCreateApiArg = {
  body: string[];
};
export type ApiListFavoritesListApiResponse =
  /** status 200 Operation successful */ ListFavoriteCoursesResponseSuccess;
export type ApiListFavoritesListApiArg = void;
export type ApiListFavoritesCreateApiResponse =
  /** status 200 Operation successful */ FavoriteCoursesResponseSuccess;
export type ApiListFavoritesCreateApiArg = {
  body: string[];
};
export type GetApiMediaAboutUsApiResponse = /** status 200  */ {
  count: number;
  next?: string | null;
  previous?: string | null;
  results: MediaAboutUs[];
};
export type GetApiMediaAboutUsApiArg = {
  /** Number of results to return per page. */
  limit?: number;
  /** The initial index from which to return the results. */
  offset?: number;
};
export type GetApiMngmNewRecordsCountApiResponse = /** status 200  */ CntOfNewRecords[];
export type GetApiMngmNewRecordsCountApiArg = void;
export type ApiPartnersListApiResponse = /** status 200  */ OurPartners[];
export type ApiPartnersListApiArg = void;
export type ApiPayentServicesListApiResponse = /** status 200  */ PaymentServices[];
export type ApiPayentServicesListApiArg = void;
export type ApiPaymentSberpayCreateApiResponse = /** status 200  */ undefined;
export type ApiPaymentSberpayCreateApiArg = {
  sberPay: SberPay;
};
export type ApiPaymentYandexCreateApiResponse = /** status 200  */ undefined;
export type ApiPaymentYandexCreateApiArg = {
  yandexPay: YandexPay;
};
export type ApiPromoCodesListApiResponse = /** status 200  */ PromoCodeItems[];
export type ApiPromoCodesListApiArg = void;
export type ApiPromoCodesCheckReadApiResponse = /** status 200 Operation successful */ Response200;
export type ApiPromoCodesCheckReadApiArg = {
  code: string;
};
export type ApiPromoCodesUserListApiResponse = /** status 200  */ UserPromoCode[];
export type ApiPromoCodesUserListApiArg = void;
export type ApiPromoCodesUserCreateApiResponse = /** status 200 Operation successful */ Response200;
export type ApiPromoCodesUserCreateApiArg = {
  userPromoCodeBody: UserPromoCodeBody;
};
export type ApiPromoCodesUserActivateCreateApiResponse =
  /** status 200 Operation successful */ Response200;
export type ApiPromoCodesUserActivateCreateApiArg = {
  userPromoCodeBody: UserPromoCodeBody;
};
export type ApiReviewsMngmReviewedCoursesListApiResponse =
  /** status 200 Operation successful */ Management200ReviewedCourses[];
export type ApiReviewsMngmReviewedCoursesListApiArg = void;
export type ApiReviewsMngmTextListApiResponse = /** status 200  */ SwaggerMngmTextCourseReview;
export type ApiReviewsMngmTextListApiArg = {
  /** Which field to use when ordering the results. */
  ordering?: string;
  /** A search term. */
  search?: string;
  rate?: string;
  mainPage?: string;
  createdAtGte?: string;
  createdAtLte?: string;
  course?: string;
  /** Number of results to return per page. */
  limit?: number;
  /** The initial index from which to return the results. */
  offset?: number;
};
export type ApiReviewsMngmTextUpdateApiResponse = /** status 200  */ TextCourseReview;
export type ApiReviewsMngmTextUpdateApiArg = {
  reviewId: string;
  mngmUpdateTextCourseReview: MngmUpdateTextCourseReview;
};
export type ApiReviewsMngmTextDeleteApiResponse = /** status 204 Deleted */ undefined;
export type ApiReviewsMngmTextDeleteApiArg = {
  reviewId: string;
};
export type ApiReviewsMngmVideoListApiResponse = /** status 200  */ SwaggerMngmVideoCourseReview;
export type ApiReviewsMngmVideoListApiArg = {
  /** Which field to use when ordering the results. */
  ordering?: string;
  /** A search term. */
  search?: string;
  rate?: string;
  mainPage?: string;
  createdAtGte?: string;
  createdAtLte?: string;
  course?: string;
  /** Number of results to return per page. */
  limit?: number;
  /** The initial index from which to return the results. */
  offset?: number;
};
export type ApiReviewsMngmVideoUpdateApiResponse = /** status 200  */ VideoCourseReview;
export type ApiReviewsMngmVideoUpdateApiArg = {
  reviewId: string;
  mngmUpdateVideoCourseReview: MngmUpdateVideoCourseReview;
};
export type ApiReviewsMngmVideoDeleteApiResponse = /** status 204 Deleted */ undefined;
export type ApiReviewsMngmVideoDeleteApiArg = {
  reviewId: string;
};
export type ApiReviewsTextListApiResponse = /** status 200  */ TextCourseReview[];
export type ApiReviewsTextListApiArg = void;
export type ApiReviewsTextAddCreateApiResponse =
  /** status 200 Operation successful */ AddReviewResponse200;
export type ApiReviewsTextAddCreateApiArg = {
  textCourseReviewAddRequestBody: TextCourseReviewAddRequestBody;
};
export type ApiReviewsTextCategoryReadApiResponse = /** status 200  */ TextCourseReview[];
export type ApiReviewsTextCategoryReadApiArg = {
  categoryId: string;
};
export type ApiReviewsTextRemoveDeleteApiResponse =
  /** status 200 Operation successful */ AddReviewResponse200;
export type ApiReviewsTextRemoveDeleteApiArg = {
  reviewId: string;
};
export type ApiReviewsTextUserListApiResponse = /** status 200  */ TextCourseReview[];
export type ApiReviewsTextUserListApiArg = void;
export type ApiReviewsTextReadApiResponse = /** status 200  */ TextCourseReview[];
export type ApiReviewsTextReadApiArg = {
  courseId: string;
};
export type ApiReviewsVideoListApiResponse = /** status 200  */ VideoCourseReview[];
export type ApiReviewsVideoListApiArg = void;
export type ApiReviewsVideoAddCreateApiResponse =
  /** status 200 Operation successful */ AddReviewResponse200;
export type ApiReviewsVideoAddCreateApiArg = {
  videoCourseReviewAddRequestBody: VideoCourseReviewAddRequestBody;
};
export type ApiReviewsVideoCategoryReadApiResponse = /** status 200  */ VideoCourseReview[];
export type ApiReviewsVideoCategoryReadApiArg = {
  categoryId: string;
};
export type ApiReviewsVideoRemoveDeleteApiResponse =
  /** status 200 Operation successful */ AddReviewResponse200;
export type ApiReviewsVideoRemoveDeleteApiArg = {
  reviewId: string;
};
export type ApiReviewsVideoUserListApiResponse = /** status 200  */ VideoCourseReview[];
export type ApiReviewsVideoUserListApiArg = void;
export type ApiReviewsVideoReadApiResponse = /** status 200  */ VideoCourseReview[];
export type ApiReviewsVideoReadApiArg = {
  courseId: string;
};
export type ApiSpeakersListApiResponse =
  /** status 200 Operation successful */ ListSpeakersCoursesResponseSuccess;
export type ApiSpeakersListApiArg = void;
export type ApiSpeakersCreateApiResponse = /** status 201  */ Speaker;
export type ApiSpeakersCreateApiArg = {
  speaker: Speaker;
};
export type ApiStatisticAllFileListApiResponse = /** status 200 File Attachment */ Blob;
export type ApiStatisticAllFileListApiArg = {
  period: string;
};
export type ApiStatisticCoursesViewsReadApiResponse =
  /** status 200 Operation successful */ StatisticPeriodResponse200;
export type ApiStatisticCoursesViewsReadApiArg = {
  period: string;
};
export type ApiStatisticCoursesViewsFileListApiResponse = /** status 200 File Attachment */ Blob;
export type ApiStatisticCoursesViewsFileListApiArg = {
  period: string;
};
export type ApiStatisticRequestsByCoursesReadApiResponse =
  /** status 200 Operation successful */ StatisticPeriodResponse200;
export type ApiStatisticRequestsByCoursesReadApiArg = {
  period: string;
};
export type ApiStatisticRequestsByCoursesFileListApiResponse =
  /** status 200 File Attachment */ Blob;
export type ApiStatisticRequestsByCoursesFileListApiArg = {
  period: string;
};
export type ApiStatisticRequestsStatusesReadApiResponse =
  /** status 200 Operation successful */ StatisticPeriodResponse200;
export type ApiStatisticRequestsStatusesReadApiArg = {
  period: string;
};
export type ApiStatisticRequestsStatusesFileListApiResponse =
  /** status 200 File Attachment */ Blob;
export type ApiStatisticRequestsStatusesFileListApiArg = {
  period: string;
};
export type ApiStatisticSpeakersHoursReadApiResponse =
  /** status 200 Operation successful */ StatisticPeriodResponse200;
export type ApiStatisticSpeakersHoursReadApiArg = {
  period: string;
};
export type ApiStatisticSpeakersHoursFileListApiResponse = /** status 200 File Attachment */ Blob;
export type ApiStatisticSpeakersHoursFileListApiArg = {
  period: string;
};
export type ApiStatisticSpeakersHoursByCoursesReadApiResponse =
  /** status 200 Operation successful */ StatisticPeriodResponse200;
export type ApiStatisticSpeakersHoursByCoursesReadApiArg = {
  period: string;
};
export type ApiStatisticSpeakersHoursByCoursesFileListApiResponse =
  /** status 200 File Attachment */ Blob;
export type ApiStatisticSpeakersHoursByCoursesFileListApiArg = {
  period: string;
};
export type ApiStatisticUsersReadApiResponse =
  /** status 200 Operation successful */ StatisticPeriodResponse200;
export type ApiStatisticUsersReadApiArg = {
  period: string;
};
export type ApiStatisticUsersFileListApiResponse = /** status 200 File Attachment */ Blob;
export type ApiStatisticUsersFileListApiArg = {
  period: string;
};
export type ApiStatisticUsersCategoriesReadApiResponse =
  /** status 200 Operation successful */ StatisticPeriodResponse200;
export type ApiStatisticUsersCategoriesReadApiArg = {
  period: string;
};
export type ApiStatisticUsersCategoriesFileListApiResponse = /** status 200 File Attachment */ Blob;
export type ApiStatisticUsersCategoriesFileListApiArg = {
  period: string;
};
export type ApiStatisticUsersLocationsReadApiResponse =
  /** status 200 Operation successful */ StatisticPeriodResponse200;
export type ApiStatisticUsersLocationsReadApiArg = {
  period: string;
};
export type ApiStatisticUsersLocationsFileListApiResponse = /** status 200 File Attachment */ Blob;
export type ApiStatisticUsersLocationsFileListApiArg = {
  period: string;
};
export type ApiStatisticUsersProfessionsReadApiResponse =
  /** status 200 Operation successful */ StatisticPeriodResponse200;
export type ApiStatisticUsersProfessionsReadApiArg = {
  period: string;
};
export type ApiStatisticUsersProfessionsFileListApiResponse =
  /** status 200 File Attachment */ Blob;
export type ApiStatisticUsersProfessionsFileListApiArg = {
  period: string;
};
export type ApiStatisticUsersProfilesReadApiResponse =
  /** status 200 Operation successful */ StatisticPeriodResponse200;
export type ApiStatisticUsersProfilesReadApiArg = {
  period: string;
};
export type ApiStatisticUsersProfilesFileListApiResponse = /** status 200 File Attachment */ Blob;
export type ApiStatisticUsersProfilesFileListApiArg = {
  period: string;
};
export type ApiStatisticUsersTargetsGroupsReadApiResponse =
  /** status 200 Operation successful */ StatisticPeriodResponse200;
export type ApiStatisticUsersTargetsGroupsReadApiArg = {
  period: string;
};
export type ApiStatisticUsersTargetsGroupsFileListApiResponse =
  /** status 200 File Attachment */ Blob;
export type ApiStatisticUsersTargetsGroupsFileListApiArg = {
  period: string;
};
export type ApiTeachersListApiResponse = /** status 200  */ Teacher[];
export type ApiTeachersListApiArg = void;
export type ApiTeachersCreateApiResponse = /** status 200 Created Teacher */ PostTeacher;
export type ApiTeachersCreateApiArg = {
  postTeacher: PostTeacher;
};
export type ApiTeachersReadApiResponse = /** status 200  */ Teacher;
export type ApiTeachersReadApiArg = {
  teacherId: string;
};
export type ApiTeachersUpdateApiResponse = /** status 200 Created Teacher */ PostTeacher;
export type ApiTeachersUpdateApiArg = {
  teacherId: string;
  postTeacher: PostTeacher;
};
export type ApiTeachersDeleteApiResponse = unknown;
export type ApiTeachersDeleteApiArg = {
  teacherId: string;
};
export type GetApiTopSpeakersApiResponse = /** status 200  */ {
  count: number;
  next?: string | null;
  previous?: string | null;
  results: TopSpeakers[];
};
export type GetApiTopSpeakersApiArg = {
  /** Number of results to return per page. */
  limit?: number;
  /** The initial index from which to return the results. */
  offset?: number;
};
export type ApiTrainingDiagnosticsListApiResponse = /** status 200  */ DiagnosticItems[];
export type ApiTrainingDiagnosticsListApiArg = void;
export type ApiTrainingDiagnosticsUserListApiResponse = /** status 200  */ UserDiagnosticCards[];
export type ApiTrainingDiagnosticsUserListApiArg = void;
export type ApiUserCoursesListApiResponse = /** status 200  */ UserCourseStatuses[];
export type ApiUserCoursesListApiArg = void;
export type ApiUserCoursesAchievementsListApiResponse = /** status 200  */ UserCourseStatuses[];
export type ApiUserCoursesAchievementsListApiArg = void;
export type ApiUsersAddressesListApiResponse = /** status 200  */ UserAddresses;
export type ApiUsersAddressesListApiArg = void;
export type ApiUsersAddressesCreateApiResponse =
  /** status 200 Operation successful */ UsersResponseWithId200;
export type ApiUsersAddressesCreateApiArg = {
  userAddressesPost: UserAddressesPost;
};
export type ApiUsersAddressesUpdateApiResponse =
  /** status 200 Operation successful */ UsersResponse200;
export type ApiUsersAddressesUpdateApiArg = {
  userAddressesPost: UserAddressesPost;
};
export type ApiUsersAvatarUpdateApiResponse =
  /** status 200 Operation successful */ UsersResponse200;
export type ApiUsersAvatarUpdateApiArg = {
  body: {
    file?: Blob;
  };
};
export type ApiUsersChangeFioDocumentUpdateApiResponse =
  /** status 200 Operation successful */ UsersResponse200;
export type ApiUsersChangeFioDocumentUpdateApiArg = {
  body: {
    file?: Blob;
  };
};
export type ApiUsersDeleteDeleteApiResponse =
  /** status 205 Operation successful */ UsersResponse200;
export type ApiUsersDeleteDeleteApiArg = void;
export type ApiUsersDictionariesEducationDocumentsTypesListApiResponse =
  /** status 200  */ UserEducationDocumentTypes[];
export type ApiUsersDictionariesEducationDocumentsTypesListApiArg = void;
export type ApiUsersDocumentFlowListApiResponse = /** status 200  */ UserDocumentFlow[];
export type ApiUsersDocumentFlowListApiArg = void;
export type ApiUsersDocumentFlowCoursesListApiResponse = /** status 200  */ {
  id?: string;
  title?: string;
}[];
export type ApiUsersDocumentFlowCoursesListApiArg = void;
export type ApiUsersDocumentFlowSigningUpdateApiResponse =
  /** status 200 Operation successful */ UsersResponse200;
export type ApiUsersDocumentFlowSigningUpdateApiArg = {
  documentId: string;
};
export type ApiUsersDocumentFlowViewingUpdateApiResponse =
  /** status 200 Operation successful */ UsersResponse200;
export type ApiUsersDocumentFlowViewingUpdateApiArg = {
  documentId: string;
};
export type ApiUsersDocumentsCreateApiResponse = /** status 200  */ UserDocuments;
export type ApiUsersDocumentsCreateApiArg = {
  body: {
    file?: Blob;
    group_id?: string;
  };
};
export type ApiUsersDocumentsListListApiResponse = /** status 200  */ UserDocumentsResponse;
export type ApiUsersDocumentsListListApiArg = {
  /** Number of results to return per page. */
  limit?: number;
  /** The initial index from which to return the results. */
  offset?: number;
};
export type ApiUsersDocumentsRecognitionAddressDocumentUpdateApiResponse =
  /** status 200 Operation successful */ UsersResponseWithData200;
export type ApiUsersDocumentsRecognitionAddressDocumentUpdateApiArg = {
  body: {
    file?: Blob;
  };
};
export type ApiUsersDocumentsRecognitionEducationDocumentUpdateApiResponse =
  /** status 200 Operation successful */ UsersResponseWithData200;
export type ApiUsersDocumentsRecognitionEducationDocumentUpdateApiArg = {
  body: {
    file?: Blob;
  };
};
export type ApiUsersDocumentsRecognitionPassportUpdateApiResponse =
  /** status 200 Operation successful */ UsersResponseWithData200;
export type ApiUsersDocumentsRecognitionPassportUpdateApiArg = {
  body: {
    file?: Blob;
  };
};
export type ApiUsersDocumentsRecognitionWorkBookUpdateApiResponse =
  /** status 200 Operation successful */ UsersResponseWithData200;
export type ApiUsersDocumentsRecognitionWorkBookUpdateApiArg = {
  body: {
    file?: Blob;
  };
};
export type ApiUsersDocumentsViewCreateApiResponse =
  /** status 200 User Documents view success created */ UsersDocumentsView200;
export type ApiUsersDocumentsViewCreateApiArg = {
  userDocumentsViews: UserDocumentsViews;
};
export type ApiUsersEducationDocumentsListApiResponse = /** status 200  */ UserEducationDocuments[];
export type ApiUsersEducationDocumentsListApiArg = void;
export type ApiUsersEducationDocumentsCreateApiResponse =
  /** status 200 Operation successful */ UsersResponseWithId200;
export type ApiUsersEducationDocumentsCreateApiArg = {
  userEducationDocuments: UserEducationDocuments;
};
export type ApiUsersEducationDocumentsScanUpdateApiResponse =
  /** status 200 Operation successful */ UsersResponse200;
export type ApiUsersEducationDocumentsScanUpdateApiArg = {
  documentId: string;
  body: {
    file?: Blob;
  };
};
export type ApiUsersEducationDocumentsReadApiResponse = /** status 200  */ UserEducationDocuments;
export type ApiUsersEducationDocumentsReadApiArg = {
  documentId: string;
};
export type ApiUsersEducationDocumentsUpdateApiResponse =
  /** status 200 Operation successful */ UsersResponse200;
export type ApiUsersEducationDocumentsUpdateApiArg = {
  documentId: string;
  userEducationDocuments: UserEducationDocuments;
};
export type ApiUsersEducationDocumentsDeleteApiResponse = unknown;
export type ApiUsersEducationDocumentsDeleteApiArg = {
  documentId: string;
};
export type ApiUsersEmploymentCenterListApiResponse = /** status 200  */ UserEmploymentCenter;
export type ApiUsersEmploymentCenterListApiArg = void;
export type ApiUsersEmploymentCenterCreateApiResponse =
  /** status 200 Operation successful */ UsersResponseWithId200;
export type ApiUsersEmploymentCenterCreateApiArg = {
  updateUserEmploymentCenter: UpdateUserEmploymentCenter;
};
export type ApiUsersEmploymentCenterUpdateApiResponse =
  /** status 200 Operation successful */ UsersResponse200;
export type ApiUsersEmploymentCenterUpdateApiArg = {
  updateUserEmploymentCenter: UpdateUserEmploymentCenter;
};
export type ApiUsersEmploymentCenterDeleteApiResponse =
  /** status 200 Operation successful */ UsersResponse200;
export type ApiUsersEmploymentCenterDeleteApiArg = void;
export type ApiUsersEmploymentCenterScanUpdateApiResponse =
  /** status 200 Operation successful */ UsersResponse200;
export type ApiUsersEmploymentCenterScanUpdateApiArg = {
  body: {
    file?: Blob;
  };
};
export type ApiUsersFamilyStatusesListApiResponse = /** status 200  */ {
  id?: number;
  title?: string;
}[];
export type ApiUsersFamilyStatusesListApiArg = void;
export type ApiUsersGendersListApiResponse = /** status 200  */ {
  id?: number;
  title?: string;
}[];
export type ApiUsersGendersListApiArg = void;
export type ApiUsersIdentityDocumentsListApiResponse = /** status 200  */ UserIdentityDocuments[];
export type ApiUsersIdentityDocumentsListApiArg = void;
export type ApiUsersIdentityDocumentsCreateApiResponse =
  /** status 200 Operation successful */ UsersResponseWithId200;
export type ApiUsersIdentityDocumentsCreateApiArg = {
  userIdentityDocuments: UserIdentityDocuments;
};
export type ApiUsersIdentityDocumentsScanUpdateApiResponse =
  /** status 200 Operation successful */ UsersResponse200;
export type ApiUsersIdentityDocumentsScanUpdateApiArg = {
  documentId: string;
  body: {
    file?: Blob;
  };
};
export type ApiUsersIdentityDocumentsScanCvUpdateApiResponse =
  /** status 200 Operation successful */ UsersResponseWithData200;
export type ApiUsersIdentityDocumentsScanCvUpdateApiArg = {
  body: {
    file?: Blob;
  };
};
export type ApiUsersIdentityDocumentsReadApiResponse = /** status 200  */ UserIdentityDocuments;
export type ApiUsersIdentityDocumentsReadApiArg = {
  documentId: string;
};
export type ApiUsersIdentityDocumentsUpdateApiResponse =
  /** status 200 Operation successful */ UsersResponse200;
export type ApiUsersIdentityDocumentsUpdateApiArg = {
  documentId: string;
  userIdentityDocuments: UserIdentityDocuments;
};
export type ApiUsersIdentityDocumentsDeleteApiResponse =
  /** status 200 Operation successful */ UsersResponse200;
export type ApiUsersIdentityDocumentsDeleteApiArg = {
  documentId: string;
};
export type ApiUsersLoginCreateApiResponse =
  /** status 200 Operation successful */ UsersLoginResponse200;
export type ApiUsersLoginCreateApiArg = {
  userTokenObtainPair: UserTokenObtainPair;
};
export type ApiUsersLoginAdCreateApiResponse =
  /** status 200 Operation successful */ UsersLoginResponse200;
export type ApiUsersLoginAdCreateApiArg = {
  body: {
    token?: string;
  };
};
export type ApiUsersLoginGuCreateApiResponse =
  /** status 200 Operation successful */ UsersLoginResponse200;
export type ApiUsersLoginGuCreateApiArg = {
  body: {
    token?: string;
  };
};
export type ApiUsersLoginRefreshCreateApiResponse =
  /** status 200 Operation successful */ UsersTokenRefreshResponse200;
export type ApiUsersLoginRefreshCreateApiArg = {
  userTokenRefresh: UserTokenRefresh;
};
export type ApiUsersLoginVkCreateApiResponse =
  /** status 200 Operation successful */ UsersLoginOAuthResponse200;
export type ApiUsersLoginVkCreateApiArg = {
  body: {
    token?: string;
  };
};
export type ApiUsersLoginYaCreateApiResponse =
  /** status 200 Operation successful */ UsersLoginOAuthResponse200;
export type ApiUsersLoginYaCreateApiArg = {
  body: {
    token?: string;
  };
};
export type ApiUsersLogoutCreateApiResponse = unknown;
export type ApiUsersLogoutCreateApiArg = {
  body: {
    refresh_token?: string;
  };
};
export type ApiUsersProfileReadApiResponse = /** status 200  */ UserInfo;
export type ApiUsersProfileReadApiArg = void;
export type ApiUsersProfileUpdateApiResponse =
  /** status 200 Operation successful */ UsersResponse200;
export type ApiUsersProfileUpdateApiArg = {
  updateUserProfile: UpdateUserProfile;
};
export type ApiUsersProfileDeleteApiResponse = unknown;
export type ApiUsersProfileDeleteApiArg = void;
export type ApiUsersRegisterCreateApiResponse =
  /** status 200 Operation successful */ UsersResponse200;
export type ApiUsersRegisterCreateApiArg = {
  userRegistration: UserRegistration;
};
export type ApiUsersWorkBookListApiResponse = /** status 200  */ UserWorkBook[];
export type ApiUsersWorkBookListApiArg = void;
export type ApiUsersWorkBookCreateApiResponse =
  /** status 200 Operation successful */ UsersResponseWithId200;
export type ApiUsersWorkBookCreateApiArg = {
  userWorkBook: UserWorkBook;
};
export type ApiUsersWorkBookUploadScanListApiResponse = /** status 200  */ UserWorkBookScan;
export type ApiUsersWorkBookUploadScanListApiArg = void;
export type ApiUsersWorkBookUploadScanUpdateApiResponse =
  /** status 200 Operation successful */ UsersResponse200;
export type ApiUsersWorkBookUploadScanUpdateApiArg = {
  body: {
    file?: Blob;
  };
};
export type ApiUsersWorkBookUploadScanDeleteApiResponse =
  /** status 200 Operation successful */ UsersResponse200;
export type ApiUsersWorkBookUploadScanDeleteApiArg = void;
export type ApiUsersWorkBookReadApiResponse = /** status 200  */ UserWorkBook;
export type ApiUsersWorkBookReadApiArg = {
  documentId: string;
};
export type ApiUsersWorkBookUpdateApiResponse =
  /** status 200 Operation successful */ UsersResponse200;
export type ApiUsersWorkBookUpdateApiArg = {
  documentId: string;
  userWorkBook: UserWorkBook;
};
export type ApiUsersWorkBookDeleteApiResponse = unknown;
export type ApiUsersWorkBookDeleteApiArg = {
  documentId: string;
};
export type ExternalApiCoursesListApiResponse = /** status 200  */ Course[];
export type ExternalApiCoursesListApiArg = {
  /** API ключ доступа */
  'X-Api-Key'?: string;
};
export type ExternalApiCoursesReadApiResponse = /** status 200  */ Course;
export type ExternalApiCoursesReadApiArg = {
  courseId: string;
  /** API ключ доступа */
  'X-Api-Key'?: string;
};
export type ExternalApiFaqListApiResponse = /** status 200  */ FaqListItems[];
export type ExternalApiFaqListApiArg = {
  /** API ключ доступа */
  'X-Api-Key'?: string;
};
export type ExternalApiListRequestsListApiResponse =
  /** status 200 Created requests by email */ ExternalApiListOfCourseRequests[];
export type ExternalApiListRequestsListApiArg = {
  /** API ключ доступа */
  'X-Api-Key'?: string;
};
export type ExternalApiPartnersListApiResponse = /** status 200  */ OurPartners[];
export type ExternalApiPartnersListApiArg = {
  /** API ключ доступа */
  'X-Api-Key'?: string;
};
export type ExternalApiPromoCodesListApiResponse = /** status 200  */ PromoCodeItems[];
export type ExternalApiPromoCodesListApiArg = {
  /** API ключ доступа */
  'X-Api-Key'?: string;
};
export type ExternalApiRecognizeDocumentCreateApiResponse = /** status 201  */ PostSendRecognize;
export type ExternalApiRecognizeDocumentCreateApiArg = {
  /** API ключ доступа */
  'X-Api-Key'?: string;
  postSendRecognize: PostSendRecognize;
};
export type ExternalApiReviewsTextListApiResponse = /** status 200  */ TextCourseReview[];
export type ExternalApiReviewsTextListApiArg = {
  /** API ключ доступа */
  'X-Api-Key'?: string;
};
export type ExternalApiReviewsVideoListApiResponse = /** status 200  */ VideoCourseReview[];
export type ExternalApiReviewsVideoListApiArg = {
  /** API ключ доступа */
  'X-Api-Key'?: string;
};
export type ExternalApiSendDocumentsCreateApiResponse = /** status 201  */ PostSendDocuments;
export type ExternalApiSendDocumentsCreateApiArg = {
  /** API ключ доступа */
  'X-Api-Key'?: string;
  postSendDocuments: PostSendDocuments;
};
export type ExternalApiSpeakersListApiResponse = /** status 200  */ Speaker[];
export type ExternalApiSpeakersListApiArg = {
  /** API ключ доступа */
  'X-Api-Key'?: string;
};
export type ExternalApiStatisticCoursesViewsReadApiResponse =
  /** status 200 Operation successful */ StatisticPeriodResponse200;
export type ExternalApiStatisticCoursesViewsReadApiArg = {
  period: string;
  /** API ключ доступа */
  'X-Api-Key'?: string;
};
export type ExternalApiStatisticRequestsStatusesReadApiResponse =
  /** status 200 Operation successful */ StatisticPeriodResponse200;
export type ExternalApiStatisticRequestsStatusesReadApiArg = {
  period: string;
  /** API ключ доступа */
  'X-Api-Key'?: string;
};
export type ExternalApiStatisticUsersTargetsGroupsReadApiResponse =
  /** status 200 Operation successful */ StatisticPeriodResponse200;
export type ExternalApiStatisticUsersTargetsGroupsReadApiArg = {
  period: string;
  /** API ключ доступа */
  'X-Api-Key'?: string;
};
export type ExternalApiTargetGroupsListApiResponse = /** status 200  */ TargetGroup[];
export type ExternalApiTargetGroupsListApiArg = {
  /** API ключ доступа */
  'X-Api-Key'?: string;
};
export type ExternalApiUsersEmploymentCenterScanReadApiResponse = unknown;
export type ExternalApiUsersEmploymentCenterScanReadApiArg = {
  userEmail: string;
  /** API ключ доступа */
  'X-Api-Key'?: string;
};
export type ExternalApiUsersEmploymentCenterScanCreateApiResponse =
  /** status 200 Operation successful */ UsersResponseWithId200;
export type ExternalApiUsersEmploymentCenterScanCreateApiArg = {
  userEmail: string;
  /** API ключ доступа */
  'X-Api-Key'?: string;
  body: {
    file?: Blob;
    target_group?: string;
  };
};
export type ExternalApiUsersReadApiResponse = /** status 200  */ GetUserByEmail;
export type ExternalApiUsersReadApiArg = {
  email: string;
  /** API ключ доступа */
  'X-Api-Key'?: string;
};
export type UserAnalytics200 = {
  user_profile_filled: boolean;
  user_profile: boolean;
  user_identity_documents: boolean;
  user_address: boolean;
  user_education_documents: boolean;
  user_workbooks: boolean;
  user_employment_center: boolean;
  user_scans: boolean;
  target_groups_matched: boolean;
};
export type FavoriteCoursesResponseSuccess = {
  success: boolean;
  status_code: number;
  detail: string;
};
export type FavoriteCoursesResponseNotFound = {
  detail: string;
};
export type MainPageCertificates = {
  id?: number;
  title: string;
  description?: string;
  image_url?: string | null;
  cropped_image?: string | null;
};
export type UserProfileWithUser = {
  user: string;
  first_name: string;
  last_name: string;
  middle_name?: string | null;
  phone?: string | null;
  region: number | null;
  avatar_url?: string | null;
  email?: string | null;
};
export type Responsible = {
  id?: string;
  user: UserProfileWithUser;
  role?: 1 | 2;
};
export type Company = {
  id?: string;
  responsible: Responsible[];
  title: string;
  title_full?: string | null;
  cropped_image?: string | null;
  ogrn: string;
  kpp: string;
  founders?: string[];
  sort_index?: number;
  is_available?: boolean;
  created?: string;
  admin: string;
};
export type PostCompany = {
  id?: string;
  title: string;
  title_full?: string | null;
  cropped_image?: string | null;
  ogrn: string;
  kpp: string;
  founders?: string[];
  sort_index?: number;
  is_available?: boolean;
  created?: string;
  admin: string;
  responsible?: string[];
};
export type SwaggerResponse404 = {
  detail: string;
};
export type PostResponsible = {
  id?: string;
  role?: 1 | 2;
  user?: string | null;
};
export type ConformityUserCoursesRequestBody = {
  user_id: string;
  course_ids: string[];
};
export type ConformityUserCoursesByEmailRequestBody = {
  user_email: string;
  course_ids: string[];
};
export type CoursePlatform = {
  id?: number;
  title: string;
  logo_url?: string | null;
  logo?: string | null;
  platform_logo?: string | null;
};
export type CourseForRequest = {
  id?: string;
  title: string;
  partner_provider: CoursePlatform;
};
export type CourseRequestProfile = {
  id?: string;
  title: string;
};
export type CourseRequest = {
  id?: number;
  course: CourseForRequest;
  country: number | null;
  region: number | null;
  city: string | null;
  profile: CourseRequestProfile;
  first_name?: string | null;
  last_name?: string | null;
  middle_name?: string | null;
  email: string;
  phone?: string | null;
  status: 1 | 2 | 3 | 4;
  created_at?: string;
  updated_at?: string;
  work_book_scan?: string | null;
  passport_scan?: string | null;
  promo_code?: string | null;
  data_processing_consent?: boolean;
  familiarized_with_the_policy?: boolean;
  consent_to_inform?: boolean;
};
export type CourseRequestsResponse404 = {
  success: boolean;
  status_code: number;
  message: string;
};
export type CourseRequestsResponse200 = {
  success: boolean;
  status_code: number;
  message: string;
};
export type CourseRequestsResponse400 = {
  success: boolean;
  status_code: number;
  message: string;
};
export type CourseRequestsUpdateBody = {
  email: string;
  status: 1 | 2 | 3 | 4;
  course_ids: string;
  country_id: number;
  region_id?: number | null;
  city?: string | null;
  first_name: string;
  last_name: string;
  phone: string;
  user_comment?: string | null;
  promo_code?: string | null;
  profile_id?: string | null;
  data_processing_consent?: boolean | null;
  familiarized_with_the_policy?: boolean | null;
  consent_to_inform?: boolean | null;
};
export type CourseRequestsResponse201WithIDs = {
  success: boolean;
  status_code: number;
  message: string;
  ids: number[];
};
export type Resp404 = {
  detail: string;
};
export type CourseRequestsPostBody = {
  email: string;
  status: 1 | 2 | 3 | 4;
  course_ids: string[];
  country_id: number;
  region_id?: number | null;
  city?: string | null;
  first_name: string;
  last_name: string;
  phone: string;
  user_comment?: string | null;
  work_book_scan?: string | null;
  passport_scan?: string | null;
  promo_code?: string | null;
  profile_id?: string | null;
  data_processing_consent?: boolean | null;
  familiarized_with_the_policy?: boolean | null;
  consent_to_inform?: boolean | null;
};
export type CourseRequestMngm = {
  id?: number;
  course: CourseForRequest;
  country: number | null;
  region: number | null;
  city: string | null;
  profile: CourseRequestProfile;
  first_name?: string | null;
  last_name?: string | null;
  middle_name?: string | null;
  email: string;
  phone?: string | null;
  status: 1 | 2 | 3 | 4;
  created_at?: string;
  updated_at?: string;
  work_book_scan?: string | null;
  passport_scan?: string | null;
  promo_code?: string | null;
  data_processing_consent?: boolean;
  familiarized_with_the_policy?: boolean;
  consent_to_inform?: boolean;
};
export type ReviewCourseRequest = {
  status: 1 | 2 | 3 | 4;
};
export type CourseRequestsResponse200UploadScan = {
  success: boolean;
  status_code: number;
  message: string;
  file: string;
};
export type GetCourseRequestComment = {
  id?: string;
  creator: UserProfileWithUser;
  text?: string | null;
  created_at?: string;
};
export type CourseRequestComment = {
  id?: string;
  text?: string | null;
};
export type CourseRequestServiceComment = {
  id?: string;
  text?: string | null;
};
export type CourseFit = {
  id?: string;
  key: string;
  value: string;
};
export type CourseAtmosphereImages = {
  cropped_image?: string | null;
};
export type CourseAtmosphereCard = {
  card_title: string;
  card_description: string;
  images: CourseAtmosphereImages[];
};
export type Speaker = {
  id?: string;
  fio: string;
  job_title: string;
  image_url?: string | null;
  cropped_image?: string | null;
  big_image_url?: string | null;
  cropped_big_image?: string | null;
  status?: 1 | 2;
};
export type CourseResult = {
  id?: string;
  title: string;
};
export type CourseContent = {
  id?: string;
  title: string;
  description: string;
  theory_count: number;
  theory_time: number;
  webinar_count: number;
  webinar_time: number;
  test_count: number;
  test_level?: 1 | 2;
};
export type NeuralImage = {
  image_url: string;
};
export type StockImage = {
  image_url: string;
};
export type CourseIntroduction = {
  id?: string;
  title: string;
  description: string;
  video_url: string;
  image_url?: string | null;
  preview_url?: string | null;
  course: string;
};
export type CourseCompanyRecommend = {
  id?: string;
  title: string;
  description: string;
  position: string;
  image_url?: string | null;
};
export type Profile = {
  id?: string;
  title: string;
};
export type CourseEduRequirement = {
  id?: number;
  title: string;
};
export type CourseFormat = {
  id?: number;
  title: string;
};
export type CourseRegion = {
  id?: number;
  title: string;
};
export type TargetGroup = {
  id?: string;
  title: string;
  description: string;
  imageSrc?: string | null;
  image_url?: string | null;
  isAvailable?: boolean;
  created?: string;
  order_by?: number;
  general_group?: boolean;
};
export type CourseFieldActivity = {
  id?: number;
  title: string;
};
export type CourseCertificate = {
  id?: number;
  title: string;
  description?: string;
  image_url?: string | null;
  cropped_image?: string | null;
};
export type CoursePeriod = {
  id?: number;
  title: string;
};
export type CourseCategory = {
  id?: number;
  title: string;
};
export type CourseUniversity = {
  id?: number;
  title: string;
  title_full?: string | null;
};
export type CourseSubject = {
  id?: number;
  title: string;
};
export type CourseJobAvailable = {
  id?: number;
  title: string;
  description: string;
  experience?: 1 | 2;
  employment_type: string;
  image_url?: string | null;
};
export type FaqCourseItem = {
  id?: string;
  question: string;
  answer: string;
  sort_index: number;
  main_page?: boolean;
  is_available?: boolean;
  created?: string;
  category?: number | null;
};
export type TextCourseReview = {
  id?: string;
  user_name: string;
  user_profile?: UserProfileWithUser;
  title: string;
  course: string;
  description?: string;
  image_url?: string | null;
  cropped_image?: string | null;
  rate?: 1 | 2 | 3 | 4 | 5;
  is_available?: boolean;
  is_accepted?: boolean;
  is_deleted?: boolean;
  created_at?: string;
};
export type VideoCourseReview = {
  id?: string;
  user_name: string;
  user_profile: UserProfileWithUser;
  title: string;
  course: string;
  video_url?: string | null;
  video_image_url?: string | null;
  video_cropped_image?: string | null;
  rate?: 1 | 2 | 3 | 4 | 5;
  created_at?: string;
};
export type ListProfessions = {
  id?: number;
  title: string;
};
export type ListSkills = {
  id?: number;
  title: string;
};
export type RequiredDocuments = {
  id?: number;
  title: string;
};
export type Course = {
  id: string;
  fits: CourseFit[];
  atmosfere_card: CourseAtmosphereCard;
  speakers: Speaker[];
  results: CourseResult[];
  contents: CourseContent[];
  neural_image: NeuralImage;
  stock_image: StockImage;
  introduction: CourseIntroduction;
  company_recommend: CourseCompanyRecommend;
  profile: Profile[];
  edu_requirement: CourseEduRequirement;
  format: CourseFormat[];
  regions: CourseRegion[];
  groups: TargetGroup[];
  partner_provider: CoursePlatform;
  areas: CourseFieldActivity[];
  certificates: CourseCertificate[];
  periods: CoursePeriod;
  categories: CourseCategory[];
  university: CourseUniversity;
  subject: CourseSubject;
  jobs_available: CourseJobAvailable[];
  faq: FaqCourseItem[];
  reviews_text: TextCourseReview[];
  reviews_video: VideoCourseReview[];
  professions: ListProfessions[];
  skills: ListSkills[];
  supervisors: UserProfileWithUser[];
  curators: UserProfileWithUser[];
  required_documents: RequiredDocuments[];
  title: string;
  description_long?: string;
  description?: string;
  image_url?: string | null;
  cropped_image?: string | null;
  rate?: (1 | 2 | 3 | 4 | 5) | null;
  rate_count?: number | null;
  is_favorite?: boolean;
  is_free?: boolean;
  allowed_age?: number | null;
  price?: number | null;
  difficulty?: (1 | 2 | 3 | 4 | 5) | null;
  profession: string;
  hours: number;
  modules_count?: number | null;
  has_refund_quotas?: boolean;
  score?: number | null;
  test_count?: number | null;
  avg_salary?: number | null;
  start_date: string | null;
  end_date: string | null;
  is_available?: boolean;
  created?: string;
  novelty_date?: string;
  application_deadline?: string | null;
  enrollment_period?: string | null;
  available_cnt?: number | null;
};
export type ListCourseFilters = {
  id?: number;
  category_filters: object;
};
export type ListRecomendationCoursesResponseSuccess = {
  courses: Course[];
};
export type CourseSendToMail = {
  id?: number;
  mail: string;
  courses_ids: object;
};
export type CourseUploadError = {
  detail: string;
};
export type CourseSchedule = {
  course_title: string;
  topic: string;
  fio?: string | null;
  place: string;
  start: string;
  end: string;
};
export type ListCity = {
  id?: number;
  title: string;
  region: number;
};
export type DictionariesResponse200ListCities = {
  id: number;
  title: string;
  region: number;
};
export type DictionariesResponse404 = {
  success: boolean;
  status_code: number;
  message: string;
};
export type ListCountry = {
  id?: number;
  title: string;
};
export type ListRegion = {
  id?: number;
  title: string;
  country: number;
};
export type DictionariesResponse200ListRegions = {
  id: number;
  title: string;
  country: number;
};
export type CoursePlatformFilter = {
  id?: number;
  title: string;
};
export type EmploymentCenterDocumentTypesSwagger = {
  doc_type: 'ATTENDANCE' | 'GRADE' | '5' | '6' | '12' | '31' | '41' | '42' | '43';
  name:
    | 'attendance'
    | 'grade'
    | 'expulsion_order'
    | 'admission_order'
    | 'completion_certificate'
    | 'services_order'
    | 'individual_plan'
    | 'admission_order_extra'
    | 'expulsion_order_extra';
};
export type FaqCategories = {
  id?: number;
  title: string;
};
export type FaqItem = {
  id?: string;
  question: string;
  answer: string;
  sort_index: number;
  main_page?: boolean;
  is_available?: boolean;
  created?: string;
  category?: number | null;
};
export type FaqListItems = {
  id?: string;
  question: string;
  answer: string;
  category: string;
  sort_index: number;
  created: string;
  main_page?: boolean;
  is_available?: boolean;
};
export type UserFileUpload = {
  file?: string;
};
export type MainBanner = {
  id?: string;
  title: string;
  subtitle: string;
  key_title: string;
  url?: string | null;
  image_logo?: string | null;
};
export type TopMenuItems = {
  id?: number;
  title: string;
  label: string;
  ordering?: number;
  top_menu: string;
};
export type TopMenus = {
  id?: string;
  items: TopMenuItems[];
  title: string;
  default?: boolean;
  is_staff?: boolean;
  user_role?: string | null;
};
export type GroupUser = {
  id?: number;
  role?: string;
  user: UserProfileWithUser;
  entered?: string;
  group_rating?: number;
  group: string;
};
export type ListGroupsResponseSuccess = {
  id?: string;
  users: GroupUser[];
  title: string;
  direction: string;
  description?: string | null;
  cropped_image?: string | null;
  group_rating?: number;
  sort_index?: number;
  is_available?: boolean;
  created?: string;
};
export type GroupItems = {
  id?: string;
  title: string;
  direction: string;
  description?: string | null;
  cropped_image?: string | null;
  group_rating?: number;
  sort_index?: number;
  is_available?: boolean;
  created?: string;
};
export type Error404 = {
  detail: string;
};
export type GroupListItems = {
  id?: string;
  users?: string;
  title: string;
  direction: string;
  description?: string | null;
  cropped_image?: string | null;
  group_rating?: number;
  sort_index?: number;
  is_available?: boolean;
  created?: string;
};
export type PostGroupUser = {
  id?: number;
  role?: 1 | 2;
  entered?: string;
  group_rating?: number;
  group: string;
  user: string;
};
export type CourseSwagger = {
  id: string;
  title: string;
};
export type CourseTopics200Swagger = {
  id: string;
  course: CourseSwagger;
  order_by: number;
  title: string;
  description: string;
  theory_count: number;
  theory_time: number;
  webinar_count: number;
  webinar_time: number;
  test_count: number;
  test_level: number;
};
export type TopicRequest200Swagger = {
  id: string;
  number: number;
  status: 1 | 2 | 3 | 4;
  start_date: string;
  created_at: string;
  updated_at: string;
  user: string;
  course_topics: string[];
};
export type IndividualProgramsResponseWithId200 = {
  success: boolean;
  status_code: number;
  id: string;
  number: number;
  message: string;
};
export type IndividualProgramsResponse400 = {
  success: boolean;
  status_code: number;
  message: string;
};
export type CoursesRequest200Swagger = {
  id: string;
  number: number;
  status: 1 | 2 | 3 | 4;
  created_at: string;
  updated_at: string;
  user: string;
  courses: CourseSwagger[];
};
export type LearnSections = {
  id?: number;
  ordering?: number;
  title: string;
  description: string;
  inactive?: boolean;
  learn: string;
};
export type LearnOnOurPlatform = {
  id?: string;
  sections: LearnSections[];
  title?: string;
  image_url?: string | null;
  cropped_image?: string | null;
};
export type ListCart = {
  course: Course;
  status?: string;
};
export type ListFavoriteCoursesResponseSuccess = {
  courses: Course[];
};
export type MediaAboutUs = {
  title: string;
  description: string;
  link: string;
  image?: string;
};
export type CntOfNewRecords = {
  key: string;
  value: number;
};
export type OurPartners = {
  title: string;
  link: string;
  image?: string;
};
export type PaymentServices = {
  id?: string;
  ordering?: number;
  title: string;
  image_logo?: string | null;
  url: string;
};
export type PaymentErrorSwagger = {
  detail: string;
};
export type SberPayItem = {
  position_id: string;
  name: string;
};
export type SberPay = {
  amount: number;
  order_bundle: SberPayItem[];
};
export type YandexPayItems = {
  productId: string;
  total: string;
  title: string;
  quantity: number;
};
export type YandexPay = {
  items: YandexPayItems[];
  total: string;
};
export type PromoCodeItems = {
  id?: string;
  code: string;
  description?: string | null;
  cropped_image?: string | null;
  is_available?: boolean;
  created?: string;
  date_start: string;
  date_end: string;
};
export type Response200 = {
  success: boolean;
  status_code: number;
  message: string;
};
export type Response404Custom = {
  success?: boolean;
  status_code: number;
  message: string;
};
export type UserPromoCode = {
  id?: number;
  code: PromoCodeItems;
  added?: string;
  activated?: string | null;
};
export type ResponseNotFound = {
  detail: string;
};
export type UserPromoCodeBody = {
  code: string;
};
export type Response409 = {
  detail: string;
  code: string;
  messages: string;
};
export type Management200ReviewedCourses = {
  id: string;
  title: string;
};
export type ReviewResponse400Custom = {
  success: boolean;
  status_code: number;
  message: string;
};
export type MngmTextCourseReview = {
  id?: string;
  user_profile: UserProfileWithUser;
  user_name: string;
  title: string;
  main_page?: boolean;
  description?: string;
  image_url?: string | null;
  cropped_image?: string | null;
  is_available?: boolean;
  is_accepted?: boolean;
  is_deleted?: boolean;
  rate?: 1 | 2 | 3 | 4 | 5;
  created_at?: string;
  course: string;
  course_category?: number | null;
};
export type SwaggerMngmTextCourseReview = {
  count: number;
  next: string;
  previous: string;
  results: MngmTextCourseReview[];
};
export type Response404Swagger = {
  detail: string;
};
export type MngmUpdateTextCourseReview = {
  main_page?: boolean;
};
export type MngmVideoCourseReview = {
  id?: string;
  user_profile: UserProfileWithUser;
  user_name: string;
  title: string;
  main_page?: boolean;
  video_url?: string | null;
  video_image_url?: string | null;
  video_cropped_image?: string | null;
  is_available?: boolean;
  rate?: 1 | 2 | 3 | 4 | 5;
  created_at?: string;
  course: string;
  course_category?: number | null;
};
export type SwaggerMngmVideoCourseReview = {
  count: number;
  next: string;
  previous: string;
  results: MngmVideoCourseReview[];
};
export type MngmUpdateVideoCourseReview = {
  main_page?: boolean;
};
export type AddReviewResponse200 = {
  success: boolean;
  status_code: number;
  id: string;
  message: string;
};
export type TextCourseReviewAddRequestBody = {
  user_name: string;
  user_profile?: string;
  title: string;
  course: string;
  description: string;
  rate: number;
};
export type VideoCourseReviewAddRequestBody = {
  user_name: string;
  user_profile?: string;
  title: string;
  course: string;
  rate: number;
  video_url: string;
};
export type ListSpeakersCoursesResponseSuccess = {
  speakers: Speaker[];
};
export type StatisticData = {
  id?: number;
  names: object;
  values: object;
};
export type StatisticPeriodResponse200 = {
  success: boolean;
  status_code: number;
  data: StatisticData;
};
export type Teacher = {
  id?: string;
  user: UserProfileWithUser;
  course: string;
  is_available?: boolean;
  created?: string;
  user_profile?: string | null;
};
export type PostTeacher = {
  id?: string;
  is_available?: boolean;
  created?: string;
  user_profile?: string | null;
  course?: string | null;
};
export type Response404 = {
  detail: string;
};
export type CourseForTopSpeakers = {
  id?: string;
  title: string;
};
export type TopSpeakers = {
  title: string;
  description: string;
  course_cnt: number;
  image?: string | null;
  courses: CourseForTopSpeakers[];
};
export type DiagnosticContents = {
  id?: string;
  title: string;
};
export type DiagnosticItems = {
  id?: string;
  contents: DiagnosticContents[];
  title: string;
  description?: string | null;
  url: string;
  cropped_image?: string | null;
  sort_index?: number;
  is_available?: boolean;
  created?: string;
};
export type UserDiagnosticCards = {
  id?: number;
  diagnostic_card: DiagnosticItems;
  progress?: number;
  user: string;
};
export type UserRole = {
  id?: string;
  title: string;
  control_access?: boolean;
};
export type UserProfile = {
  user: string;
  desired_profession: ListProfessions[];
  desired_skills: ListSkills[];
  region: number | null;
  first_name: string;
  last_name: string;
  middle_name?: string | null;
  first_name_latin?: string | null;
  last_name_latin?: string | null;
  middle_name_latin?: string | null;
  first_name_old?: string | null;
  last_name_old?: string | null;
  middle_name_old?: string | null;
  change_fio_document?: string | null;
  avatar_url?: string | null;
  fio_did_not_change?: boolean;
  gender?: 1 | 2;
  family_status?: (1 | 2 | 3 | 4 | 5 | 6) | null;
  birthday_date?: string | null;
  birthplace?: string | null;
  snils?: number | null;
  inn?: number | null;
  email?: string | null;
  email_alternative?: string | null;
  phone?: string | null;
  telegram?: string | null;
  citizenship?: number | null;
  birth_country?: number | null;
  desired_target_group?: string | null;
};
export type UserInfo = {
  id?: string;
  email?: string;
  is_staff?: boolean;
  roles: UserRole[];
  profile: UserProfile;
};
export type UserCourseStatuses = {
  user: UserInfo;
  course: Course;
  status?: string;
};
export type UserAddresses = {
  id?: number;
  registration_country: number | null;
  registration_region: number | null;
  registration_populated_area: string | null;
  actual_county: number | null;
  actual_region: number | null;
  actual_populated_area: string | null;
  registration_region_num?: number | null;
  registration_post_index?: number | null;
  registration_district?: string | null;
  registration_street?: string | null;
  registration_house?: string | null;
  registration_building?: string | null;
  registration_apartment?: string | null;
  actual_region_num?: number | null;
  actual_post_index?: number | null;
  actual_district?: string | null;
  actual_street?: string | null;
  actual_house?: string | null;
  actual_building?: string | null;
  actual_apartment?: string | null;
  is_available?: boolean;
  created?: string;
  user: string;
};
export type UsersResponseWithId200 = {
  success: boolean;
  status_code: number;
  id: string;
  message: string;
};
export type UserAddressesPost = {
  id?: number;
  registration_region_num?: number | null;
  registration_post_index?: number | null;
  registration_populated_area?: string | null;
  registration_district?: string | null;
  registration_street?: string | null;
  registration_house?: string | null;
  registration_building?: string | null;
  registration_apartment?: string | null;
  actual_region_num?: number | null;
  actual_post_index?: number | null;
  actual_populated_area?: string | null;
  actual_district?: string | null;
  actual_street?: string | null;
  actual_house?: string | null;
  actual_building?: string | null;
  actual_apartment?: string | null;
  is_available?: boolean;
  created?: string;
  user: string;
  registration_country?: number | null;
  registration_region?: number | null;
  actual_county?: number | null;
  actual_region?: number | null;
};
export type UsersResponse200 = {
  success: boolean;
  status_code: number;
  message: string;
};
export type UserResponseNotFound = {
  detail: string;
};
export type UsersProfileResponse400 = {
  first_name: string;
  last_name: string;
};
export type UsersResponse403Messages = {
  token_class: string;
  token_type: string;
  message: string;
};
export type UsersResponse403 = {
  detail: string;
  code: string;
  messages: UsersResponse403Messages[];
};
export type UserEducationDocumentTypes = {
  id?: string;
  title: string;
};
export type UserDocumentFlow = {
  id?: string;
  title: string;
  file?: string;
  date_added?: string;
  date_signing?: string | null;
  date_viewing?: string | null;
  user: string;
  course?: string | null;
};
export type UserDocuments = {
  id?: string;
  title: string;
  size: string;
  extension: string;
  file?: string;
  is_deleted?: boolean;
  created?: string;
  user?: string;
  group?: string;
  special_for?: string | null;
};
export type UsersDocumentsResponse400 = {
  detail: string;
};
export type GroupItemsForDicuments = {
  id?: string;
  title: string;
  direction: string;
  description?: string | null;
  cropped_image?: string | null;
  group_rating?: number;
  sort_index?: number;
  is_available?: boolean;
  created?: string;
};
export type UserDocumentsResponse = {
  id?: string;
  is_me_view?: boolean;
  user: UserProfile;
  special_for?: UserProfile;
  group: GroupItemsForDicuments;
  title: string;
  size: string;
  extension: string;
  file?: string;
  is_deleted?: boolean;
  created?: string;
};
export type UsersResponseWithData200 = {
  success: boolean;
  status_code: number;
  message: string;
  data: object;
};
export type UsersDocumentsView200 = {
  success: boolean;
  status_code: number;
  message: string;
};
export type UserDocumentsViews = {
  id?: string;
  created?: string;
  user: string;
  document: string;
};
export type UserEducationDocuments = {
  id?: string;
  document_serial?: string | null;
  document_number?: string | null;
  document_register_number?: string | null;
  issue_date?: string | null;
  issued_by?: string | null;
  qualification?: string | null;
  speciality?: string | null;
  education_start?: number | null;
  education_end?: number | null;
  not_finished?: boolean;
  first_name?: string | null;
  last_name?: string | null;
  middle_name?: string | null;
  scan?: string | null;
  is_available?: boolean;
  created?: string;
  user?: string | null;
  type?: string | null;
};
export type UserEmploymentCenter = {
  id?: string;
  user: UserProfileWithUser;
  scan?: string | null;
  confirmed_employment_center?: boolean;
  confirmed_admin?: boolean;
  created?: string;
  updated?: string;
  target_group?: string | null;
};
export type UpdateUserEmploymentCenter = {
  target_group: string;
  confirmed_employment_center?: boolean;
};
export type UserIdentityDocuments = {
  id?: string;
  type?: 0 | null;
  document_serial?: string | null;
  document_number?: string | null;
  issued_by?: string | null;
  issue_date?: string | null;
  validity?: string | null;
  division_code?: string | null;
  issued_country?: string | null;
  birth_country?: string | null;
  scan?: string | null;
  is_available?: boolean;
  created?: string;
  user?: string | null;
};
export type UsersLoginResponse200 = {
  refresh: string;
  access: string;
};
export type UsersLoginResponse401 = {
  detail: string;
};
export type UserTokenObtainPair = {
  email: string;
  password: string;
};
export type UsersTokenRefreshResponse200 = {
  access: string;
};
export type UsersTokenRefreshResponse400 = {
  refresh: string;
};
export type UsersTokenRefreshResponse401 = {
  detail: string;
  code: string;
};
export type UserTokenRefresh = {
  refresh: string;
  access?: string;
};
export type UsersLoginOAuthResponse200 = {
  refresh: string;
  access: string;
  email: string;
};
export type UpdateUserProfile = {
  user: string;
  first_name: string;
  last_name: string;
  middle_name?: string | null;
  first_name_latin?: string | null;
  last_name_latin?: string | null;
  middle_name_latin?: string | null;
  first_name_old?: string | null;
  last_name_old?: string | null;
  middle_name_old?: string | null;
  fio_did_not_change?: boolean;
  gender?: 1 | 2;
  family_status?: (1 | 2 | 3 | 4 | 5 | 6) | null;
  birthday_date?: string | null;
  birthplace?: string | null;
  snils?: number | null;
  inn?: number | null;
  email?: string | null;
  email_alternative?: string | null;
  phone?: string | null;
  telegram?: string | null;
  citizenship?: number | null;
  birth_country?: number | null;
  region?: number | null;
  desired_target_group?: string | null;
  desired_profession?: number[];
  desired_skills?: number[];
};
export type UsersRegistrationResponse400 = {
  email: string;
  password: string;
  first_name: string;
  last_name: string;
};
export type UserRegistration = {
  email: string;
  password: string;
  first_name: string;
  last_name: string;
  middle_name?: string | null;
  phone?: string | null;
  region?: number | null;
};
export type UserWorkBook = {
  id?: string;
  public_service?: string | null;
  public_service_authority?: string | null;
  work_place?: string | null;
  job_title?: string | null;
  seniority?: number | null;
  county?: string | null;
  region?: string | null;
  populated_area?: string | null;
  street?: string | null;
  house?: string | null;
  building?: string | null;
  is_available?: boolean;
  created?: string;
  user?: string | null;
};
export type UserWorkBookScan = {
  id?: string;
  scan?: string | null;
  user: string;
};
export type SwaggerResponseNotFound = {
  detail: string;
};
export type ExternalApiCourseForRequests = {
  id?: string;
  title: string;
  partner_provider_title: string;
};
export type ExternalApiListOfCourseRequests = {
  id: string;
  email: string;
  status: string;
  course: ExternalApiCourseForRequests;
  region: string;
  created_at?: string;
  updated_at?: string;
  first_name?: string | null;
  last_name?: string | null;
  middle_name?: string | null;
  phone?: string | null;
};
export type PostSendRecognize = {};
export type PostSendDocuments = {};
export type GetUserByEmail = {
  user_id: string;
  email: string;
  first_name: string;
  last_name: string;
  middle_name?: string | null;
  phone?: string | null;
  avatar_url?: string | null;
  region?: number | null;
};
export const {
  useApiAnalyticsUserEmailReadQuery,
  useLazyApiAnalyticsUserEmailReadQuery,
  useApiAnalyticsUserReadQuery,
  useLazyApiAnalyticsUserReadQuery,
  useApiCartCreateMutation,
  useApiCartDeleteMutation,
  useApiCertificatesListQuery,
  useLazyApiCertificatesListQuery,
  useApiCompaniesListQuery,
  useLazyApiCompaniesListQuery,
  useApiCompaniesCreateMutation,
  useApiCompaniesResponsibleListQuery,
  useLazyApiCompaniesResponsibleListQuery,
  useApiCompaniesResponsibleCreateMutation,
  useApiCompaniesResponsibleReadQuery,
  useLazyApiCompaniesResponsibleReadQuery,
  useApiCompaniesResponsibleUpdateMutation,
  useApiCompaniesResponsibleDeleteMutation,
  useApiCompaniesAvatarUpdateMutation,
  useApiCompaniesReadQuery,
  useLazyApiCompaniesReadQuery,
  useApiCompaniesUpdateMutation,
  useApiCompaniesDeleteMutation,
  useApiConformityUserCoursesCreateMutation,
  useApiConformityUserCoursesEmailCreateMutation,
  useGetApiCourseRequestByRequestIdQuery,
  useLazyGetApiCourseRequestByRequestIdQuery,
  usePutApiCourseRequestByRequestIdMutation,
  usePostApiCourseRequestsMutation,
  useGetApiCourseRequestsCsvByRequestIdQuery,
  useLazyGetApiCourseRequestsCsvByRequestIdQuery,
  useGetApiCourseRequestsDocxByRequestIdQuery,
  useLazyGetApiCourseRequestsDocxByRequestIdQuery,
  useGetApiCourseRequestsEmailAuthQuery,
  useLazyGetApiCourseRequestsEmailAuthQuery,
  useGetApiCourseRequestsMngmReviewQuery,
  useLazyGetApiCourseRequestsMngmReviewQuery,
  usePutApiCourseRequestsMngmReviewByRequestIdMutation,
  usePostApiCourseRequestsPassportScanMutation,
  usePostApiCourseRequestsWorkBookScanMutation,
  useGetApiCourseRequestsByCourseRequestIdCommentsQuery,
  useLazyGetApiCourseRequestsByCourseRequestIdCommentsQuery,
  usePostApiCourseRequestsByCourseRequestIdCommentsMutation,
  useGetApiCourseRequestsByCourseRequestIdServiceCommentsQuery,
  useLazyGetApiCourseRequestsByCourseRequestIdServiceCommentsQuery,
  usePostApiCourseRequestsByCourseRequestIdServiceCommentsMutation,
  useGetApiCourseRequestsByEmailQuery,
  useLazyGetApiCourseRequestsByEmailQuery,
  useApiCoursesListQuery,
  useLazyApiCoursesListQuery,
  useApiCoursesCategoryFiltersListQuery,
  useLazyApiCoursesCategoryFiltersListQuery,
  useApiCoursesMngmListQuery,
  useLazyApiCoursesMngmListQuery,
  useApiCoursesRecommendationListQuery,
  useLazyApiCoursesRecommendationListQuery,
  useApiCoursesRecommendationCreateMutation,
  usePostApiCoursesSendToMailMutation,
  useApiCoursesUploadFileCreateMutation,
  useApiCoursesReadQuery,
  useLazyApiCoursesReadQuery,
  useApiCoursesScheduleListQuery,
  useLazyApiCoursesScheduleListQuery,
  useApiDictionariesCitiesListQuery,
  useLazyApiDictionariesCitiesListQuery,
  useApiDictionariesCitiesReadQuery,
  useLazyApiDictionariesCitiesReadQuery,
  useApiDictionariesCityReadQuery,
  useLazyApiDictionariesCityReadQuery,
  useApiDictionariesCountriesListQuery,
  useLazyApiDictionariesCountriesListQuery,
  useApiDictionariesCountryReadQuery,
  useLazyApiDictionariesCountryReadQuery,
  useApiDictionariesRegionReadQuery,
  useLazyApiDictionariesRegionReadQuery,
  useApiDictionariesRegionsListQuery,
  useLazyApiDictionariesRegionsListQuery,
  useApiDictionariesRegionsReadQuery,
  useLazyApiDictionariesRegionsReadQuery,
  useApiDictionaryCoursesAreasListQuery,
  useLazyApiDictionaryCoursesAreasListQuery,
  useApiDictionaryCoursesCategoriesListQuery,
  useLazyApiDictionaryCoursesCategoriesListQuery,
  useApiDictionaryCoursesCertsListQuery,
  useLazyApiDictionaryCoursesCertsListQuery,
  useGetApiDictionaryCoursesEduRequirementsQuery,
  useLazyGetApiDictionaryCoursesEduRequirementsQuery,
  useApiDictionaryCoursesFormatsListQuery,
  useLazyApiDictionaryCoursesFormatsListQuery,
  useApiDictionaryCoursesGroupsListQuery,
  useLazyApiDictionaryCoursesGroupsListQuery,
  useApiDictionaryCoursesPeriodsListQuery,
  useLazyApiDictionaryCoursesPeriodsListQuery,
  useApiDictionaryCoursesPlatformsListQuery,
  useLazyApiDictionaryCoursesPlatformsListQuery,
  useApiDictionaryCoursesProfilesListQuery,
  useLazyApiDictionaryCoursesProfilesListQuery,
  useApiDictionaryCoursesRegionsListQuery,
  useLazyApiDictionaryCoursesRegionsListQuery,
  useApiDictionaryCoursesSubjectsListQuery,
  useLazyApiDictionaryCoursesSubjectsListQuery,
  useApiDictionaryCoursesUniversitiesListQuery,
  useLazyApiDictionaryCoursesUniversitiesListQuery,
  useGetApiEmploymentCenterDocTypesQuery,
  useLazyGetApiEmploymentCenterDocTypesQuery,
  useApiEmploymentCenterCreateMutation,
  useApiFaqCategoriesListQuery,
  useLazyApiFaqCategoriesListQuery,
  useApiFaqItemReadQuery,
  useLazyApiFaqItemReadQuery,
  useApiFaqListCategoryItemsListQuery,
  useLazyApiFaqListCategoryItemsListQuery,
  useApiFaqListItemsListQuery,
  useLazyApiFaqListItemsListQuery,
  useApiFaqMainPageListQuery,
  useLazyApiFaqMainPageListQuery,
  useApiFavoritesCreateMutation,
  useApiFavoritesDeleteMutation,
  useApiFilesCreateMutation,
  useApiGeneralMainBannerListQuery,
  useLazyApiGeneralMainBannerListQuery,
  useApiGeneralProfessionsListQuery,
  useLazyApiGeneralProfessionsListQuery,
  useApiGeneralSkillsListQuery,
  useLazyApiGeneralSkillsListQuery,
  useApiGeneralTopMenusListQuery,
  useLazyApiGeneralTopMenusListQuery,
  useApiGroupsListQuery,
  useLazyApiGroupsListQuery,
  useApiGroupsCreateMutation,
  useApiGroupsUserAllListQuery,
  useLazyApiGroupsUserAllListQuery,
  useApiGroupsUserListQuery,
  useLazyApiGroupsUserListQuery,
  useApiGroupsUserCreateMutation,
  useApiGroupsUserReadQuery,
  useLazyApiGroupsUserReadQuery,
  useApiGroupsUserUpdateMutation,
  useApiGroupsUserDeleteMutation,
  useApiGroupsReadQuery,
  useLazyApiGroupsReadQuery,
  useApiGroupsUpdateMutation,
  useApiGroupsDeleteMutation,
  useApiIndividualProgramsCourseTopicsListQuery,
  useLazyApiIndividualProgramsCourseTopicsListQuery,
  useApiIndividualProgramsCourseTopicsRequestsListQuery,
  useLazyApiIndividualProgramsCourseTopicsRequestsListQuery,
  useApiIndividualProgramsCourseTopicsRequestsCreateMutation,
  useApiIndividualProgramsCourseTopicsRequestsNumberReadQuery,
  useLazyApiIndividualProgramsCourseTopicsRequestsNumberReadQuery,
  useApiIndividualProgramsCourseTopicsRequestsUuidReadQuery,
  useLazyApiIndividualProgramsCourseTopicsRequestsUuidReadQuery,
  useApiIndividualProgramsCoursesRequestsListQuery,
  useLazyApiIndividualProgramsCoursesRequestsListQuery,
  useApiIndividualProgramsCoursesRequestsCreateMutation,
  useApiIndividualProgramsCoursesRequestsNumberReadQuery,
  useLazyApiIndividualProgramsCoursesRequestsNumberReadQuery,
  useApiIndividualProgramsCoursesRequestsToggleCreateMutation,
  useApiIndividualProgramsCoursesRequestsUuidReadQuery,
  useLazyApiIndividualProgramsCoursesRequestsUuidReadQuery,
  useApiLearnOnOurPlatformListQuery,
  useLazyApiLearnOnOurPlatformListQuery,
  useApiListCartListQuery,
  useLazyApiListCartListQuery,
  useApiListCartCreateMutation,
  useApiListFavoritesListQuery,
  useLazyApiListFavoritesListQuery,
  useApiListFavoritesCreateMutation,
  useGetApiMediaAboutUsQuery,
  useLazyGetApiMediaAboutUsQuery,
  useGetApiMngmNewRecordsCountQuery,
  useLazyGetApiMngmNewRecordsCountQuery,
  useApiPartnersListQuery,
  useLazyApiPartnersListQuery,
  useApiPayentServicesListQuery,
  useLazyApiPayentServicesListQuery,
  useApiPaymentSberpayCreateMutation,
  useApiPaymentYandexCreateMutation,
  useApiPromoCodesListQuery,
  useLazyApiPromoCodesListQuery,
  useApiPromoCodesCheckReadQuery,
  useLazyApiPromoCodesCheckReadQuery,
  useApiPromoCodesUserListQuery,
  useLazyApiPromoCodesUserListQuery,
  useApiPromoCodesUserCreateMutation,
  useApiPromoCodesUserActivateCreateMutation,
  useApiReviewsMngmReviewedCoursesListQuery,
  useLazyApiReviewsMngmReviewedCoursesListQuery,
  useApiReviewsMngmTextListQuery,
  useLazyApiReviewsMngmTextListQuery,
  useApiReviewsMngmTextUpdateMutation,
  useApiReviewsMngmTextDeleteMutation,
  useApiReviewsMngmVideoListQuery,
  useLazyApiReviewsMngmVideoListQuery,
  useApiReviewsMngmVideoUpdateMutation,
  useApiReviewsMngmVideoDeleteMutation,
  useApiReviewsTextListQuery,
  useLazyApiReviewsTextListQuery,
  useApiReviewsTextAddCreateMutation,
  useApiReviewsTextCategoryReadQuery,
  useLazyApiReviewsTextCategoryReadQuery,
  useApiReviewsTextRemoveDeleteMutation,
  useApiReviewsTextUserListQuery,
  useLazyApiReviewsTextUserListQuery,
  useApiReviewsTextReadQuery,
  useLazyApiReviewsTextReadQuery,
  useApiReviewsVideoListQuery,
  useLazyApiReviewsVideoListQuery,
  useApiReviewsVideoAddCreateMutation,
  useApiReviewsVideoCategoryReadQuery,
  useLazyApiReviewsVideoCategoryReadQuery,
  useApiReviewsVideoRemoveDeleteMutation,
  useApiReviewsVideoUserListQuery,
  useLazyApiReviewsVideoUserListQuery,
  useApiReviewsVideoReadQuery,
  useLazyApiReviewsVideoReadQuery,
  useApiSpeakersListQuery,
  useLazyApiSpeakersListQuery,
  useApiSpeakersCreateMutation,
  useApiStatisticAllFileListQuery,
  useLazyApiStatisticAllFileListQuery,
  useApiStatisticCoursesViewsReadQuery,
  useLazyApiStatisticCoursesViewsReadQuery,
  useApiStatisticCoursesViewsFileListQuery,
  useLazyApiStatisticCoursesViewsFileListQuery,
  useApiStatisticRequestsByCoursesReadQuery,
  useLazyApiStatisticRequestsByCoursesReadQuery,
  useApiStatisticRequestsByCoursesFileListQuery,
  useLazyApiStatisticRequestsByCoursesFileListQuery,
  useApiStatisticRequestsStatusesReadQuery,
  useLazyApiStatisticRequestsStatusesReadQuery,
  useApiStatisticRequestsStatusesFileListQuery,
  useLazyApiStatisticRequestsStatusesFileListQuery,
  useApiStatisticSpeakersHoursReadQuery,
  useLazyApiStatisticSpeakersHoursReadQuery,
  useApiStatisticSpeakersHoursFileListQuery,
  useLazyApiStatisticSpeakersHoursFileListQuery,
  useApiStatisticSpeakersHoursByCoursesReadQuery,
  useLazyApiStatisticSpeakersHoursByCoursesReadQuery,
  useApiStatisticSpeakersHoursByCoursesFileListQuery,
  useLazyApiStatisticSpeakersHoursByCoursesFileListQuery,
  useApiStatisticUsersReadQuery,
  useLazyApiStatisticUsersReadQuery,
  useApiStatisticUsersFileListQuery,
  useLazyApiStatisticUsersFileListQuery,
  useApiStatisticUsersCategoriesReadQuery,
  useLazyApiStatisticUsersCategoriesReadQuery,
  useApiStatisticUsersCategoriesFileListQuery,
  useLazyApiStatisticUsersCategoriesFileListQuery,
  useApiStatisticUsersLocationsReadQuery,
  useLazyApiStatisticUsersLocationsReadQuery,
  useApiStatisticUsersLocationsFileListQuery,
  useLazyApiStatisticUsersLocationsFileListQuery,
  useApiStatisticUsersProfessionsReadQuery,
  useLazyApiStatisticUsersProfessionsReadQuery,
  useApiStatisticUsersProfessionsFileListQuery,
  useLazyApiStatisticUsersProfessionsFileListQuery,
  useApiStatisticUsersProfilesReadQuery,
  useLazyApiStatisticUsersProfilesReadQuery,
  useApiStatisticUsersProfilesFileListQuery,
  useLazyApiStatisticUsersProfilesFileListQuery,
  useApiStatisticUsersTargetsGroupsReadQuery,
  useLazyApiStatisticUsersTargetsGroupsReadQuery,
  useApiStatisticUsersTargetsGroupsFileListQuery,
  useLazyApiStatisticUsersTargetsGroupsFileListQuery,
  useApiTeachersListQuery,
  useLazyApiTeachersListQuery,
  useApiTeachersCreateMutation,
  useApiTeachersReadQuery,
  useLazyApiTeachersReadQuery,
  useApiTeachersUpdateMutation,
  useApiTeachersDeleteMutation,
  useGetApiTopSpeakersQuery,
  useLazyGetApiTopSpeakersQuery,
  useApiTrainingDiagnosticsListQuery,
  useLazyApiTrainingDiagnosticsListQuery,
  useApiTrainingDiagnosticsUserListQuery,
  useLazyApiTrainingDiagnosticsUserListQuery,
  useApiUserCoursesListQuery,
  useLazyApiUserCoursesListQuery,
  useApiUserCoursesAchievementsListQuery,
  useLazyApiUserCoursesAchievementsListQuery,
  useApiUsersAddressesListQuery,
  useLazyApiUsersAddressesListQuery,
  useApiUsersAddressesCreateMutation,
  useApiUsersAddressesUpdateMutation,
  useApiUsersAvatarUpdateMutation,
  useApiUsersChangeFioDocumentUpdateMutation,
  useApiUsersDeleteDeleteMutation,
  useApiUsersDictionariesEducationDocumentsTypesListQuery,
  useLazyApiUsersDictionariesEducationDocumentsTypesListQuery,
  useApiUsersDocumentFlowListQuery,
  useLazyApiUsersDocumentFlowListQuery,
  useApiUsersDocumentFlowCoursesListQuery,
  useLazyApiUsersDocumentFlowCoursesListQuery,
  useApiUsersDocumentFlowSigningUpdateMutation,
  useApiUsersDocumentFlowViewingUpdateMutation,
  useApiUsersDocumentsCreateMutation,
  useApiUsersDocumentsListListQuery,
  useLazyApiUsersDocumentsListListQuery,
  useApiUsersDocumentsRecognitionAddressDocumentUpdateMutation,
  useApiUsersDocumentsRecognitionEducationDocumentUpdateMutation,
  useApiUsersDocumentsRecognitionPassportUpdateMutation,
  useApiUsersDocumentsRecognitionWorkBookUpdateMutation,
  useApiUsersDocumentsViewCreateMutation,
  useApiUsersEducationDocumentsListQuery,
  useLazyApiUsersEducationDocumentsListQuery,
  useApiUsersEducationDocumentsCreateMutation,
  useApiUsersEducationDocumentsScanUpdateMutation,
  useApiUsersEducationDocumentsReadQuery,
  useLazyApiUsersEducationDocumentsReadQuery,
  useApiUsersEducationDocumentsUpdateMutation,
  useApiUsersEducationDocumentsDeleteMutation,
  useApiUsersEmploymentCenterListQuery,
  useLazyApiUsersEmploymentCenterListQuery,
  useApiUsersEmploymentCenterCreateMutation,
  useApiUsersEmploymentCenterUpdateMutation,
  useApiUsersEmploymentCenterDeleteMutation,
  useApiUsersEmploymentCenterScanUpdateMutation,
  useApiUsersFamilyStatusesListQuery,
  useLazyApiUsersFamilyStatusesListQuery,
  useApiUsersGendersListQuery,
  useLazyApiUsersGendersListQuery,
  useApiUsersIdentityDocumentsListQuery,
  useLazyApiUsersIdentityDocumentsListQuery,
  useApiUsersIdentityDocumentsCreateMutation,
  useApiUsersIdentityDocumentsScanUpdateMutation,
  useApiUsersIdentityDocumentsScanCvUpdateMutation,
  useApiUsersIdentityDocumentsReadQuery,
  useLazyApiUsersIdentityDocumentsReadQuery,
  useApiUsersIdentityDocumentsUpdateMutation,
  useApiUsersIdentityDocumentsDeleteMutation,
  useApiUsersLoginCreateMutation,
  useApiUsersLoginAdCreateMutation,
  useApiUsersLoginGuCreateMutation,
  useApiUsersLoginRefreshCreateMutation,
  useApiUsersLoginVkCreateMutation,
  useApiUsersLoginYaCreateMutation,
  useApiUsersLogoutCreateMutation,
  useApiUsersProfileReadQuery,
  useLazyApiUsersProfileReadQuery,
  useApiUsersProfileUpdateMutation,
  useApiUsersProfileDeleteMutation,
  useApiUsersRegisterCreateMutation,
  useApiUsersWorkBookListQuery,
  useLazyApiUsersWorkBookListQuery,
  useApiUsersWorkBookCreateMutation,
  useApiUsersWorkBookUploadScanListQuery,
  useLazyApiUsersWorkBookUploadScanListQuery,
  useApiUsersWorkBookUploadScanUpdateMutation,
  useApiUsersWorkBookUploadScanDeleteMutation,
  useApiUsersWorkBookReadQuery,
  useLazyApiUsersWorkBookReadQuery,
  useApiUsersWorkBookUpdateMutation,
  useApiUsersWorkBookDeleteMutation,
  useExternalApiCoursesListQuery,
  useLazyExternalApiCoursesListQuery,
  useExternalApiCoursesReadQuery,
  useLazyExternalApiCoursesReadQuery,
  useExternalApiFaqListQuery,
  useLazyExternalApiFaqListQuery,
  useExternalApiListRequestsListQuery,
  useLazyExternalApiListRequestsListQuery,
  useExternalApiPartnersListQuery,
  useLazyExternalApiPartnersListQuery,
  useExternalApiPromoCodesListQuery,
  useLazyExternalApiPromoCodesListQuery,
  useExternalApiRecognizeDocumentCreateMutation,
  useExternalApiReviewsTextListQuery,
  useLazyExternalApiReviewsTextListQuery,
  useExternalApiReviewsVideoListQuery,
  useLazyExternalApiReviewsVideoListQuery,
  useExternalApiSendDocumentsCreateMutation,
  useExternalApiSpeakersListQuery,
  useLazyExternalApiSpeakersListQuery,
  useExternalApiStatisticCoursesViewsReadQuery,
  useLazyExternalApiStatisticCoursesViewsReadQuery,
  useExternalApiStatisticRequestsStatusesReadQuery,
  useLazyExternalApiStatisticRequestsStatusesReadQuery,
  useExternalApiStatisticUsersTargetsGroupsReadQuery,
  useLazyExternalApiStatisticUsersTargetsGroupsReadQuery,
  useExternalApiTargetGroupsListQuery,
  useLazyExternalApiTargetGroupsListQuery,
  useExternalApiUsersEmploymentCenterScanReadQuery,
  useLazyExternalApiUsersEmploymentCenterScanReadQuery,
  useExternalApiUsersEmploymentCenterScanCreateMutation,
  useExternalApiUsersReadQuery,
  useLazyExternalApiUsersReadQuery,
} = injectedRtkApi;
