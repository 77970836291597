import { FC, memo, useMemo } from 'react';
import { ICalendarDayProps } from './props';
import { classNames } from '../../../../helpers/classnames';
import { CalendarSizeEnum } from '../calendar-size';
import { isLastWeekDay, isWeekends } from '../helpers';

import css from './day.module.scss';

export const CalendarDay: FC<ICalendarDayProps> = memo(({ value, size, onClick }) => {
  if (!value) { return null; }

  const classes = useMemo(() => classNames(
    css.calendarDay,
    {
      [css.calendarDay_small]: size === CalendarSizeEnum.Small,
      [css.calendarDay__today]: value.today,
      [css.calendarDay__next_month]: value.next,
      [css.calendarDay__prev_month]: value.prev,
      [css.calendarDay__in_range]: value.inRange,
      [css.calendarDay__range_start]: value.rangeStart,
      [css.calendarDay__range_end]: value.rangeEnd,
      [css.calendarDay__disabled]: value.disabled,
      [css.calendarDay__weekends]: isWeekends(value.date) && !value.selected,
      [css.calendarDay__last]: isLastWeekDay(value.date),
      [css.calendarDay__selected]: value.selected,
    },
  ), [value]);

  return (
    <div className={classes} onClick={() => onClick && onClick()}>
      {value.date.getDate()}
    </div>
  );
});
