import { ProfessorsFilterTypeEnum } from '../enums';
import { IManagementProfessorsState } from '../interfaces';

export const professorsInitialState: IManagementProfessorsState = {
  filter: {
    skip: 0,
    take: 0,
    search: undefined,
    type: ProfessorsFilterTypeEnum.All,
    possibleTypes: [
      ProfessorsFilterTypeEnum.All,
      ProfessorsFilterTypeEnum.Active,
      ProfessorsFilterTypeEnum.Draft,
      ProfessorsFilterTypeEnum.Hidden,
      ProfessorsFilterTypeEnum.Archived,
    ],
  },
};
