import { TutorStatus } from '../../enums/courses/tutor-status';
import { CoursesMapper } from '../../types/courses/courses-mapper';
import { mapToCertificate } from './certificate';
import { mapToTextReview } from './text-review';
import { mapToVideoReview } from './video-review';
import { mapToDictionaryItem } from './dictionary';
import { Course } from '../base-api';
import { CoursesStatusEnum } from '../../pages/management/containers/courses/enums';

const priceOptions = [
  'Бесплатно',
  'До 10 000 руб.',
  'От 10 001 до 50 000 руб.',
  'От 50 0001 до 100 000 руб.',
  'Более 100 001 руб.',
];

const dateStartOptions = ['До 7 дней', 'От 8 до 29 дней', 'От 30 дней'];


const mapStatus = (fromDto: Course) => {
  const isArchived = fromDto.end_date !== null ? new Date().getTime() > new Date(fromDto.end_date).getTime() : fromDto.end_date;

  if (fromDto.is_available && !isArchived) {
    return [CoursesStatusEnum.All, CoursesStatusEnum.Active];
  } else if (!fromDto.is_available && !isArchived) {
    return [CoursesStatusEnum.All, CoursesStatusEnum.Hidden, CoursesStatusEnum.Draft];
  } else if (isArchived) {
    return [CoursesStatusEnum.All, CoursesStatusEnum.Archived];
  }
  return [CoursesStatusEnum.All];
};


export const mapToCourse: CoursesMapper = (fromDto) => {
  return {
    id: fromDto.id!,
    title: fromDto.title,
    description: fromDto.description || '',
    atmosfere_card: fromDto.atmosfere_card,
    imageSrc:
      fromDto?.image_url || fromDto?.neural_image?.image_url || fromDto?.cropped_image || '',
    partnerProvider: {
      id: fromDto.partner_provider?.id!,
      name: fromDto.partner_provider?.title,
      imageSrc: fromDto.partner_provider?.logo!,
      platformSrc: fromDto.partner_provider?.platform_logo!,
    },
    isFree: !!fromDto?.is_free,
    speakers: fromDto?.speakers,
    baseModule: {
      difficulty: fromDto.difficulty!,
      modulesCount: fromDto.modules_count!,
      hours: fromDto.hours,
      for: fromDto.fits.map((fit) => ({
        key: fit.key,
        value: fit.value,
      })),
      benefits: fromDto.results.map((result) => result.title),
      speakers: fromDto.speakers.map((speaker) => ({
        id: speaker.id || '',
        fio: speaker.fio,
        status: speaker.status === 1 ? TutorStatus.Teacher : TutorStatus.Other,
        jobTitle: speaker.job_title,
        photoSrc: speaker.image_url || speaker.cropped_image || '',
        photoBigSrc: speaker.big_image_url! || speaker.cropped_big_image || '',
      })),
      professions: [fromDto.profession],
      requirement: { education: [fromDto.edu_requirement?.title] },
      testsCount: fromDto.test_count!,
    },
    groups: fromDto.groups.map((group) => ({
      id: `${group.id}`,
      title: `${group.title}`,
    })),
    areas: fromDto.areas.map((area) => ({
      id: `${area.id}`,
      title: `${area.title}`,
    })),
    formats: fromDto.format.map((format) => ({
      id: `${format.id}`,
      title: `${format.title}`,
    })),
    regions: fromDto.regions.map((region) => ({
      id: `${region.id}`,
      title: `${region.title}`,
    })),
    certificates: fromDto.certificates.map(mapToCertificate),
    periods: fromDto.periods
      ? [
          {
            id: `${fromDto.periods.id}`,
            title: `${fromDto.periods.title}`,
          },
        ]
      : [],
    eduRequirement: fromDto.edu_requirement?.id
      ? { id: `${fromDto.edu_requirement.id}`, title: `${fromDto.edu_requirement.title}` }
      : undefined,
    rate: fromDto.rate!,
    rateCount: fromDto.rate_count!,
    textReviews: fromDto.reviews_text.map(mapToTextReview),
    videoReviews: fromDto.reviews_video.map(mapToVideoReview),
    intro: { ...fromDto.introduction },
    categories: fromDto.categories.map((category) => ({
      id: `${category.id}`,
      title: `${category.title}`,
    })),
    university: fromDto.university
      ? { id: `${fromDto.university.id}`, title: `${fromDto.university.title}` }
      : undefined,
    startDate: fromDto.start_date
      ? new Date(fromDto.start_date).getTime() - Date.now() <= 7 * 24 * 60 * 60 * 1000 // till 7 days
        ? { id: '1', title: dateStartOptions[0], value: `${fromDto.start_date}` }
        : new Date(fromDto.start_date).getTime() - Date.now() > 7 * 24 * 60 * 60 * 1000 &&
          new Date(fromDto.start_date).getTime() - Date.now() <= 29 * 24 * 60 * 60 * 1000 // from 8 to 29 дней
        ? { id: '2', title: dateStartOptions[1], value: `${fromDto.start_date}` }
        : { id: '3', title: dateStartOptions[2], value: `${fromDto.start_date}` }
      : undefined,
    endDate: fromDto.end_date ? fromDto.end_date : undefined,
    lang: { id: 'ru', title: 'Русский' },
    price:
      typeof fromDto.price === 'number'
        ? +fromDto.price === 0
          ? { id: '1', title: priceOptions[0], value: `${fromDto.price}` }
          : fromDto.price > 0 && fromDto.price <= 10000
          ? { id: '2', title: priceOptions[1], value: `${fromDto.price}` }
          : fromDto.price > 10000 && fromDto.price <= 50000
          ? { id: '3', title: priceOptions[2], value: `${fromDto.price}` }
          : fromDto.price > 50000 && fromDto.price <= 100000
          ? { id: '4', title: priceOptions[3], value: `${fromDto.price}` }
          : { id: '5', title: priceOptions[4], value: `${fromDto.price}` }
        : fromDto.is_free
        ? { id: '1', title: priceOptions[0], value: '0' }
        : undefined,
    isNovelty: fromDto.novelty_date ? new Date(fromDto.novelty_date).getTime() > Date.now() : false,
    difficulty: fromDto.difficulty!,
    subject: fromDto?.subject ? mapToDictionaryItem(fromDto?.subject) : undefined,
    application_deadline: fromDto.application_deadline ? fromDto.application_deadline : undefined,
    enrollment_period: fromDto.enrollment_period ? fromDto.enrollment_period : undefined,
    isAvailable: fromDto.is_available,
    statuses: mapStatus(fromDto),
  };
};
