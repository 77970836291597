import { FC, memo } from 'react';

import { AppConst } from '../../../constants/app';
import { withTestId } from '../../../helpers';
import { HeaderDesktopView } from './components/desktop-view';
import { HeaderMobileView } from './components/mobile-view';

import css from './index.module.scss';

export const Header: FC = memo(() => {
  return (
    <div className={css.headerWrapper}>
      <div className={`${css.headerWrapperContainer} ${AppConst.Name}-container`} {...withTestId('headerBlock')}>
        <HeaderDesktopView />
        <HeaderMobileView />
      </div>
    </div>
  );
});
