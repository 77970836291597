import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { initialState } from './intitial-state';
import { ERootState } from '../../../interfaces/root-state';

export const slice = createSlice({
  name: ERootState.study,
  initialState,
  reducers: {
    resetFilter: () => initialState,
    setMyCoursesTab: (state, action: PayloadAction<number>) => {
      state.myCourses.activeTabId = action.payload;
    },
    setMyCoursesSearchValue: (state, action: PayloadAction<string>) => {
      state.myCourses.searchValue = action.payload;
    },
    setMyRequestsTab: (state, action: PayloadAction<number>) => {
      state.myRequests.activeTabId = action.payload;
    },
    setMyGroupsTab: (state, action: PayloadAction<number | string | undefined>) => {
      state.myGroups.activeTabId = action.payload;
    },
    setMyGroupsSearchValue: (state, action: PayloadAction<string>) => {
      state.myGroups.searchValue = action.payload;
    },
    setAchievementsSearchValue: (state, action: PayloadAction<string>) => {
      state.achievements.searchValue = action.payload;
    },
    setAchievementsDocumentType: (state, action: PayloadAction<string | undefined>) => {
      state.achievements.documentTypeId = action.payload;
    },
    setDocumentsSearchValue: (state, action: PayloadAction<string>) => {
      state.documents.searchValue = action.payload;
    },
    setDocumentsDocumentType: (state, action: PayloadAction<number>) => {
      state.documents.documentTypeId = action.payload;
    },
    setDocumentsCoursesType: (state, action: PayloadAction<string | undefined>) => {
      state.documents.coursesTypeId = action.payload;
    },
    setPromocodesTab: (state, action: PayloadAction<number>) => {
      state.promocodes.activeTabId = action.payload;
    },
    setRequestPromocode: (state, action: PayloadAction<string | null>) => {
      state.promocodes.request = action.payload;
    },
  },
});
