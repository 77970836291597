import { FC, memo } from 'react';
import { ISideMenuProps } from './props';
import { SideMenuItem } from './item';
import { useUserProfile } from '../../hooks/auth';
import { withTestId } from '../../helpers';
import css from './index.module.scss';
import { MenuItem } from '../../interfaces/common/menu-item';

export const SideMenu: FC<ISideMenuProps> = memo(({ items, counters, iconProvider, chapter, testId, onItemClick }) => {
  const { user } = useUserProfile();
  
  const roleControlAccess = (item: MenuItem) => {
    // here is the definition of what the user sees depending on his role (item.role === user.role[i].title)
    return user?.isStaff;
  };

  return (
    <ul className={css.sideMenu} {...withTestId(testId)}>
      {items
        .filter((item) => item.isAvailable(user))
        .map((menuItem) => (
          <SideMenuItem
            key={menuItem.key}
            item={menuItem}
            counter={counters && counters[menuItem.key]}
            iconProvider={iconProvider}
            chapter={chapter}
            testId={menuItem.key}
            onItemClick={onItemClick}
          />
        ))}
    </ul>
  );
});
