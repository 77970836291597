import { useMemo } from 'react';
import { useAppDispatch, useAppSelector } from '../../../hooks/hooks';
import { Course, useApiCoursesListQuery } from '../../../services/base-api';
import { Course as MappedCourse } from '../../../interfaces/courses/course';
import { mapToCourse } from '../../../services/mappers/courses';
import { addCourseToShow, setFiltersOptions } from '../store/actions';
import { getFiltersOptions } from '../../../helpers/filterOptions';

export const useAllCoursesList = () => {
  const dispatch = useAppDispatch();
  const filter = useAppSelector((state) => state.main.filter);
  const { isFetching, data } = useApiCoursesListQuery({});

  const noData = isFetching || !data;

  return useMemo(() => {
    const allCourses = (data as unknown as Course[]) || [];
    const mappedCourses: MappedCourse[] = allCourses.map(mapToCourse);

    const filteredCourses = mappedCourses
      .filter(
        (course) => !filter.group || course.groups?.some((group) => group.id === filter.group?.id),
      )
      .filter((course) => !filter.areas?.length || course.areas?.some((area) => filter.areas?.map(filterArea => filterArea.id).includes(area.id)))
      .filter(
        (course) =>
          !filter.region || course.regions?.some((region) => region.id === filter.region?.id),
      )
      .filter(
        (course) =>
          !filter.format || course.formats?.some((format) => format.id === filter.format?.id),
      )
      .filter(
        (course) =>
          !filter.certificate ||
          course.certificates?.some((certificate) => certificate.id === filter.certificate?.id),
      )
      .filter(
        (course) =>
          !filter.period || course.periods?.some((period) => period.id === filter.period?.id),
      )
      .filter(
        (course) =>
          !filter.eduRequirement || course.eduRequirement?.id === filter.eduRequirement?.id,
      )
      .filter(
        (course) =>
          !filter.category ||
          course.categories?.some((category) => category.id === filter.category?.id),
      )
      .filter(
        (course) =>
          !filter.search || course.title.toLowerCase().indexOf(filter.search.toLowerCase()) >= 0,
      )
      .filter((course) => !filter.university || course.university?.id === filter.university.id)
      .filter((course) => !filter.lang || course.lang?.id === filter.lang.id)
      .filter((course) => !filter.price || course.price?.id === filter.price.id)
      .filter((course) => !filter.startDate || course.startDate?.id === filter.startDate.id);

    const optionsFromFilteredCourses = getFiltersOptions(filteredCourses);
    dispatch(setFiltersOptions(optionsFromFilteredCourses));

    const coursesToShow = filteredCourses.slice(0, filter.courseCount);
    const isMore = filteredCourses.length > coursesToShow.length;

    const getMore = () => {
      if (isMore) {
        dispatch(addCourseToShow(9));
      }
    };

    if (noData) {
      return { totalCount: 0, courses: [], noData, isMore, getMore, allCourses: [] };
    }

    return {
      courses: coursesToShow,
      totalCount: allCourses.length,
      noData: false,
      isMore,
      getMore,
      allCourses: mappedCourses
    };
  }, [noData, data, filter, dispatch]);
};
