import { fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { AuthStorage } from '../auth/auth-storage';
import { Cookies } from 'react-cookie-consent';

const baseUrl = `${process.env.REACT_APP_API}`;
const apiKey = process.env.REACT_APP_API_KEY;

export const baseQuery = fetchBaseQuery({
  baseUrl,
  mode: 'cors',
  referrerPolicy: 'unsafe-url',
  prepareHeaders: (headers) => {
    const authData = AuthStorage.getData();

    if (authData?.token) {
      headers.set('Authorization', `Bearer ${authData?.token}`);
    }

    if (apiKey) {
      headers.set('X-API-Key', apiKey);
    }

    const csrftoken = Cookies.get('csrftoken');

    if (csrftoken) {
      headers.set('X-CSRFToken', csrftoken);
    }

    return headers;
  },
});
