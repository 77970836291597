import { ProfileMapper } from '../interfaces/profile';

export const mapToProfileUser: ProfileMapper = (from) => ({
  userId: from?.user,
  firstName: from?.first_name,
  lastName: from?.last_name,
  middleName: from?.middle_name || '',
  phone: from?.phone || '',
  avatarUrl: from?.avatar_url || '',
  region: from?.region || 0,
});
