import { PayloadAction } from '@reduxjs/toolkit';

import { PageSliceCaseReducers } from '../../../types';
import { DocumentsFilterTypeEnum } from '../enums';

export const documentsReducers: PageSliceCaseReducers = {
    setDocumentsFilterSearch: (state, action: PayloadAction<string | undefined>) => {
        state.documents.filter.search = action.payload;
    },
    setDocumentsFilterType: (state, action: PayloadAction<DocumentsFilterTypeEnum>) => {
        state.documents.filter.type = action.payload;
    },
};
