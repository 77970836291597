import { useMemo } from 'react';
import { useUserProfile } from '../../../../hooks/auth';
import { useApiGeneralTopMenusListQuery } from '../../../../services/base-api';
import { PagePathsEnum } from '../../../../enums/routes';
import { MenuItem } from '../../../../interfaces/common/menu-item';
import { useTranslation } from 'i18nano';

export const useTabsMenu = () => {
    const translate = useTranslation();
    const { user } = useUserProfile();
    const { data, isLoading } = useApiGeneralTopMenusListQuery();

    const mapLabelToPath = (title: string, label: string) => {
        if (label === 'main') {
            return {
                key: title,
                path: PagePathsEnum.Root
            };
        }

        if (label === PagePathsEnum.Courses) {
            return {
                key: title,
                path: PagePathsEnum.Courses
            };
        }

        if (label === 'study/all-information') {
            return {
                key: title,
                path: PagePathsEnum.Study
            };
        }

        if (label === PagePathsEnum.Management) {
            return {
                key: title,
                path: PagePathsEnum.Management
            };
        }
    };

    const menuTabs: MenuItem[] = useMemo(() => {
      if (data?.length) {
        const menuTabsData = data.filter((item) => item.is_staff === !!user?.isStaff);
        const menuTabsList: MenuItem[] = [];
        menuTabsData[0]?.items.map((item) => {
            menuTabsList.push({
                ...mapLabelToPath(item.title, item.label)!,
                isAvailable: () => true,
            });
        });
        if (menuTabsData.length)
            return menuTabsList;
      }
      return [
        {
          key: translate('menu.main'),
          path: PagePathsEnum.Root,
          isAvailable: () => true,
        },
        {
          key: translate('menu.courses'),
          path: PagePathsEnum.Courses,
          isAvailable: () => true,
        },
        {
          key: translate('menu.study'),
          path: PagePathsEnum.Study,
          isAvailable: () => true,
        }
    ];
    }, [user, data]);

    return useMemo(() => {
        return {
            isLoading,
            menuTabs
        };
    }, [user, data]);
};