import { memo } from 'react';
import { useTranslation } from 'i18nano';

import { Button } from '../../../../../components/ui-kit/button';
import { ContainerSection } from '../../../../../components/container-section';

import css from './index.module.scss';

export const PromoSection = memo(() => {
  const translate = useTranslation();

  return (
    <ContainerSection>
      <div className={css.footerPromo}>
        <div className={css.footerPromoLogo}>
          <span />
        </div>
        {/* <div className={css.footerPromoPartners}>
          <Button
            type={'btnlink'}
            style={{ width: '240px' }}
            label={translate('footer.becomeTeacher')}
          />
          <Button
            type={'btnlink'}
            style={{ width: '192px' }}
            label={translate('footer.becomePartner')}
          />
        </div> */}
      </div>
    </ContainerSection>
  );
});
