import { combineReducers, configureStore, EnhancedStore, PreloadedState, Reducer } from '@reduxjs/toolkit';
import appReducer from '../state/reducer';
import { baseApi } from '../services/base-api';
import authReducer from '../pages/auth/store/slice';
import mainReducer from '../pages/main/store/reducer';
import studyReducer from '../pages/study/store/reducer';
import managementReducer from '../pages/management/store/reducer';
import { errorHandlerMiddleware } from '../services/base-api/error-handler';
import { RootState } from '../interfaces/root-state';

const staticReducers = {
  app: appReducer,
  auth: authReducer,
  main: mainReducer,
  study: studyReducer,
  management: managementReducer,
  [baseApi.reducerPath]: baseApi.reducer,
};

// Add a dictionary to keep track of the registered async reducers
const asyncReducers: { [key: string]: Reducer } = {};

export const setupStore = (preloadedState?: PreloadedState<RootState>) => {
  return configureStore({
    reducer: staticReducers,
    middleware: (getDefaultMiddleware) =>
      getDefaultMiddleware().concat(baseApi.middleware, errorHandlerMiddleware),
    preloadedState
  });
};

export const store = setupStore();

// Create an inject reducer function
// This function adds the async reducer, and creates a new combined reducer
export function injectReducer(store: EnhancedStore, key: string, asyncReducer: Reducer) {
  asyncReducers[key] = asyncReducer;
  store.replaceReducer(
    combineReducers({
      ...staticReducers,
      ...asyncReducers,
    }),
  );
}
