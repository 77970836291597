import { FC, memo, useCallback, useMemo } from 'react';
import { NavLink } from 'react-router-dom';
import { useTranslation } from 'i18nano';
import { ISideMenuItemProps } from './props';
import { classNames } from '../../../helpers/classnames';
import { MaterialIconText } from '../../material-icon-text';
import { SideSubMenu } from '../sub-menu';
import { withTestId } from '../../../helpers';

import css from './index.module.scss';

export const SideMenuItem: FC<ISideMenuItemProps> = memo(
  ({ item, counter, iconProvider, chapter, testId, onItemClick }) => {
    const translate = useTranslation();
    const hasCustomIconProvider = useMemo(() => item.iconName && !!iconProvider, [iconProvider]);
    const getCustomIcon = useCallback(
      (iconName: string, isActive: boolean) => {
        if (hasCustomIconProvider) {
          const IconComponent = iconProvider!(iconName, isActive);
          if (!IconComponent) {
            return null;
          }

          return <IconComponent />;
        }

        return null;
      },
      [hasCustomIconProvider, iconProvider],
    );

    if (item.subItems) {
      return (
        <li className={css.sideMenuItem} key={item.key}>
          <SideSubMenu
            parentItem={item}
            title={item.key}
            items={item.subItems}
            icon={item.iconName}
          />
        </li>
      );
    }

    return (
      <li className={css.sideMenuItem} key={item.key} {...withTestId(testId)}>
        <NavLink
          to={item.path}
          className={({ isActive }) =>
            classNames(css.sideMenuItemLink, { [css.sideMenuItemLinkActive]: isActive })
          }
          onClick={() => onItemClick && onItemClick()}
        >
          {({ isActive }) => (
            <>
              {!hasCustomIconProvider && item.iconName && (
                <MaterialIconText
                  iconName={item.iconName!}
                  size={'20'}
                  classes={css.sideMenuItemIcon}
                  isSymbols={true}
                />
              )}
              {hasCustomIconProvider && getCustomIcon(item.iconName!, isActive)}
              <span className={classNames({ [css.sideMenuItemLinkTextWithIcon]: !!item.iconName })}>
                {translate(item.key)}
              </span>
              {counter! > 0 && chapter === 'management' && (
                <span className={css.sideMenuItemBadge}>{counter}</span>
              )}
            </>
          )}
        </NavLink>
      </li>
    );
  },
);
