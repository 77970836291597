import { ITestable } from '../../interfaces';

type TestableKeyPropsType = Lowercase<(keyof ITestable)>;
type DataAttrNameType = `data-${TestableKeyPropsType}`;
type TestIdAttrType = {
  [key in DataAttrNameType]: string;
};
const isProduction = process.env.NODE_ENV === 'production';

export const withTestId = (testId?: string): TestIdAttrType | {} => {
  const props = {} as TestIdAttrType;
  if (!testId || isProduction) { return props; }
  
  props['data-testid'] = testId!;

  return props;
};
