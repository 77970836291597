import { PayloadAction } from '@reduxjs/toolkit';

import { PageSliceCaseReducers } from '../../../types';
import { GroupsStatusEnum } from '../enums';

export const groupsReducers: PageSliceCaseReducers = {
    setGroupsFilterSearch: (state, action: PayloadAction<string | undefined>) => {
        state.groups.filter.search = action.payload;
    },
    setGroupsStatus: (state, action: PayloadAction<GroupsStatusEnum>) => {
        state.groups.filter.groupStatus = action.payload;
    },
};
