import { FC, memo, useEffect, useState } from 'react';

import { IPaginatorProps } from './props';
import { HBox } from '../layout';
import { Pages } from './pages';
import { PerPageSelector } from './per-page-selector';
import { AppConst } from '../../constants/app';

interface IPaginatorState {
  selectedItemsPerPageCount: number,
}

export const Paginator: FC<IPaginatorProps> = memo((props) => {
  const [state, setState] = useState<IPaginatorState>({
    selectedItemsPerPageCount: AppConst.perPageItemsCounts[0],
  });

  const handleChanePerPageItems = (count: number) => {
    if (state.selectedItemsPerPageCount !== count) {
      setState((prevState) => ({ ...prevState, selectedItemsPerPageCount: count, }));
      props.onChanePerPageItems && props.onChanePerPageItems(count);
    }
  };

  useEffect(() => {
    setState((prevState) => ({
      ...prevState,
      selectedItemsPerPageCount: props.itemsPerPage || state.selectedItemsPerPageCount,
    }));
  }, [props.itemsPerPage]);
  
  return (
    <HBox testId={'paginator'} align={'center'} justify={'end'}>
      <Pages
        itemsPerPage={state.selectedItemsPerPageCount}
        itemsTotalCount={props.itemsTotalCount}
        currentPage={props.currentPage}
        onSetPage={props.onSetPage}
      />
      <PerPageSelector
        label={props.perPageSelectorLabel}
        counts={AppConst.perPageItemsCounts}
        selectedCount={state.selectedItemsPerPageCount}
        onChanePerPageItems={handleChanePerPageItems}
      />
    </HBox>
  );
});
