import { memo, useEffect, useState } from 'react';

import { CalendarMonth } from '../month';
import { ISingleDatePickerProps } from './props';
import { MaterialIconText } from '../../../material-icon-text';
import { IDay } from '../day';

import css from './index.module.scss';
import { addMonths, formatMonthLong } from '../helpers';

interface ISingleDatePickerState {
  month?: Date;
  selectedDay?: Date;
}

export const SingleDatePicker: React.FC<ISingleDatePickerProps> = memo((props) => {
  const [state, setState] = useState<ISingleDatePickerState>({
    month: props.selectedDay || new Date(),
    selectedDay: props.selectedDay || new Date(),
  });

  const handleSelect = (val: Date[] | [Date] | IDay) => {
    props.onSelect && props.onSelect(val);
  };

  const handleArrowClick = (spin: number) => {
    let month = state.month;
    const selectedDay = state.selectedDay;
    if (spin) {
      month = addMonths(month!, 1);
    } else {
      month = addMonths(month!, -1);
    }

    if (props.onChangeMonth) {
      props.onChangeMonth({ month });
    }

    setState((prevState) => ({ ...prevState, month: month, selectedDay }));
  };

  useEffect(() => {
    setState((prevState) => ({
      ...prevState,
      month: props.selectedDay || new Date(),
      selectedDay: props.selectedDay || new Date(),
    }));
  }, [props.selectedDay]);

  return (
    <div className={css.singleDatepicker}>
      <div className={css.singleDatepicker__header}>
        <MaterialIconText
          classes={css.singleDatepicker__arrow}
          iconName={'chevron_left'}
          onClick={() => handleArrowClick(0)}
        />
        <span className={css.singleDatepicker__year}>
          {state.month && formatMonthLong(state.month, props.locale)}
        </span>
        <MaterialIconText
          classes={css.singleDatepicker__arrow}
          iconName={'chevron_right'}
          onClick={() => handleArrowClick(1)}
        />
      </div>
      <div className={css.singleDatepicker__calendar}>
        <CalendarMonth
          onClick={handleSelect}
          selectedDay={state.selectedDay}
          month={state.month!}
          minDate={props.minDate}
          maxDate={props.maxDate}
        />
      </div>
    </div>
  );
});
