import { useTranslation } from 'i18nano';
import { FC } from 'react';
import CookieConsent from 'react-cookie-consent';
import { withTestId } from '../../helpers';
import css from './index.module.scss';

export const CookiesConsentView: FC = () => {
  const translate = useTranslation();
  return (
    <CookieConsent
      location="bottom"
      buttonText={translate('cookies.allowText')}
      cookieName="ranepaTest"
      containerClasses={css.container}
      contentClasses={css.content}
      buttonClasses={css.allowBtn}
      expires={150}
      customButtonProps={{ 'data-testid': 'cookieConsentSubmitBtn' }}
    >
      <span {...withTestId('cookieConsentText')}>{translate('cookies.text')}</span>
    </CookieConsent>
  );
};

