import { FC, memo, useMemo } from 'react';
import { useTranslation } from 'i18nano';
import css from './index.module.scss';
import { useUserProfile } from '../../../../../../../hooks/auth';
import { handleImageError } from '../../../../../../../helpers/image-error';

export const UserProfile: FC = memo(() => {
  const translate = useTranslation();
  const { user } = useUserProfile();

  if (!user) {
    return null;
  }

  const profileName = useMemo(() => user.isStaff
    ? translate('header.admin')
    : user.profileName ?? translate('header.profileNameFallback'),
    [user.profileName, user.isStaff],
  );

  return (
    <div className={css.userProfile}>
      <span className={css.userProfileData}>
        <p>{`${user.firstName} ${user.lastName}`}</p>
        <p>{profileName}</p>
      </span>
      <img
        className={css.userProfileImage}
        src={user.avatarUrl}
        alt={'profile'}
        onError={handleImageError('/images/profiles/userIcon.svg')}
      />
    </div>
  );
});
