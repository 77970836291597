import { FC, memo, useCallback, useEffect, useState } from 'react';

import { IButtonGroupItem, IButtonGroupProps } from './props';
import { Button } from '../index';

import css from './index.module.scss';

export const ButtonGroup: FC<IButtonGroupProps> = memo(({ items, selectedId, onSelect }) => {
  const [currentSelectedItem, setSelectedItem] = useState<IButtonGroupItem | null>(items[0] || null);
  const isSelected = useCallback((item: IButtonGroupItem) =>
    item.id === currentSelectedItem?.id ? 'primary' : 'secondary', [currentSelectedItem?.id]);

  const handleChange = (selectedItem: IButtonGroupItem) => {
    onSelect && onSelect(selectedItem);
    setSelectedItem(selectedItem);
  };

  useEffect(() => {
    setSelectedItem(items.find(item => item.id === selectedId) || null);
  }, [selectedId]);

  return (
    <div className={css.buttonGroup}>
      {
        items.map((item) => (
          <Button
            key={item.id}
            style={{ flex: '1 0 auto' }}
            type={isSelected(item)}
            label={item.label}
            onClick={() => handleChange(item)}
          />
        ))
      }
    </div>
  );
});
