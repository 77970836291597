import css from './index.module.scss';
import { useTranslation } from 'i18nano';

export const ThemeSwitcher = () => {
  const translate = useTranslation();
  return (
    <div className={css.theme}>
      <p className={css.themeTitle}>{translate('header.profileMenu.theme')}</p>
      <div className={css.themeToggle}>
        <label className={css.themeToggleSwitch}>
          <input className={css.themeToggleInput} type='checkbox' />
          <span className={css.themeToggleSlider} />
        </label>
      </div>
    </div>
  );
};
