import { FC, memo } from 'react';
import { InfiniteProgressBar } from '../ui-kit';

import css from './index.module.scss';

export const RoutingIndicator: FC = memo(() => (
  <div className={css.wrapper}>
    <InfiniteProgressBar />
  </div>
));
