import { FC } from 'react';
import PhoneInput from 'react-phone-input-2';
import ru from 'react-phone-input-2/lang/ru.json';

import { VBox } from '../../layout';
import { IPhoneFieldProps } from './props';

import css from './index.module.scss';

export const PhoneField: FC<IPhoneFieldProps> = (props) => {
  return (
    <VBox gap={4}>
      <label className={css.label}>{props.label}{props.isRequired && <sup>*</sup>}</label>
      <PhoneInput
        country={'ru'}
        localization={props.lang === 'ru' ? ru : undefined}
        preferredCountries={['ru', 'by']}
        inputProps={{ ...props, id: props.name }}
        onChange={(e) => props.onChange && props.onChange(e)}
        inputStyle={{
          width: '100%',
          minHeight: '46px',
          border: '1px solid #D9D9D9',
          borderRadius: '10px',
        }}
      />
      {!!props.errorMessage && <div className={css.error}>{props.errorMessage}</div>}
    </VBox>
  );
};
