import 'react-phone-input-2/lib/style.css';
import { FC } from 'react';
import { useField, useFormikContext } from 'formik';
import { IFormInput } from '../../../interfaces/common/form-input';
import PhoneInput from 'react-phone-input-2';
import { classNames } from '../../../helpers/classnames';
import ru from 'react-phone-input-2/lang/ru.json';
import { useTranslationChange } from 'i18nano';
import css from './index.module.scss';
import { Select } from '../../ui-kit/select';
import { DropFileInput } from '../drop-file-input';
import { DateField } from '../../ui-kit/calendar';
import InputMask from 'react-input-mask';

export const FormikInput: FC<IFormInput<Record<string, string>>> = ({
  label,
  className,
  type,
  selectedItem,
  canClear = false,
  mask = undefined,
  dependofValue = false,
  ...props
}) => {
  const [field, meta, helpers] = useField(props);
  const { lang } = useTranslationChange();
  const { setFieldValue } = useFormikContext();
  const { maxHeight, ...restProps } = props;

  if (type === 'checkbox') {
    return (
      <label className={classNames(css.formikInputCheckboxWrapper, className)}>
        <input
          className={css.formikInputCheckbox}
          type='checkbox'
          id={props.id || props.name}
          checked={field.value}
          {...field}
          {...restProps}
        />
        {label}
      </label>
    );
  }

  return (
    <div className={classNames(css.formikInput, className)}>
      <label className={css.formikInputLabel} htmlFor={props.id || props.name}>
        {label}
      </label>
      {props.name === 'phone' ? (
        <PhoneInput
          {...field}
          country={'ru'}
          localization={lang === 'ru' ? ru : undefined}
          preferredCountries={['ru', 'by']}
          inputProps={{ ...restProps, id: props.id || props.name }}
          onChange={(phone: string) => {
            helpers.setValue(phone);
          }}
          inputStyle={{
            width: '100%',
            minHeight: '46px',
            border: '1px solid #D9D9D9',
            borderRadius: '10px',
          }}
        />
      ) : type === 'select' ? (
        <Select
          {...field}
          fullDisabled={restProps.disabled}
          canClear={canClear}
          maxHeight={maxHeight}
          query={(search, skip, take, callback) => {
            callback(props.options ?? []);
          }}
          onSelect={(selectEvent) => {
            setFieldValue(field.name, selectEvent?.id);
            if (props.clearInputNames?.length !== 0) {
              props.clearInputNames?.forEach((name) => {
                setFieldValue(name, '');
              });
            }
          }}
          onBlur={() => helpers.setTouched(true)}
          selectedItem={selectedItem}
          dependofValue={dependofValue}
          {...restProps}
        />
      ) : type === 'file' ? (
        <DropFileInput {...field} {...props} />
      ) : type === 'date' ? (
        <DateField
          fullWidth
          locale={lang === 'ru' ? 'ru' : undefined}
          onSelect={(value) => {
            setFieldValue(field.name, value);
          }}
          value={field.value.split('-').reverse().join('.')}
        />
      ) : mask ? (
        <InputMask
          type='text'
          mask={mask}
          placeholder={restProps.placeholder}
          maskChar=''
          className={css.formikInputInput}
          id={props.id || props.name}
          {...field}
        />
      ) : (
        <input
          className={css.formikInputInput}
          type='text'
          id={props.id || props.name}
          {...field}
          {...restProps}
        />
      )}
      {meta.touched && meta.error ? <p className={css.formikInputError}>{meta.error}</p> : null}
    </div>
  );
};
