import { FC, memo } from 'react';
import { NavLink } from 'react-router-dom';
import { classNames } from '../../../../../helpers/classnames';
import { withTestId } from '../../../../../helpers';
import { IHeaderNavMenuProps } from './props';
import css from './index.module.scss';
import { DataLoaderWrapper } from '../../../../../components/data-loader';
import { useTabsMenu } from '../../hooks/use-tabs-menu';

export const HeaderNavMenu: FC<IHeaderNavMenuProps> = memo((props) => {
  const { isLoading, menuTabs } = useTabsMenu();

  const getClasses = (isActive: boolean) =>
    classNames(css.headerNavMenuItem, { [css.headerNavMenuItemActive]: isActive });

  return (
    <DataLoaderWrapper isLoading={isLoading}>
      <nav className={css.headerNavMenu} {...withTestId('navigationMenu')}>
        {menuTabs.map((item) => (
          <NavLink
            key={item.key}
            to={item.path}
            className={({ isActive }) => getClasses(isActive)}
            onClick={() => props.onMenuItemClick && props.onMenuItemClick()}
          >
            {item.key}
          </NavLink>
        ))
        }
      </nav>
    </DataLoaderWrapper>
  );
});
