import { PayloadAction } from '@reduxjs/toolkit';

import { PageSliceCaseReducers } from '../../../types';
import { CoursesStatusEnum } from '../enums';

export const coursesReducers: PageSliceCaseReducers = {
    setCoursesFilterSearch: (state, action: PayloadAction<string | undefined>) => {
        state.courses.filter.search = action.payload;
    },
    setCoursesStatus: (state, action: PayloadAction<CoursesStatusEnum>) => {
        state.courses.filter.coursesStatus = action.payload;
    },
    setCoursesCurrentPage: (state, action: PayloadAction<{ pageId: number, totalCount: number }>) => {
        state.courses.filter.currentPage = action.payload.pageId;
        state.courses.filter.skip = (action.payload.pageId - 1) * state.courses.filter.take;
    },
    setCoursesPerPageItems: (state, action: PayloadAction<number>) => {
        state.courses.filter.take = action.payload;
        state.courses.filter.currentPage = 1;
        state.courses.filter.skip = 0;
    },
};
