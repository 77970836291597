import { FC, useEffect, useState } from 'react';
import { useTranslation } from 'i18nano';
import { PopupWindowProps } from './props';
import { PopupItem } from '../popup-item';
import { classNames } from '../../../../../../../helpers/classnames';
import { TextField } from '../../../../../../../components/ui-kit/text-field';

import css from './index.module.scss';
import { Button } from '../../../../../../../components/ui-kit/button';
import { usePostApiCoursesSendToMailMutation } from '../../../../../../../services/base-api';
import { useUserProfile } from '../../../../../../../hooks/auth';

export const PopupWindow: FC<PopupWindowProps> = ({ isVisible, isLoading, courses }) => {
  const translate = useTranslation();
  const { user } = useUserProfile();
  const [mail, setMail] = useState<string>(user?.email ?? '');
  const [isSended, setIsSended] = useState<boolean>(false);
  const [sendToMail, sendToMailState] = usePostApiCoursesSendToMailMutation();

  const classes = classNames(
    {
      [css.popupWindow]: true,
      [css.popupWindowVisible]: isVisible,
    },
    // className,
  );

  useEffect(() => {
    setIsSended(!isVisible);
  }, [isVisible]);

  async function onSubmitEmail() {
    try {
      await sendToMail({
        courseSendToMail: {
          mail,
          courses_ids: courses.map((item, _) => item.id)
        }
      }).unwrap();
      setIsSended(true);
    } catch (error) {
      console.error('rejected', error);
    }
  }

  return (
    <div className={classes}>
      <div className={css.popupWindowContentWrapper}>
        {courses.map((course) => (
          <PopupItem key={course.id} course={course} />
        ))}
      </div>

      <div className={css.popupWindowFooterWrapper}>
        <div className={css.popupWindowFooterLabel}>
          {translate('header.emailLabel')}
        </div>

        <div className={css.popupWindowFormWrapper}>
          <TextField
            placeholder={translate('header.emailPlaceholder')}
            value={mail}
            onChange={(value) => setMail(value!)}
            isValid={true}
            isDisabled={isSended && sendToMailState.data != null}
            isRequired={true}
            canClear={true}
          />

          <Button
            type={'primary'}
            isDisabled={isSended && sendToMailState.data != null}
            isLoading={isLoading || sendToMailState.isLoading}
            label={translate('header.buttonLabel')}
            onClick={onSubmitEmail}
          />
        </div>
      </div>
    </div>
  );
};
