import { useDropzone } from 'react-dropzone';
import css from './index.module.scss';
import { FC, useEffect, useState } from 'react';
import { IDropInput } from '../../../interfaces/common/dropInput';
import { useField, useFormikContext } from 'formik';
import { classNames } from '../../../helpers/classnames';
import { FileInfo } from '../file-info';
import { useTranslation } from 'i18nano';
import { Loader } from '../../ui-kit';

export const DropFileInput: FC<IDropInput<Record<string, string>>> = ({
  name,
  type,
  placeholder,
  accent,
  ...props
}) => {
  const translate = useTranslation();
  const [field, meta] = useField({ name, type });
  const [isLoading, setIsLoading] = useState(false);
  // const [file, setFile] = useState<File | null>(null);
  const { setFieldError, setFieldValue, setFieldTouched, validateField, initialValues } =
    useFormikContext();
  const { acceptedFiles, isDragAccept, getRootProps, getInputProps } = useDropzone({
    multiple: false,
    accept: {
      'image/png': ['.png'],
      'image/jpeg': ['.jpeg', '.jpg'],
      'application/pdf': ['.pdf'],
    },
    onDropRejected: () => {
      setFieldError(name, meta.error);
    },
    onDrop: (acceptedFiles) => {
      setFieldValue(name, acceptedFiles);
      setFieldTouched(name, true);
      validateField(name);
    },
    onDragLeave: () => {
      setFieldTouched(name, false);
    },
  });

  const file = acceptedFiles[0];
  const handleDelete = () => {
    setFieldValue(name, '');
  };

  useEffect(() => {
    if (file) {
      setFieldValue(name, acceptedFiles);
    } else if (field.value && typeof field.value === 'string') {
      setIsLoading(true);
      fetch(field.value)
        .then((r) => r.blob())
        .then((blobFile) => {
          const filename = field.value.split('/').pop();
          const file = new File([blobFile], filename, {
            lastModified: new Date().getTime(),
            type: blobFile.type,
          });
          setFieldValue(name, file);
          setIsLoading(false);
        });
    }
    setFieldError(name, undefined);
  }, [file, setFieldError, setFieldValue, name, initialValues]);

  return !field.value ? (
    <div {...getRootProps({ className: `${css.dropFileInputWrapper}` })}>
      <input {...getInputProps({ ...field, ...props })} />
      <p
        className={classNames(css.dropFileInput, {
          [css.dropFileInputAccept]: isDragAccept,
          [css.dropFileInputError]: meta.error,
        })}
      >
        {placeholder ? placeholder : translate('sharedComponents.dropFileInput.placeholder')}
        <span className={css.dropFileInputAccent}>
          {accent ? accent : translate('sharedComponents.dropFileInput.accent')}
        </span>
      </p>
    </div>
  ) : (
    <FileInfo
      isLoading={isLoading}
      fileName={(file?.name || field.value?.name) ?? ''}
      fileMeta={
        file?.lastModified
          ? `${new Date(file.lastModified).toLocaleDateString()} • ${(file.size / 1024).toFixed(
              0,
            )} Кб`
          : field.value?.lastModified
          ? `${new Date(field.value?.lastModified).toLocaleDateString()} • ${(
              field.value?.size / 1024
            ).toFixed(0)} Кб`
          : ''
      }
      handleDelete={handleDelete}
    />
  );
};
