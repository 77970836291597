export const enum StudyPathsEnum {
  MyData = 'my',
  AllInformation = 'all-information',
  MyCourses = 'my-courses',
  MyGroups = 'my-groups',
  Achievements = 'achievements',
  Favourites = 'favourites',
  MyRequests = 'my-requests',
  Documents = 'documents',
  Payment = 'payment',
  Promocodes = 'promocodes',
  Diagnostics = 'diagnostics',
  Calendar = 'calendar',
  Reviews = 'reviews',
  IndividualPrograms = 'individual-programs',
  LearningPath = 'learning-path',
}
