import React, { Suspense } from 'react';
import ReactDOM from 'react-dom';
import { TranslationProvider } from 'i18nano';
import { Provider } from 'react-redux';
import { RouterProvider } from 'react-router-dom';
import { store } from './app/state/store';
import { appRouter } from './app/routing/routes';
import { SuspenseFallback } from './app/components';
import { Toasts } from './app/components/ui-kit/toasts';
import { DEFAULT_LANGUAGE, translations } from './translations';
import './app/styles/main.scss';
import { ProfileProvider } from './app/components/profile-provider';
import { CookiesConsentView } from './app/components/cookies-provider';
import { VersionApp } from './app/components/version-app';

ReactDOM.render(
  <React.StrictMode>
    <Provider store={store}>
      <TranslationProvider
        language={DEFAULT_LANGUAGE}
        fallback={DEFAULT_LANGUAGE}
        translations={translations}
        transition={true}
      >
        <ProfileProvider>
          <Suspense fallback={<SuspenseFallback />}>
            <RouterProvider router={appRouter} />
            <Toasts />
            <CookiesConsentView />
            <VersionApp />
          </Suspense>
        </ProfileProvider>
      </TranslationProvider>
    </Provider>
  </React.StrictMode>,
  document.getElementById('root'),
);
