import { useMemo } from 'react';

import { useAppSelector } from '../../../hooks/hooks';
import { Course, useApiCoursesListQuery } from '../../../services/base-api';
import { mapToCourse } from '../../../services/mappers/courses';
import { selectSelectedCourses } from '../../main/store/selectors';

export const useSelectedCourses = () => {
  const selectedCourses = useAppSelector(selectSelectedCourses);
  const { isFetching, data, isLoading } = useApiCoursesListQuery({});

  const noData = isFetching || !data;

  return useMemo(() => {
    const allCourses = (data as unknown as Course[]) || [];
    const mappedCourses = allCourses.map(mapToCourse);
    const filteredCourses = mappedCourses.filter((course) => selectedCourses.includes(course.id));

    return {
      courses: filteredCourses,
      allCourses: mappedCourses,
      noData,
      allCoursesIsLoading: isLoading,
    };
  }, [noData, selectedCourses, data]);
};
