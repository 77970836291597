export function formatDate(date?: string): string {
  if (!date) {
    return '';
  }

  return new Intl.DateTimeFormat('ru', { dateStyle: 'short', timeStyle: 'medium' }).format(
    Date.parse(date),
  );
}

export function toDateShort(date: Date | null): string {
  if (!date) {
    return '';
  }

  return Intl.DateTimeFormat('ru').format(date);
}

export function apiDateFormat(stringDate: string | undefined | null, language: string): string {
  if (!stringDate) {
    return '';
  }

  try {
    const date = Date.parse(stringDate);
    return Intl.DateTimeFormat(language).format(date);
  } catch (e) {
    console.error(e);
    return '';
  }
}

const prefixNumber = (num: number): string => {
  if (num <= 0) { return '0'; }
  
  return num <= 9 ? `0${num}` : `${num}`;
};

const prefixedDays = [...Array(31)].map((_, index) => prefixNumber(index + 1));
const days = [...Array(9)].map((_, index) => `${index + 1}`);
const prefixedMonths = [...Array(12)].map((_, index) => prefixNumber(index + 1));
const months = [...Array(9)].map((_, index) => `${index + 1}`);

const validDays = new RegExp(`^(${days.concat(prefixedDays).join('|')})$`);
const validMonths = new RegExp(`^(${months.concat(prefixedMonths).join('|')})$`);
const validYear = /^(0|1|2|3)[0-9]{3}$/;
const defaultDate = new Date(1, 1, 1);
const normalizedNumber = (num: string) => `${prefixNumber(parseInt(num))}`;

export function formatWithPrefix(stringDate: string | undefined | null, splitter: '.' | '/'): string {
  if (!stringDate) {
    return '';
  }
  
  try {
    const parts = stringDate.split(splitter);
    if (parts && parts.length === 1) {
      return validDays.test(parts[0]) ? `${normalizedNumber(parts[0])}${splitter}` : `01${splitter}`;
    }

    if (parts && parts.length === 2) {
      const day = validDays.test(parts[0]) ? `${normalizedNumber(parts[0])}${splitter}` : `01${splitter}`;
      const month = validMonths.test(parts[1]) ? `${normalizedNumber(parts[1])}${splitter}` : `01${splitter}`;

      return `${day}${month}`;
    }

    if (parts && parts.length === 3) {
      const day = validDays.test(parts[0]) ? `${normalizedNumber(parts[0])}${splitter}` : `01${splitter}`;
      const month = validMonths.test(parts[1]) ? `${normalizedNumber(parts[1])}${splitter}` : `01${splitter}`;
      const year = validYear.test(parts[2]) ? `${parts[2]}` : '1901';

      return `${day}${month}${year}`;
    }

    return '';
  } catch (e) {
    console.error(e);
    return '';
  }
}

export function stringToDate(stringDate: string | undefined | null, splitter: '.' | '/'): Date {
  if (!stringDate) {
    return defaultDate;
  }

  try {
    const parts = stringDate.split(splitter);
    if (parts && parts.length === 1) {
      const day = validDays.test(parts[0]) ? parseInt(parts[0].replace('0', '')) : 1;
      return new Date(1, 1, day);
    }

    if (parts && parts.length === 2) {
      const day = validDays.test(parts[0]) ? parseInt(parts[0].replace('0', '')) : 1;
      const month = validMonths.test(parts[1]) ? parseInt(parts[1].replace('0', '')) - 1 : 0;

      return new Date(1, month, day);
    }

    if (parts && parts.length === 3) {
      const day = validDays.test(parts[0]) ? parseInt(parts[0].replace('0', '')) : 1;
      const month = validMonths.test(parts[1]) ? parseInt(parts[1].replace('0', '')) - 1 : 0;
      const year = validYear.test(parts[2]) ? parseInt(parts[2]) : 1;

      return new Date(year, month, day);
    }

    return defaultDate;
  } catch (e) {
    console.error(e);
    return defaultDate;
  }
}
