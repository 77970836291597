import { FC, memo, useState } from 'react';
import { HeaderDropdown } from '../header-dropdown';
import { UserProfileSubmenu } from '../user-profile-submenu';
import { UserProfile } from '../user-profile';
import { useOutsideClick } from '../../../../../../../hooks/outside-click';
import { withTestId } from '../../../../../../../helpers';

import css from './index.module.scss';

export const UserProfileMenu: FC = memo(() => {
  const [isSubmenuShown, setIsSubmenuShown] = useState<boolean>(false);
  const ref = useOutsideClick(() => setIsSubmenuShown(false));

  return (
    <div
      ref={ref}
      className={css.userProfileMenu}
      onClick={() => setIsSubmenuShown((prevState) => !prevState)}
      {...withTestId('userProfileMenu')}
    >
      <UserProfile />
      <HeaderDropdown isVisible={isSubmenuShown} className={css.userProfileMenuDropdown}>
        <UserProfileSubmenu />
      </HeaderDropdown>
    </div>
  );
});
