import { FC, memo, useMemo } from 'react';
import { classNames } from '../../../../helpers/classnames';
import { IIconSvgProps } from './props';

import css from './icon.module.scss';

export const IconSvg: FC<IIconSvgProps> = memo((props) => {
  const classes =  useMemo(() => classNames(css.iconSvg, {
    [css.iconSvg__fill_neutral]: props.fill === 'neutral' || !props.fill,
    [css.iconSvg__fill_primary]: props.fill === 'primary',
    [css.iconSvg__fill_secondary]: props.fill === 'secondary',
    [css.iconSvg__fill_default]: props.fill === 'default',
    [css.iconSvg__stroke_neutral]: props.stroke === 'neutral',
    [css.iconSvg__stroke_primary]: props.stroke === 'primary',
    [css.iconSvg__stroke_secondary]: props.stroke === 'secondary',
    [css.iconSvg__stroke_default]: props.stroke === 'default',
  }), [props.fill, props.stroke]);

  const color = props.fill === 'neutral' || props.fill === 'primary' || props.fill === 'secondary' ? undefined : props.fill;

  return <svg
    width={`${props.width || 16}px`}
    height={`${props.height || 16}px`}
    version='1.1'
    xmlns='http://www.w3.org/2000/svg'
    viewBox={`0 0 ${props.viewBoxX || props.width || 16} ${props.viewBoxY || props.height || 16}`}
    className={classes}
    style={{ fill: color }}
    strokeWidth={props.strokeWidth}
    strokeLinecap={props.strokeLinecap}
    strokeLinejoin={props.strokeLinejoin}
    transform={props.transform}
  >
  {
    Array.isArray(props.pathEl)
    ? props.pathEl.map((path, index) => <path key={index} d={path} />)
    : <path d={props.pathEl as string} />
  }
  </svg>;
});
