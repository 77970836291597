import { UserMapper } from '../types';
import { mapToDictionaryItem } from '../../../services/mappers/dictionary';
import { mapToControlAccess } from '../../../services/mappers/role-control-access';

export const mapToUser: UserMapper = (from) => {
  return {
    userId: from.profile.user,
    firstName: from.profile?.first_name,
    firstNameLatin: from.profile?.first_name_latin || '',
    firstNamePrevious: from.profile?.first_name_old || '',
    lastName: from.profile?.last_name,
    lastNameLatin: from.profile?.last_name_latin || '',
    lastNamePrevious: from.profile?.last_name_old || '',
    middleName: from.profile?.middle_name || '',
    middleNameLatin: from.profile?.middle_name_latin || '',
    middleNamePrevious: from.profile?.middle_name_old || '',
    sex: from.profile?.gender || 0,
    maritalStatus: from.profile?.family_status,
    citizenship: from.profile?.citizenship,
    countryOfBirth: from.profile?.birth_country,
    dateOfBirth: from.profile?.birthday_date || '',
    placeOfBirth: from.profile?.birthplace || '',
    snils: from.profile?.snils ? from.profile?.snils.toString() : '',
    inn: from.profile?.inn ? from.profile?.inn.toString() : '',
    email: from.profile?.email || from?.email || '',
    secondEmail: from.profile?.email_alternative || '',
    phone: from.profile?.phone || '',
    avatarUrl: from.profile?.avatar_url || '',
    documentScanPrevious: from.profile.change_fio_document || '',
    region: from.profile?.region || 0,
    desiredProfessions: from.profile?.desired_profession.map(mapToDictionaryItem) || [],
    desiredSkills: from.profile?.desired_skills.map(mapToDictionaryItem) || [],
    telegram: from.profile?.telegram || '',
    isStaff: from.is_staff || false,
    fioDidNotChange: from.profile.fio_did_not_change,
    desiredProfile: from.profile?.desired_target_group || '',
    roles: from.roles.map(mapToControlAccess) || [],
  };
};
