import { FC } from 'react';
import { ICardTextWithIconProps } from './props';
import { MaterialIconText } from '../../material-icon-text';

import css from './index.module.scss';

export const CardTextWithIcon: FC<ICardTextWithIconProps> = ({ iconName, text }) => {
  if (text)
    return (
      <div className={css.cardTextWithIcon}>
        <MaterialIconText iconName={iconName} size='14' classes={css.cardTextWithIconIcon} />
        <p className={css.cardTextWithIconText}>{text}</p>
      </div>
    );
  return <></>;
};
