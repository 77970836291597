import { FC, memo, useCallback, useEffect, useState } from 'react';

import { ICalendarMonthProps } from './props';
import { CalendarDay, IDay } from '../day';
import { classNames } from '../../../../helpers/classnames';
import { CalendarSizeEnum } from '../calendar-size';
import {
  addMonths,
  getCurrentMonthFirstWeekday,
  getDaysInMonth,
  inRange,
  isSame,
  isSameOrBefore,
  weekCount,
} from '../helpers';

import css from './month.module.scss';

interface ICalendarMonthContainerState {
  range: Date[] | [Date];
  selected?: Date;
  month: Date;
}

export const CalendarMonth: FC<ICalendarMonthProps> = memo((props) => {
  const [state, setState] = useState<ICalendarMonthContainerState>({
    range: props.range || [],
    selected: props.selectedDay,
    month: props.month,
  });
  const now = new Date();

  const clickOnDay = (day: IDay) => {
    if (!day.disabled) {
      let range = state.range;

      if (props.canRange) {
        if (range.length === 2 || (range[1] && !range[0])) {
          range = [day.date];
        } else if (range[0] && isSameOrBefore(range[0], day.date)) {
          range[1] = day.date;
        } else {
          range = [day.date];
        }
      } else {
        day.selected = true;
      }

      setState((prevState) => ({
        ...prevState,
        range: range,
        selected: !props.canRange ? day.date : undefined,
      }));

      props.onClick && props.onClick((props.canRange && range) || day);
    }
  };

  const build = useCallback(() => {
    const range = state.range;
    const month = state.month;
    const selected = state.selected || props.selectedDay;
    const date = month;
    const daysInMonth = getDaysInMonth(date);
    let daysOnBoard = weekCount(month) * 7;
    const currentMonthFirstWeekday = getCurrentMonthFirstWeekday(date);
    const prevMonth = addMonths(date, -1);
    const nextMonth = addMonths(date, 1);
    const daysInPrevMonth = getDaysInMonth(prevMonth);
    const days = [];

    let nextMonthDay = 1;

    const difference = daysInMonth - (daysOnBoard - currentMonthFirstWeekday);
    if (difference > 0) {
      daysOnBoard += difference;
    }

    for (let d = 1; d <= daysOnBoard; d++) {
      let _date: Date = new Date();
      const res: IDay = {} as IDay;

      if (d <= currentMonthFirstWeekday) {
        const prevMonthCopy = new Date(prevMonth);
        prevMonthCopy.setDate(daysInPrevMonth - (currentMonthFirstWeekday - d));
        _date = new Date(prevMonthCopy);

        res.prev = true;
      } else if (d <= daysInMonth + currentMonthFirstWeekday) {
        const dateCopy = new Date(date);
        dateCopy.setDate(d - currentMonthFirstWeekday);

        _date = new Date(dateCopy);
      } else if (d > daysInMonth + currentMonthFirstWeekday) {
        const nextMonthCopy = new Date(nextMonth);
        nextMonthCopy.setDate(nextMonthDay);
        _date = new Date(nextMonthCopy);
        res.next = true;
        nextMonthDay++;
      }

      days.push({
        ...res,
        date: _date,
        today: isSame(now, _date),
        selected: isSame(selected, _date),
        rangeStart: isSame(range[0], _date),
        rangeEnd: isSame(range[1], _date),
        inRange: inRange(_date, range[0], range[1]),
        disabled:
          !!props.maxDate && !!props.minDate && !inRange(_date, props.minDate, props.maxDate),
      });
    }

    return days;
  }, [props, state.selected, state.month, state.range]);

  useEffect(() => {
    setState((prevState) => ({
      ...prevState,
      selected: props.selectedDay || state.selected,
      month: props.month || state.month,
    }));
  }, [props.selectedDay, props.month]);

  const days = build();
  const daysNames: string[] = props.daysNames || ['Пн', 'Вт', 'Ср', 'Чт', 'Пт', 'Сб', 'Вс'];
  const calendarDay = days.map((day: IDay, index: number) => (
    <CalendarDay key={index} value={day} onClick={() => clickOnDay(day)} size={props.size} />
  ));
  return (
    <div
      className={classNames(css.monthCalendar, {
        [css.monthCalendar_small]: props.size === CalendarSizeEnum.Small,
      })}
    >
      <div
        className={classNames(css.monthCalendar__names, {
          [css.monthCalendar__names_small]: props.size === CalendarSizeEnum.Small,
        })}
      >
        {daysNames.map((val, index) => (
          <span key={index}>{val}</span>
        ))}
      </div>
      {calendarDay}
    </div>
  );
});
