import { FC, useEffect, useState } from 'react';
import { NavLink, useLocation } from 'react-router-dom';
import { useTranslation } from 'i18nano';
import { ISideSubMenu } from './props';
import { classNames } from '../../../helpers/classnames';
import { MaterialIconText } from '../../material-icon-text';

import css from './index.module.scss';

export const SideSubMenu: FC<ISideSubMenu> = ({ parentItem, title, items, icon }) => {
  const [isOpened, setOpened] = useState(true);
  const [isActive, setActive] = useState(false);
  const translate = useTranslation();
  const location = useLocation();

  useEffect(() => {
    setActive(location.pathname.includes(parentItem.path));
  }, [location]);

  return (
    <div className={css.sideSubMenu}>
      <div
        className={classNames(css.sideSubMenuHeader, {
          [css.sideSubMenuHeaderActive]: isActive,
          [css.sideSubMenuHeaderOpened]: isOpened,
        })}
        onClick={() => setOpened(!isOpened)}
      >
        <span className={css.sideSubMenuHeaderText}>
          <MaterialIconText
            iconName={icon!}
            size='20'
            classes={classNames(css.sideSubMenuIcon, { [css.sideSubMenuIconActive]: isActive })}
          />
          {translate(title)}
        </span>
        <MaterialIconText iconName={isOpened ? 'expand_less' : 'expand_more'} />
      </div>
      {isOpened && (
        <nav className={css.sideSubMenuItems}>
          {items
            .filter((menuItem) => menuItem.isAvailable())
            .map((menuItem) => (
              <NavLink
                key={menuItem.key}
                to={menuItem.path}
                className={({ isActive }) =>
                  classNames(css.sideSubMenuItem, { [css.sideSubMenuItemActive]: isActive })
                }
              >
                {translate(menuItem.key)}
              </NavLink>
            ))}
        </nav>
      )}
    </div>
  );
};
