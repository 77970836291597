import { AppConst } from '../../constants/app';
import { AuthTokenData } from '../../interfaces/auth/token-data';

export class AuthStorage {
  static setData(data: AuthTokenData): void {
    const { login, token, refreshtoken, expireDate } = data;

    localStorage.setItem(
      AppConst.AuthStorageKey,
      JSON.stringify({
        login,
        token,
        refreshtoken,
        expireDate: expireDate || Date.now() + 60 * 60 * 1000, // 1 hour
      }),
    );
  }

  static getData(): AuthTokenData | null {
    const tokenDataString = localStorage.getItem(AppConst.AuthStorageKey);
    const authTokenData = tokenDataString ? (JSON.parse(tokenDataString) as AuthTokenData) : null;
    if (!authTokenData) {
      return null;
    }

    // const nowData = Date.now();
    // const expireDate = authTokenData.expireDate || nowData;

    return /* expireDate > nowData ? */ authTokenData /* : null */;
  }

  static clearData(): void {
    localStorage.removeItem(AppConst.AuthStorageKey);
  }
}
