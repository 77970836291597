import { FC, Suspense } from 'react';
import { Header } from './header';
import { Footer } from './footer';
import { LayoutRoutes } from './routing/routes';
import { Outlet, ScrollRestoration, useNavigation } from 'react-router-dom';
import { SuspenseFallback } from '../../components';
import { RoutingIndicator } from '../../components/routing-indicator';
import css from './index.module.scss';
import { useInitFavourites } from '../../hooks/use-init-favourites';
import { useInitSelectedCourses } from '../../hooks/use-init-selected-courses';

export const LayoutPage: FC = () => {
  const navigation = useNavigation();
  useInitFavourites();
  useInitSelectedCourses();

  return (
    <div className={css.wrapper}>
      <Header />
      <div className={css.content}>
        <Suspense fallback={<SuspenseFallback />}>
          <LayoutRoutes />
          <Outlet />
        </Suspense>
      </div>
      <Footer />
      <ScrollRestoration />
      {navigation.state === 'loading' && <RoutingIndicator />}
    </div>
  );
};
