const enum Weekend {
  Saturday = 6,
  Sunday = 7,
}

export const isoWeekDay = (date: Date): number => {
  const weekDay = date.getDay();

  return weekDay === 0 ? Weekend.Sunday : weekDay;
};

export const isoMonth = (date: Date): number => {
  return date.getMonth() + 1;
};

export const isoDay = (date: Date): number => {
  return date.getDate() + 1;
};

export const isWeekends = (date: Date): boolean => {
  return [Weekend.Saturday, Weekend.Sunday].includes(isoWeekDay(date));
};

export const isLastWeekDay = (date: Date): boolean => {
  return isoWeekDay(date) === Weekend.Sunday;
};

export const isSameOrBefore = (date1: Date, date2: Date) => date1 <= date2;

export const getDaysInMonth = (date: Date): number => {
  return new Date(date.getFullYear(), date.getMonth() + 1, 0).getDate();
};

export const addMonths = (date: Date, months: number): Date => {
  const dateCopy = new Date(date);
  const targetMonth = dateCopy.getMonth() + months;

  dateCopy.setMonth(targetMonth);

  if (dateCopy.getMonth() !== ((targetMonth % 12) + 12) % 12) {
    dateCopy.setDate(0);
  }

  return dateCopy;
};

export const getCurrentMonthFirstWeekday = (date: Date): number => {
  const dateCopy = new Date(date);

  dateCopy.setDate(0);

  return dateCopy.getDay();
};

export const formatMonthLong = (date?: Date, locale?: string): string => {
  if (!date) {
    return '';
  }

  return new Intl.DateTimeFormat(locale, {
    year: 'numeric',
    month: 'long',
  }).format(date);
};

export const isSame = (date1?: Date, date2?: Date): boolean => {
  if (!date1 || !date2) {
    return false;
  }

  return (
    date1.getDate() === date2.getDate() &&
    date1.getMonth() === date2.getMonth() &&
    date1.getFullYear() === date2.getFullYear()
  );
};

export const inRange = (date?: Date, min?: Date, max?: Date): boolean => {
  if (!date || !min || !max) {
    return false;
  }

  return date >= min && date <= max;
};

export const weekCount = (date: Date): number => {
  const firstOfMonth = new Date(date.getFullYear(), date.getMonth(), 1);
  const lastOfMonth = new Date(date.getFullYear(), date.getMonth() + 1, 0);
  const lastOfMonthDiff = 6 - (lastOfMonth.getDay() === 0 ? 6 : lastOfMonth.getDay());
  const used = firstOfMonth.getDay() + lastOfMonth.getDate() + lastOfMonthDiff;

  return Math.round(used / 7);
};

export const formatDate = (date?: Date, locale?: string): string => {
  if (!date) {
    return '';
  }
  return new Intl.DateTimeFormat(locale, {
    year: 'numeric',
    month: '2-digit',
    day: '2-digit',
  }).format(date);
};

export const toDate = (dateString?: string, locale?: string): Date | undefined => {
  if (!dateString) {
    return undefined;
  }

  const parts = dateString.split(locale === 'ru' ? '.' : '/');

  return new Date(parseInt(parts[2], 10), parseInt(parts[1], 10) - 1, parseInt(parts[0], 10));
};
