import { FC, memo, useState } from 'react';
import { NavLink, useLocation, useNavigate } from 'react-router-dom';
import { useTranslation } from 'i18nano';

import RequestIcon30 from '../../../../../components/ui-kit/icons/components/Request30.icon';
import ReviewIcon30 from '../../../../../components/ui-kit/icons/components/Review30.icon';
import { useUserProfile } from '../../../../../hooks/auth';
import { Button } from '../../../../../components/ui-kit/button';
import { PagePathsEnum } from '../../../../../enums/routes';
import { FavoriteItem } from './components/favorite-item';
import { UserProfileMenu } from './components/user-profile-menu';
import { LanguageSwitcher } from './components/language-switcher';
import { classNames } from '../../../../../helpers/classnames';
import { CurrencySwitcher } from './components/currency-switcher';
import { CartItem } from './components/cart-item';
import { withTestId } from '../../../../../helpers';

import css from './index.module.scss';

export const HeaderProfileMenu: FC<Record<'className', string>> = memo(({ className }) => {
  const navigate = useNavigate();
  const translate = useTranslation();
  const { user } = useUserProfile();  
  const location = useLocation();

  const [isRequestHover, setIsRequestHover] = useState<boolean>(false);
  const [isReviewHover, setIsReviewHover] = useState<boolean>(false);

  function onMouseEnterRequest() {
    setIsRequestHover(true);
  }
  function onMouseLeaveRequest() {
    setIsRequestHover(false);
  }
  
  function onMouseEnterReview() {
    setIsReviewHover(true);
  }
  function onMouseLeaveReview() {
    setIsReviewHover(false);
  }

  return (
    <div className={classNames(css.headerProfileMenu, className)} {...withTestId('profileMenu')}>
      {false && <LanguageSwitcher />}
      {false && <CurrencySwitcher />}
      { 
        user && user.isStaff! && 
        <>
          <NavLink to={`${PagePathsEnum.Management}/${PagePathsEnum.ManagementRequests}`}>
            <div onMouseEnter={onMouseEnterRequest} onMouseLeave={onMouseLeaveRequest}>
              <RequestIcon30 type={isRequestHover ? 'primary' : 'secondary'} />
            </div>
          </NavLink>
          <NavLink to={`${PagePathsEnum.Management}/${PagePathsEnum.ManagementReviews}`}>
          <div onMouseEnter={onMouseEnterReview} onMouseLeave={onMouseLeaveReview}>
              <ReviewIcon30 type={isReviewHover ? 'primary' : 'secondary'} />
            </div>
          </NavLink>
        </>
      }
      {
        (!user || !user.isStaff) && 
        <>
          <FavoriteItem />
          <CartItem />
        </>
      }
        
      {!user && (
        <Button
          label={translate('header.login')}
          style={{ width: '80px' }}
          onClick={() =>
            navigate(PagePathsEnum.Auth, {
              state: {
                prevPage: location.pathname,
              },
            })
          }
          testId={'headerLoginButton'}
        />
      )}
      {!!user && <UserProfileMenu />}
    </div>
  );
});
