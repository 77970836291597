import css from './index.module.scss';
import { MaterialIconText } from '../../material-icon-text';
import { FC } from 'react';
import { IFileInfoProps } from '../../../pages/study/interfaces/fileInfo';
import { Loader } from '../../ui-kit';

export const FileInfo: FC<IFileInfoProps> = ({ fileName, fileMeta, handleDelete, isLoading }) => {
  return (
    <div className={css.fileInfo}>
      {isLoading ? (
        <Loader style={{ width: '20px', height: '20px' }} strokeWidth='2' animationDuration='.5s' />
      ) : (
        <>
          <div className={css.fileInfoData}>
            <p className={css.fileInfoDataName}>{fileName}</p>
            <p className={css.fileInfoDataMeta}>{fileMeta}</p>
          </div>
          <MaterialIconText iconName='delete' size={'20'} onClick={handleDelete} />
        </>
      )}
    </div>
  );
};
