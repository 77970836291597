import { NavLink } from 'react-router-dom';
import { PagePathsEnum } from '../../../../../../../enums/routes';
import { MaterialIconText } from '../../../../../../../components/material-icon-text';
import { useState } from 'react';
import ShoppingCartIcon30 from '../../../../../../../components/ui-kit/icons/components/ShopingCart30.icon';
import { StudyPathsEnum } from '../../../../../../study/enums';
import { PopupWindow } from '../popup-window';
import { useSelectedCourses } from '../../../../../../study/hooks/selected';

export const CartItem = () => {
  const [isHover, setIsHover] = useState<boolean>(false);
  const [isFocus, setIsFocus] = useState<boolean>(false);
  const { courses, noData, allCoursesIsLoading } = useSelectedCourses();

  function onMouseEnter() {
    setIsHover(true);
    setIsFocus(true);
  }

  function onMouseLeave() {
    setIsHover(false);
    setTimeout(() => {
      setIsFocus(false);
    }, 300);
  }

  return (
    <div onMouseEnter={onMouseEnter} onMouseLeave={onMouseLeave}>
      <NavLink
        to={`${PagePathsEnum.Study}/${StudyPathsEnum.MyCourses}`}
        onMouseEnter={() => setIsHover(true)}
        onMouseLeave={() => setIsHover(false)}
      >
        {false && <MaterialIconText size={'24'} iconName={'shopping_bag'} />}
        <ShoppingCartIcon30 type={isHover ? 'primary' : 'secondary'} />      
      </NavLink>
      { !noData && <PopupWindow isVisible={isHover || isFocus} isLoading={allCoursesIsLoading} courses={courses} />}
    </div>
  );  
};
