import { FC, useState, createContext, useEffect } from 'react';
import { UserData } from '../../interfaces/auth/user';
import { mapToUser } from '../../pages/auth/services/user-mapper';
import { AuthStorage } from '../../services/auth/auth-storage';
import { useLazyApiUsersProfileReadQuery } from '../../services/base-api';

interface ProfileContextState {
  profile?: UserData | undefined,
  isProfileFetching: boolean,
}

export const ProfileContext = createContext<ProfileContextState | undefined>(undefined);

export const ProfileProvider: FC = ({ children }) => {
  const [state, setState] = useState<ProfileContextState>({
    profile: undefined,
    isProfileFetching: false,
  });
  const [getProfile] = useLazyApiUsersProfileReadQuery();

  const startFetching = () => {
    setState((prevState) => ({ ...prevState, isProfileFetching: true, }));
  };

  const stopFetching = () => {
    setState((prevState) => ({ ...prevState, isProfileFetching: false, }));
  };

  const refreshProfile = async () => {
    const authData = AuthStorage.getData();

    if (!state.profile && !state.isProfileFetching && !!authData) {
      try {
        startFetching();
        const profileResponse = await getProfile().unwrap();
        setState((prevState) => ({ ...prevState, profile: mapToUser(profileResponse), }));
      } catch { /* */ }
      finally {
        stopFetching();
      }
    }
  };

  useEffect(() => {
    refreshProfile();
  }, []);
  
  return (
    <ProfileContext.Provider value={state}>
      {children}
    </ProfileContext.Provider>
  );
};

