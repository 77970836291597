import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { initialState } from './intitial-state';
import { UserData } from '../../../interfaces/auth/user';
import { ERootState } from '../../../interfaces/root-state';

const slice = createSlice({
  name: ERootState.auth,
  initialState,
  reducers: {
    hydrate: (state, action) => {
      return action.payload;
    },
    setProfile: (state, { payload: { user } }: PayloadAction<{ user?: UserData }>) => {
      state.user = user;
    },
    setAutoSignIn: (
      state,
      { payload: { login, password } }: PayloadAction<{ login: string; password: string }>,
    ) => {
      state.autoSignIn = {
        login,
        password,
      };
    },
    updateProfile: (state, { payload }: PayloadAction<UserData>) => {
      state.user = {
        ...state.user,
        ...payload,
      };
    },
  },
});

export const { setProfile, hydrate, setAutoSignIn, updateProfile } = slice.actions;

export default slice.reducer;
