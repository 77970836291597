import { PayloadAction } from '@reduxjs/toolkit';

import { PageSliceCaseReducers } from '../../../types';
import { RequestPeriodEnum, CourseStatusEnum, RequestStatusEnum } from '../enums';

export const requestsReducers: PageSliceCaseReducers = {
    setRequestsFilterSearch: (state, action: PayloadAction<string | undefined>) => {
        state.requests.filter.search = action.payload;
    },
    setRequestsCourseStatus: (state, action: PayloadAction<CourseStatusEnum>) => {
        state.requests.filter.courseStatus = action.payload;
    },
    setRequestsRequestPeriod: (state, action: PayloadAction<RequestPeriodEnum>) => {
        state.requests.filter.requestPeriod = action.payload;
    },
    setRequestsRequestStatus: (state, action: PayloadAction<RequestStatusEnum>) => {
        state.requests.filter.requestStatus = action.payload;
    },
    setRequestsCurrentPage: (state, action: PayloadAction<{ pageId: number, totalCount: number }>) => {
        state.requests.filter.currentPage = action.payload.pageId;
        state.requests.filter.skip = (action.payload.pageId - 1) * state.requests.filter.take;
    },
    setRequestsPerPageItems: (state, action: PayloadAction<number>) => {
        state.requests.filter.take = action.payload;
        state.requests.filter.currentPage = 1;
        state.requests.filter.skip = 0;
    },
};
