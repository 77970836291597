import { ChangeEvent, FC, memo, useEffect, useState } from 'react';
import { IAvatarLoaderProps } from './props';
import { MaterialIconText } from '../../material-icon-text';

import css from './index.module.scss';
import { handleImageError } from '../../../helpers/image-error';

export const AvatarLoader: FC<IAvatarLoaderProps> = memo(({ currentUserData, onFileLoad }) => {
  const [selectedFile, setSelectedFile] = useState<File | null>(null);
  const [preview, setPreview] = useState<string>();

  // create a preview as a side effect, whenever selected file is changed
  useEffect(() => {
    if (!selectedFile) {
      setPreview(undefined);
      return;
    }

    const objectUrl = URL.createObjectURL(selectedFile);
    setPreview(objectUrl);

    // free memory when ever this component is unmounted
    return () => URL.revokeObjectURL(objectUrl);
  }, [selectedFile]);

  const onSelectFile = (e: ChangeEvent<HTMLInputElement>) => {
    if (!e.target.files || e.target.files.length === 0) {
      setSelectedFile(null);
      onFileLoad && onFileLoad(undefined);
      return;
    }

    const addedFile = e.target.files[0] as File;
    setSelectedFile(addedFile);
    onFileLoad && onFileLoad(addedFile);
  };

  return (
    <div className={css.avatarLoader}>
      <img
        className={css.avatarLoaderImage}
        src={preview || currentUserData?.avatarUrl || '/images/profiles/userIcon.svg'}
        alt={currentUserData?.firstName}
        onError={handleImageError('/images/profiles/userIcon.svg')}
      />
      <input type='file' onChange={onSelectFile} id='imgUpload' style={{ display: 'none' }} />
      <label htmlFor='imgUpload'>
        <MaterialIconText
          classes={css.avatarLoaderPhotoIcon}
          iconName={'photo_camera'}
          size={'32'}
        />
      </label>
    </div>
  );
});
